<template>
  <v-app>
    <!-- <v-main>
      <transition name="animation" enter-active-class="animated fadeIn">
        <router-view class="view"></router-view>
      </transition>
    </v-main>-->
    <LoadingScreen v-if="isLoading" :isLoading="isLoading" />

    <div v-if="!isLoading" class="view">
      <CookieBanner />
      <!-- <AppBar :show-tabs="showTabs" v-model="appBar"></AppBar> -->
      <!-- 
        <transition name="animation" enter-active-class="animated fadeIn">
          <router-view class="view"></router-view>
      </transition>-->

      <router-view v-slot="{ Component }">
        <transition name="animation" enter-active-class="animated fadeIn">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import Footer from "./components/Footer.vue";
import LoadingScreen from "./components/LoadingScreen.vue";
import CookieBanner from "./components/CookieBanner.vue";
import packageInfo from "../package";

export default {
  name: "App",

  components: {
    Footer,
    AppBar,
    LoadingScreen,
    CookieBanner,
  },
  data: () => ({
    isLoading: true,
    appVersion: packageInfo.version,
  }),
  methods: {
    setUserLang() {
      let lang = window.navigator.language;
      if (lang.includes("en")) {
        this.$i18n.locale = "en";
      } else if (lang.includes("es")) {
        this.$i18n.locale = "es";
      } else if (lang.includes("fr")) {
        this.$i18n.locale = "fr";
      } else if (lang.includes("pt")) {
        this.$i18n.locale = "pt";
      } else {
        this.$i18n.locale = "en";
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.setUserLang();
    console.log("Current app version: " + this.appVersion);
  },
};
</script>
<style scoped>
.view {
  max-width: 100%;
  overflow-x: hidden;
}

.v-application--wrap {
  width: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Droidiga";
  src: local("Droidiga"), url(./fonts/Droidiga.otf) format("truetype");
}
</style>