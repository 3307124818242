export default {
  "common": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue à ", _interpolate(_named("municipal"))])},
    "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes & Conditions"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire la suite"])},
    "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire moins"])},
    "AppDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez l'application pour un accès complet"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des informations, veuillez patienter..."])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous"])}
  },
  "notFound": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page was not found"])}
  },
  "tabname": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
    "poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points D'interêts"])},
    "destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinations"])},
    "routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routes"])},
    "qrscanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr scanner"])},
    "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorateur"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir partenaire"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhérer"])}
  },
  "home": {
    "travizco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Portugal dans une app, une app pour le monde"])},
    "poiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'intérêt"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez aventurier avec nos "])},
    "poiSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur la page d'accueil, nous affichons les points d'intérêt à proximité et les services locaux, en mettant en évidence la municipalité actuelle."])},
    "routesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routes"])},
    "routesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez nos itinéraires touristiques affichés en différentes couleurs et découvrez les points d'intérêt qu'ils comprennent."])},
    "routesTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous créons des itinéraires distincts entre différents points d'intérêt pour les découvrir de manière ordonnée"])},
    "routesTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque itinéraire contient un ordre spécifique de points d'intérêt qui peuvent être trouvés et consultés lorsque vous voyagez avec l'appl"])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir plus d'informations en utilisant l'application"])},
    "qrscannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr Scanner"])},
    "qrscannerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le scanner QR du point d'intérêt pour obtenir plus d'informations à son sujet"])},
    "dare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osez voyager d'une nouvelle manière avec "])},
    "explorerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorateur"])},
    "explorerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec le mode explorateur, vous pouvez découvrir les points d'intérêt à proximité pendant votre voyage en temps réel, avec ou sans votre téléphone allumé."])},
    "qrscannerTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un point d'intérêt est accompagné d'un QR code, l'application permet de le consulter directement en le scannant"])},
    "qrscannerTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous conférez un point d'intérêt via le scanner QR, vous pouvez obtenir toutes les informations le concernant"])},
    "footerDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco géré par Impactzero, Software lda., vise à découvrir le patrimoine culturel et historique d'un lieu particulier."])},
    "footerDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant, cependant, toute consultation avec l'APP, les conditions d'utilisation et la politique de traitement doivent être lues."])},
    "footerDescription3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La permanence et l'utilisation de l'APP par le visiteur/utilisateur implique l'acceptation de ses termes et conditions, sans aucune réserve, donc si vous n'êtes pas d'accord avec eux, vous devez immédiatement arrêter la navigation."])},
    "footerDescription4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco se réserve le droit, à tout moment, de modifier la présentation et la configuration de l'APP, ainsi que ses conditions d'utilisation et la politique de traitement des données personnelles, et le visiteur/utilisateur doit donc toujours qui accède à l'APP, consulter ces termes et conditions à l'avance."])},
    "footerDescription5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des informations supplémentaires peuvent être obtenues auprès d'Impactzero, Software lda., en envoyant un e-mail à support", "@", "travizco.com."])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez notre partenaire avec travizco business"])},
    "advertBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annoncez votre entreprise"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["devenez notre partenaire"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous!"])}
  },
  "giveaway_popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concours"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez comment vous pouvez être le gagnant de notre tirage au sort."])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à 14/11"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer"])},
    "contest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concours"])}
  },
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlez avec nous"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "business": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez notre partenaire"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ici"])},
    "findout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez comment amplifier votre activité avec nous"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous offrons :"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir plus de visibilité et de crédibilité"])},
    "topic1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire Maintenant"])},
    "topic1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez-vous dans TRAVIZCO BUSINESS et ajoutez des Points d'Intérêt, des services et des itinéraires thématiques pour donner plus de visibilité à votre entreprise."])},
    "topic2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimulez Votre Enteprise"])},
    "topic2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisez votre activité de manière créative et originale, avec de la publicité personnalisée pour mieux faire connaître votre entreprise."])},
    "topic3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraires Personnalisés"])},
    "topic3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous fournissons des itinéraires touristiques en 30 langues différentes en mode automatique, même lorsque le téléphone est verrouillé. Contactez-nous pour promouvoir vos idées."])}
  },
  "destinations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinations"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres destinations"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visiter"])},
    "atractVisitants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui attirent des millions de voyageurs dans le monde entier"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains exemples incluent des villes historiques comme Porto et Lisbonne, des paysages naturels à couper le souffle comme la côte Vicentine et les montagnes de l'île de Madère, et des plages de sable volcanique peuvent être trouvées aux Açores ! Ces endroits offrent une large gamme d'activités, allant de tours culturels et historiques à des aventures en plein air et des moments de détente sur la plage. De plus, de nombreuses destinations touristiques ont une riche cuisine et une vie nocturne animée à apprécier. Lors de la planification de votre prochain voyage, considérez l'un des nombreux incroyables destinations touristiques disponibles à Travizco!"])}
  },
  "blog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre blog"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les nouveautés et les nouveautés de vos destinations préférées"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite"])}
  },
  "cookies": {
    "accept_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter tout"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'options"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies de ciblage sont utilisés pour identifier les visiteurs sur différents sites Web, par exemple. partenaires de contenu, réseaux de bannières. Ces cookies peuvent être utilisés par les entreprises pour établir un profil des intérêts des visiteurs ou montrer des publicités pertinentes sur un autre site web."])},
    "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce website utilise des cookies "])},
    "textToShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce site web utilise des cookies pour améliorer l'expérience utilisateur. En utilisant notre site web, vous acceptez tous les cookies conformément à notre politique en matière de cookies."])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos des cookies"])},
    "about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont de petits fichiers texte placés sur votre ordinateur par les sites web que vous visitez. Les sites web utilisent des cookies pour aider les utilisateurs à naviguer efficacement et à exécuter certaines fonctions. Les cookies nécessaires au bon fonctionnement du site peuvent être définis sans la permission de l'utilisateur Tous les autres cookies doivent être approuvés avant d'être définis dans le navigateur.Vous pouvez modifier votre consentement à l'utilisation des cookies à tout moment sur notre page Politique de confidentialité."])},
    "liste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des cookies"])},
    "save_exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder et Quitter"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])}
  },
  "jingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écoutez notre Jingle !"])},
  "business_form": {
    "register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregistrer Ici"])},
    "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'Enregistrement"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "poi_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Point d'Intérêt"])},
    "service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Service"])},
    "service_subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-type de Service"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de Téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse complète"])},
    "colaborator_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous identifiez-vous comme employé, représentant ou collaborateur du lieu que vous avez l'intention d'ajouter ?"])},
    "colaborator_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est votre rôle ?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "aditional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes/Commentaires supplémentaires"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez le formulaire pour nous contacter par e-mail"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "mandatory_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'e-mail non valide"])},
    "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone non valide"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "searchPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher le lieu à ajouter:"])}
  },
  "form_map": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu sélectionné"])}
  },
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de"])},
  "destination_details": {
    "aveiro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveiro est une ville et appartient à la région Centre. Important centre urbain, ferroviaire, portuaire, avec des universités et très touristique. La Venise portugaise, comme on la surnomme, avec ses moliceiros, il n'y a rien de plus beau ! Ville de la côte ouest fondée au bord d'une lagune, la Ria de Aveiro, dont les atouts sont le sel et le commerce naval. Les Moliceiros, bateaux aux couleurs attrayantes, traditionnellement utilisés pour la récolte des algues, emmènent les touristes à travers les canaux pour une agréable promenade. Ses plages sont une attraction, récompensée par une médaille d'or. Du patrimoine bâti avec ses panneaux d'azulejos d'une rare beauté, en passant par les musées et les différents parcs, faites une pause pour déguster les fameuses taupes d'ovos ! Avec ses 19 municipalités, elle possède une gastronomie très riche, les huîtres qui viennent de la Ria... ne manquez pas une miette de toute cette richesse, tant d'émerveillement. Le Travizco sera votre compagnon de route !"])},
    "acores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Açores sont un archipel formé de 9 îles. Sur l'île de Pico, les vignobles sont plantés dans des champs de lave qui ressemblent à des labyrinthes. Le point culminant est Pico avec 2352 mètres. L'origine volcanique des Açores s'exprime davantage sur l'île de Sao Miguel, dans la vallée de Furnas, où est produit le 'Cozido das Furnas'. C'est l'île la plus grande et la plus peuplée, avec le lac de Sete Cidades et le lac de Fogo. La beauté et la préservation du paysage naturel sont visibles dans toutes ces îles. Graciosa est considérée comme une réserve mondiale de la biosphère par l'UNESCO, connue sous le nom d'île blanche. Avec TRAVIZCO, visitez les plantations de thé, les plus anciennes et les plus uniques d'Europe, qui forment un paysage incroyable !"])},
    "beja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beja, la capitale du Baixo Alentejo, la capitale du plus grand district portugais en termes de superficie, est une ville d'environ 36 mille habitants, facile à explorer à pied ou à vélo et où le temps est relatif ... À l'époque romaine, elle était appelée Pax Julia et pour rappeler ces temps, Beja Romana a lieu au printemps pendant 3 jours, où sa grandeur et sa splendeur sont rappelées. Le territoire est divisé en 14 municipalités, avec des plaines verdoyantes qui deviennent dorées pendant l'été, c'est un territoire d'une beauté unique qui s'étend de l'Espagne à la mer. Ses vins font partie des meilleurs du Portugal, sa cuisine est simple, créative, mais extrêmement riche en saveurs. Un bon exemple de cette créativité est le porc à l'alentejana, une symbiose parfaite entre la terre et la mer. Explorez Beja avec TRAVIZCO et laissez-vous emporter calmement, émerveillez-vous devant Alqueva et, si possible, profitez du magnifique Cante Alentejano."])},
    "braga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville des archevêques avec plus de 2000 ans d'existence, Braga est la ville la plus ancienne du Portugal, appelée par les Romains 'Bracara Augusta'. Elle est connue pour son tourisme religieux et culturel, avec une grande affluence de personnes à Pâques. Ses sanctuaires sont bien connus, tels que Bom Jesus do Monte et Sameiro. Braga est composée de 14 municipalités, toutes ayant une grande importance pour le développement du territoire, également en termes touristiques. Le territoire est assez accidenté, avec des montagnes élevées et des municipalités dans la zone du parc national Peneda Gerês (1545 mètres), le seul du Portugal. La gastronomie et les vins de la région attirent de plus en plus de visiteurs, et la population jeune y trouve une bonne qualité de vie."])},
    "braganca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bragança est une ville frontalière, un siège et un district situé dans le nord-est du Portugal. Il y a 12 municipalités, composées de deux régions distinctes, celles de plus haute altitude, au nord, qui constituent la Terra Fria Transmontana et au sud, la Terra Quente Transmontana, au climat plus doux. Dans l'ensemble, le district est montagneux. La Serra da Nogueira, avec 1320 mètres, sépare les vallées de la Tuela et du Sabor. La plus haute de toutes est la Serra de Montesinho, avec d'immenses sentiers, qui s'élève à 1400 mètres et s'étend sur le territoire espagnol. L'histoire raconte que c'est à Bragança qu'a eu lieu le mariage secret entre D. Pedro et Inês de Castro. Promenez-vous dans le centre historique et ne manquez pas la beauté de ses monuments ! Visitez les communes voisines, profitez du paysage et, avec Travizco, découvrez où manger le meilleur de ces régions !"])},
    "castelo_branco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castelo Branco est située dans l'intérieur centre du pays, dans la Beira Baixa. Elle est la capitale du district et compte onze municipalités. Depuis les murs du château, on peut voir une partie de la ville. La broderie a acquis une grande importance dans la région grâce à la culture du lin comme tradition. Les produits locaux sont d'une grande importance, tels que l'huile d'olive et le vin. De l'élevage, on produit du fromage, certains des plus appréciés du pays. La production de cerises, de cerises acides et de pêches contribue au développement économique de la commune. Le marqueur géodésique qui marque le centre du Portugal offre une vue magnifique ! L'architecture religieuse et militaire est répartie dans les différents comtés. Avec TRAVIZCO, venez dans le pays des Albicastrenses!"])},
    "coimbra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coimbra est située dans le centre du Portugal, capitale du district. Ville d'étudiants qui fut autrefois la capitale du pays, avec la rivière Mondego à ses pieds. Un complexe architectural comprenant l'Université de Coimbra est classé au patrimoine mondial. Ville d'amour et de traditions académiques, résidences et républiques d'étudiants, lieu de rassemblements où plusieurs écrivains ont été formés. La beauté de la Bibliothèque Joanina est unique ! Tavernes et collations, douceurs de couvent, plats typiques que l'on trouve dans tous ses 17 municipalités. Des plages de sable doré, des montagnes élevées, des villages de schiste et Conímbriga, sont de bonnes raisons de visiter. Si vous n'avez jamais vu de plantations de riz, Travizco vous montrera où les trouver !"])},
    "evora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évora, une ville qui respire l'histoire et l'art portugais, son centre historique a été déclaré site du patrimoine mondial par l'UNESCO. Praça do Giraldo, le Temple de Diane, la Chapelle des Os, la Cathédrale, l'Église de São Francisco... il n'y a rien de tel que de se promener au hasard avec TRAVIZCO dans la poche pour découvrir cette merveilleuse ville. Mais la beauté et le charme ne se trouvent pas seulement à Évora, ne manquez pas l'occasion d'explorer le district où vous serez sûrement captivé par son patrimoine et sa beauté naturelle. Le paysage est typiquement alentejan, plat avec de vastes oliveraies et des vignobles, aride dans les mois les plus secs. Du sommet de Monsaraz, vous pouvez contempler l'immensité du réservoir d'Alqueva, le plus grand lac artificiel d'Europe. Au pied de la chaîne de montagnes de Borba se trouve Vila Viçosa caractérisée par une atmosphère royale distincte, à Arraiolos venez découvrir les tapisseries, à Portel visitez l'imposant château, prévoyez une visite aux grottes d'Escoural à Montemor-o-Novo... il y a tellement à dire, mais le mieux est de se laisser porter par TRAVIZCO lors d'un voyage à travers l'Alentejo central, un territoire d'une beauté unique, riche en culture, tradition et gastronomie."])},
    "faro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faro est la capitale de l'Algarve, une région qui ne nécessite aucune présentation. Un territoire connu pour ses plages de sable doré, le barrocal d'où ressortent son délicieux miel, medronho et liège. Enfin, la chaîne de montagnes, qui occupe 50% de la région, une terre de grande beauté dont le plus haut sommet est situé dans la chaîne de montagnes de Monchique à 902m d'altitude. Son climat méditerranéen tempéré, baigné par plus de 3000 heures de soleil par an, fait de l'Algarve l'une des destinations touristiques les plus populaires au monde et très prisée pour le golf. L'Algarve est composé de 16 municipalités, chacune avec une forte identité, pleines d'histoires et de paysages d'une beauté unique. Perdez-vous dans l'Algarve et trouvez votre chemin avec TRAVIZCO."])},
    "guarda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le district de Guarda est situé dans le centre de la région Beira, entre le plateau Guarda-Sabugal et la Serra da Estrela. C'est à l'intérieur de ses murs que l'histoire est racontée depuis plus de 800 ans. Un lieu de santé et de bien-être en raison de l'air pur que l'on respire ici. C'est la région des 5 F : Forte pour son granit, Abondante pour ses vallées fertiles, Froide pour son climat, Fidèle pour ses habitants et Belle pour tout ce qu'elle possède. Les passerelles de Mondego vous invitent à vous promener, en commençant par le barrage de Caldeirão, avec des ponts suspendus et des paysages incroyables ! Il y a 14 municipalités avec des endroits d'une beauté rare ! Les peintures rupestres, les châteaux et la cuisine montagnarde sont déjà suffisantes pour venir ici ! Prenez-vous et le Travizco et aventurez-vous !"])},
    "leiria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiria est située dans la région côtière centrale, c'est la capitale et le siège du district. La ville s'est développée autour du château, près de la rivière Lis. Elle compte 16 municipalités avec des monuments d'une beauté extraordinaire. Les forêts sans fin, les pinèdes, les plages sans fin, l'architecture religieuse et civile, ses spas et ses traditions populaires sont tous d'une grande importance !... La cuisine, entre montagnes et plages, est très variée, avec des plats portugais de poisson et de viande, accompagnés de vin de la DOC Encostas de Aire. La céramique et le verre sont une référence. D'autres châteaux et monuments méritent une visite obligatoire. Si vous aimez le chocolat, ne manquez pas le festival de la région. TRAVIZCO sera avec vous pour vous montrer le chemin !"])},
    "lisboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisbonne est la capitale du pays, la capitale du district avec le Tage qui a vu ses navires partir à la découverte il y a plus de 500 ans. Lisbonne est la ville aux sept collines, avec ses châteaux et ses églises imposantes d'une beauté incomparable. Elle a été reconstruite après une tragédie, avec des monuments architecturaux de grande valeur, des quartiers emblématiques qui n'ont pas cessé d'être animés au fil des ans. Avec 18 communes, des villes et des villages qui ont leur propre identité, des arènes et des traditions taurines, des vignobles et des zones agricoles avec les potagers qui alimentent la grande Lisbonne. Des plages et des villages de pêcheurs qui sont devenus des attractions touristiques, sans perdre ce qui les caractérise le plus. La gastronomie est bien plus que ce qui peut être écrit, elle est là pour être goûtée. TRAVIZCO trouve cet endroit !"])},
    "madeira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madère est un archipel (avec Porto Santo et Ilhas Desertas) dont la capitale est Funchal. Axée sur le tourisme, les visiteurs viennent des quatre coins du monde, pour son climat aux températures douces et ses paysages aux montagnes et vallées enchanteresses. Dans ses 11 municipalités, cherchez les points de vue et admirez tout ce que vous voyez (Cabo Girão et la passerelle). Ne manquez pas de faire l'ascension du Pico do Areeiro, qui culmine à plus de 1800 mètres. Piscines naturelles, maisons de Santana, villages pittoresques et villages de pêcheurs, un monde à découvrir avec TRAVIZCO ! Montez dans le téléphérique, descendez dans les 'Carros de Cesto' et visitez le marché des Lavradores. Gastronomie : poisson frais, espetada, bolo do caco, vin de Madère et Poncha... fortement recommandé !"])},
    "portalegre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portalegre est située dans le nord de l'Alentejo, au milieu du parc naturel de la Serra de São Mamede. C'est une ville entourée de montagnes, de falaises et de plaines, avec des paysages d'une grande beauté. La fabrication de tapisseries est devenue un ex-libris de la ville et l'artisanat est présent dans toute la région. De nombreux produits régionaux, saucissons, fruits, miel et fromages rendent la gastronomie très riche, ainsi que des vins et des bières artisanales. Les sucreries conventuelles que l'on trouve sur tout le territoire sont un délice des Dieux. Les haras où sont élevés les meilleurs chevaux lusitaniens sont une terre de légendes et de traditions, de festivals et de pèlerinages qui a même une 'Sintra de l'Alentejo' pour la beauté de ses jardins, certaines municipalités de Portalegre étant les plus fortifiées d'Europe. Avec TRAVIZCO, venez à leur rencontre !"])},
    "porto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ville de Porto est le district et le siège du district de Porto situé dans la région Nord, dans le Douro Litoral. Elle regroupe 18 municipalités où se trouvent la plupart des petites et moyennes entreprises du pays. Le port de Leixões et l'aéroport Francisco Sá Carneiro sont deux infrastructures majeures et importantes de la région. Porto est la ville qui a donné son nom au Portugal lorsqu'elle s'appelait Portus Cale. Elle est mondialement connue pour son vin (le vin de Porto), les couleurs des maisons de la Ribeira et son centre historique, classé au patrimoine mondial de l'humanité. La Francesinha est plus célèbre ici qu'en France, mais Tripas à moda do Porto, c'est Porto ! Invicta (invincible) ville et région d'une grande beauté, tant naturelle qu'architecturale, toutes les municipalités conservent leur propre identité. Les bateaux rabelo qui naviguent le long du Douro montrent les ponts et les rives du Douro. Ne perdez plus de temps et avec Travizco, vous trouverez tous les trésors cachés !"])},
    "santarem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santarém appartient à la région du Ribatejo, capitale et siège du district. Les Serras de Aire et Candeeiros sont imposantes. Tout le patrimoine architectural de la région est d'une grande valeur. Les Templiers ont laissé une grande partie de celui-ci, comme des châteaux et des églises. Allez en bateau au château qui se trouve au milieu de la rivière. Le sanctuaire de Fátima attire des milliers de pèlerins tout au long de l'année, le site recevant des milliers de personnes. Découvrez la terre des phénomènes, les terres des vins et des melons, ainsi que la ville où a vécu Luís de Camões. Plages fluviales qui se remplissent en été. Vous avez faim ? La gastronomie de la région avec des plats traditionnels est un incontournable. Ensuite, trouvez la prochaine direction avec TRAVIZCO."])},
    "setubal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur la côte sud du fleuve Tage, dans l'estuaire du Sado, se trouve la belle ville de Setúbal, la capitale d'un district composé de 13 municipalités. Dans la ville de Bocage et Luísa Todi, le couvent de Jésus de style gothique-manuélin, qui abrite le musée de la ville, et le fort de São Filipe, transformé en pousada, d'où vous pourrez contempler la ville, le fleuve Sado, Troia et la chaîne de montagnes de l'Arrábida, sont des incontournables. À proximité, les visiteurs peuvent trouver des zones naturelles préservées telles que l'estuaire du fleuve Sado et le parc naturel de l'Arrábida. Les plages de cette région sont d'une beauté incomparable, et la mer généreuse apporte le meilleur poisson et fruits de mer du Portugal sur la table de ceux qui visitent la région. À l'intérieur, les plats de volaille enrichissent les tables, tels que l'Arroz de Pato, la Cabidela de Galinha ou la Feijoada de Frango. Le Lombo de Porco Assado à Montijo mérite également d'être mentionné. Apportez TRAVIZCO avec vous et laissez-le vous guider à travers cette terre de contrastes entre la mer et le fleuve, entre la côte et l'intérieur."])},
    "viana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viana do Castelo (capitale du district) est une ville atlantique située au nord du Portugal, dans le Haut-Minho. Paysage naturel, avec la présence de la rivière, de la montagne et de la mer (la rivière Lima prend sa source en Espagne et se jette dans Viana). Au sommet, la Basilique de Santa Luzia d'où l'on a une vue magnifique, à ne pas manquer ! Empruntez l'Elevador qui fait un parcours de 650 mètres (le plus long du Portugal). Il y a 10 municipalités à Viana do Castelo, l'une des plus belles du pays. Viana do Castelo a un lien étroit avec la morue, car c'est d'ici que partaient les moulins à morue vers Terre-Neuve et le Groenland. La région offre de nombreuses spécialités gastronomiques. La Senhora da Agonia, célébrée le 20 août, attire de nombreux visiteurs, tout comme les plages de sable fin. L'architecture du centre historique et le pont Eiffel... Les célèbres vins Vinho Verde sont à ne pas manquer ! Et comme confiserie, la Torta de Viana, certifiée par la mairie. Avec Travizco, laissez-vous emporter et surprendre ! En guise de souvenir, emportez un mouchoir de Saint-Valentin ou un objet en filigrane."])},
    "vila_real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par ici le Marão, ceux qui sont ici sont aux commandes ! Vila Real, ville et district du même nom, est située au nord du Portugal, dans l'ancienne province de Trás-os-Montes et Alto Douro. Elle est située sur les pentes de la Serra do Marão et à proximité de la Serra do Alvão. Quatorze municipalités font partie du district de Vila Real, chacune avec ses particularités qui la caractérisent si bien. Le paysage est à couper le souffle ! Le merveilleux royaume de Miguel Torga est aujourd'hui une destination touristique d'excellence ! Il existe de nombreuses Quintas que l'on peut visiter et où l'on peut faire des dégustations de vin. La gastronomie de la région, si riche et diversifiée, est à goûter et à savourer. La construction du tunnel de Marão de près de 6 kilomètres, le plus long tunnel routier du pays, a rapproché les districts de Braga, Porto et Guimarães. La N2 traversant ces municipalités, Travizco vous indique ce qu'il faut trouver et visiter !"])},
    "viseu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ville de Viseu est le siège et la capitale du district de la région Centre-Nord. C'est le plus grand district du pays avec 24 municipalités, chacune ayant des caractéristiques uniques. La région Viseu Dão-Lafões est protégée par les montagnes de Serra da Estrela, Montemuro et Caramulo, et c'est dans ses terroirs que naissent des nectars primés. Profitez des spas de la région pour vous ressourcer et laissez-vous emporter par les sentiers de montagne, respirez l'air frais et appréciez le paysage. Si vous le souhaitez, vous pouvez également perfectionner votre technique de golf. La ville de Viriato possède tant de monuments, un héritage architectural intéressant dans toutes les municipalités. La gastronomie de la région est riche et diversifiée. En route vers Viseu avec TRAVIZCO !"])}
  }
}