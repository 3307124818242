<template>
  <!-- app bar -->
  <!-- <v-app-bar v-if="$vuetify.display.mdAndUp">
    <GiveawayVue></GiveawayVue>
  </v-app-bar>-->
  <v-app-bar class="app-bar mx-auto bg-primary" elevation="5" density="confortable" v-model="hideBar">
    <!-- <v-row>
      <GiveawayVue></GiveawayVue>
    </v-row>-->
    <v-row>
      <v-toolbar-title class="show-pointer d-flex">
        <router-link to="/home">
          <a @click="scrollToElementById('startCard')" class="d-flex ml-5" v-if="$vuetify.display.mdAndUp">
            <v-img :src="qrLogo" cover height="32" width="52"></v-img>
            <v-img :src="whiteTravizco" height="30" width="200"></v-img>
          </a>
          <a @click="scrollToElementById('startCard')" class="d-flex ml-5 pt-2" v-if="$vuetify.display.sm">
            <v-img :src="qrLogo" cover height="32" width="52"></v-img>
            <v-img :src="whiteTravizco" height="30" width="200"></v-img>
          </a>

          <a @click="scrollToElementById('startCard')" class="d-flex pl-5 pt-2" v-if="$vuetify.display.xs">
            <v-img :src="qrLogo" cover height="33" width="50"></v-img>
            <v-img :src="whiteTravizco" class height="33" width="170"></v-img>
          </a>
        </router-link>
      </v-toolbar-title>

      <v-app-bar-nav-icon v-if="showTabs" @click="drawer = !drawer" class="d-flex d-md-none mr-5"></v-app-bar-nav-icon>
    </v-row>

    <!-- tabs -->
    <div>
      <v-tabs v-if="showTabs" class="d-none d-md-flex" v-model="intersectedTab">
        <v-tab :key="index" v-for="(tab, index) of tabs" class="tab-text" @click="scrollToElementById(tab.scrollToId)">
          {{ tab.name
          }}
        </v-tab>
      </v-tabs>
    </div>
  </v-app-bar>

  <!-- drawer -->
  <v-navigation-drawer :touchless="isTouchless" class="bg-primary-darken-1" elevation="5" location="end" temporary v-model="drawer">
    <v-container fluid>
      <v-img :src="logoNav" contain height="85px" class="mt-2 mb-4"></v-img>
    </v-container>

    <v-divider color="white" class="ml-4 mr-4"></v-divider>

    <v-list nav>
      <v-list-item @click.stop="drawer = !drawer" v-cloak two-line :key="id" v-for="(item, id) of tabs" class="tab-text" @click="scrollToElementById(item.scrollToId)">
        <v-list-item-title class="item-text" v-text="item.name"></v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider color="white" class="ml-4 mr-4"></v-divider>

    <div class="pt-5 white--text font-weight-bold follow-text">{{ $t('common.follow') }}</div>

    <div class="d-flex justify-center mt-5">
      <div class="d-flex">
        <v-btn v-for="i in icons" :key="i.id" :href="i.link" :icon="i.icon" variant="text" size="35" class="mr-4" />
        <v-btn href="https://www.tiktok.com/@travizco" variant="text" icon size="35">
          <v-img contain height="35" width="35" :src="tiktokIcon" aspect-ratio="1"></v-img>
        </v-btn>
      </div>
    </div>

    <!-- download in store -->
    <div style="text-align:center" class="mt-5">
      <div class="d-flex justify-center">
        <a class="imageExpand" id="ios_store" target="_blank" href="https://apps.apple.com/app/travizco/id1613139068">
          <v-img width="90px" contain :src="appStoreButton"></v-img>
        </a>
        <a class="imageExpand ml-2 mr-1" id="android_store" target="_blank" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco">
          <v-img width="90px" contain :src="googlePlayButton"></v-img>
        </a>
      </div>
    </div>
  </v-navigation-drawer>
</template>
  
<script>
export default {
  name: "AppBar",
  data() {
    return {
      appBar: true,
      shrinkBarVar: false,
      drawer: false,
      icons: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/Travizco-112803288127105",
        },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/travizco_iz",
        },
        {
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/company/86613576",
        },
      ],
      tiktokIcon: require("../assets/tiktok_icon.png"),
      appStoreButton: require("../assets/app_store_icon.png"),
      googlePlayButton: require("../assets/google_play_icon.png"),
      headerImage: require("../assets/business/business_header.svg"),
      businessLogo: require("../assets/business/blue_business.svg"),
      qrLogo: require("../assets/qrLogo.jpg"),
      whiteTravizco: require("../assets/headerimg.png"),
      logoNav: require("../assets/logo_nav.png"),
    };
  },
  props: {
    tabs: {
      type: Array,
    },
    showTabs: {
      type: Boolean,
    },
    tabIndex: {
      type: Number,
    },
    shrinkBar: {
      type: Boolean,
    },
    isTouchless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    intersectedTab: function () {
      return this.tabIndex;
    },
    hideBar: function () {
      return this.shrinkBarVar;
    },

    /*  hideTabsIcon: function () {
       return this.isHomePageVar;
     } */
  },
  methods: {
    /* on click navigate to each tab content */
    scrollToElementById(id) {
      /* go to business page if tab partner is clicked */
      if (id === "ref_6") {
        this.$router.push("business");
      } else {
        const element = document.getElementById(id);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    },
    /* hide and show appbar when scrolling */
    handleScroll(event) {
      if (window.scrollY > 0) {
        this.shrinkBarVar = true;
      } else {
        this.shrinkBarVar = false;
      }
    },
  },
  watch: {
    drawer: function () {
      if (this.drawer === true) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.25rem rgba(18, 243, 37, 0.5);
} */

.tab-text {
  text-shadow: 0.2px 0.2px black;
}

.follow-text {
  text-align: center;
  font-size: 15px;
}

.app-bar > .v-toolbar__content {
  justify-content: space-between;
}

.show-pointer:hover {
  cursor: pointer;
}

.item-text {
  font-size: 18px;
  text-shadow: 1px 1px black;
  text-align: center;
}
</style>

 
 
  
