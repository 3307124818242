<template>
  <div class="d-flex">
    <div class="counter">{{ count }}</div>
  </div>
</template>

<script scoped>
export default {
  props: {
    targetCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
      interval: null,
      countEnd: false,
    };
  },
  mounted() {
    // Use the Intersection Observer API to detect when the component is in view
    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        // Start counting when the component is in view
        this.interval = setInterval(() => {
          if (this.count >= this.targetCount) {
            clearInterval(this.interval);
            this.countEnd = true;
          } else {
            let increment = Number.parseInt(this.targetCount * 0.003);
            if (increment === 0) increment = 1;
            if (this.count + increment > this.targetCount) {
              this.count = this.targetCount;
            } else {
              this.count = this.count + increment;
            }
          }
        }, 5);
      } else {
        // Stop counting when the component is not in view
        clearInterval(this.interval);
      }
    });
    observer.observe(this.$el);
  },
  beforeDestroy() {
    // Clean up the interval when the component is destroyed
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.counter {
  transition: all 0.4s ease-out;
}
</style>