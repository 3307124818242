export default {
  "common": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome to ", _interpolate(_named("municipal"))])},
    "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read less"])},
    "AppDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the application to have full access"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading information, please wait..."])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])}
  },
  "notFound": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page was not found"])}
  },
  "tabname": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of Interest"])},
    "destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinations"])},
    "routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routes"])},
    "qrscanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr scanner"])},
    "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become Partner"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])}
  },
  "home": {
    "travizco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal in one app, an app for the world"])},
    "poiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of Interest"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover "])},
    "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be an adventurer with our "])},
    "poiSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the home page we display nearby points of interests and local services, highlighting the current municipality."])},
    "routesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routes"])},
    "routesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our touristic routes shown in different colors and find out what points of interest it includes."])},
    "routesTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create distinct routes between different points of interest to discover them in an orderly way"])},
    "routesTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each route contains a specific order of points of interest that can be found and consulted as you travel with the app"])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtain more information using the "])},
    "qrscannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr Scanner"])},
    "qrscannerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the QR scanner at the point on interest to get more information about it."])},
    "dare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dare to travel in a new way with "])},
    "explorerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
    "explorerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the explorer mode you can discover nearby points of interest during your trip in real time, with or without your phone turned on."])},
    "qrscannerTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a point of interest is accompanied by a QR code, the app allows it to be consulted directly through scanning it"])},
    "qrscannerTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When confering a point of interest through the QR Scanner you can obtain all the information about it"])},
    "footerDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco managed by Impactzero, Software lda., aims to discover the cultural and historical heritage of a particular location."])},
    "footerDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before, however, any consultation with the APP, the Terms of Use and Treatment Policy must be read"])},
    "footerDescription3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The permanence and use of the APP by the visitor/user implies acceptance of its terms and conditions, without any reservation, so if you do not agree with them, you must immediately stop browsing."])},
    "footerDescription4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco, reserves the right, at any time, to change the presentation and configuration of the APP, as well as its terms and conditions of use and the policy for processing personal data, thus, the visitor/user shall whenever accessing the APP, consult these terms and conditions in advance."])},
    "footerDescription5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information can be obtained from Impactzero, Software lda. by e-mailing support", "@", "travizco.com."])},
    "qrackonTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We display a map in order to present to you several places that might pique your curiosity"])},
    "qrackonTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide a filter for the different types of points of interest to simplify your exploration"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be our partner with travizco business"])},
    "advertBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advert your business,"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["become our partner"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now!"])}
  },
  "giveaway_popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Contest"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out how you can be the winner of our photography contest"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until 14/11"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate"])},
    "contest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contest"])}
  },
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk to us"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "business": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be our partner."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register here"])},
    "findout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover how to expand your business with us"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer:"])},
    "mockupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "mockupSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text sample Subtitle text Subtitle text Subtitle text Subtitle text Subtitle text Subtitle text Subtitle text Subtitle text "])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtain more Visibility and Credibility"])},
    "topic1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
    "topic1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register in TRAVIZCO BUSINESS and add Points of Interest, Services and thematic Routes to give more visibility to your company."])},
    "topic2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost Your Business"])},
    "topic2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize your business in a creative and original way with custom advertising to better promote your company."])},
    "topic3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized Routes"])},
    "topic3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide tourist routes in 30 different languages in automatic mode even when the phone is locked. Contact us to promote your ideas."])}
  },
  "destinations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinations"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Destinations"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit"])},
    "atractVisitants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["that attract millions of travellers around the world"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some examples include historical cities like Porto and Lisbon, breathtaking natural landscapes like the Vicentina Coast and the mountains of Madeira Island, and volcanic sand beaches can be found in the Azores! These places offer a wide range of activities, from cultural and historical tours to outdoor adventures and beach relaxation. Additionally, many tourist destinations have rich cuisine and lively nightlife to enjoy. When planning your next trip, consider one of the many amazing tourist destinations available in Travizco!"])}
  },
  "blog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our blog"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out what's new about your favorite destinations"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "cookies": {
    "accept_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Options"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeting cookies are used to identify visitors across different websites, for example. content partners, banner networks. These cookies can be used by companies to build a profile of visitors' interests or show relevant ads on another website."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses cookies"])},
    "textToShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses cookies to improve the user experience. By using our website, you are agreeing to all cookies in accordance with our Cookie Policy."])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Cookies"])},
    "about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies are small text files placed on your computer by the websites you visit. Websites use cookies to help users navigate efficiently and perform certain functions. Cookies necessary for the proper functioning of the site can be set without user permission All other cookies need to be approved before being set in the browser.You can change your consent to the use of cookies at any time on our Privacy Policy page."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of cookies"])},
    "save_exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Exit"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeting"])}
  },
  "jingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to our Jingle!"])},
  "business_form": {
    "register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Here"])},
    "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Place"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "poi_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point of Interest Type"])},
    "service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Type"])},
    "service_subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Subtype"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
    "colaborator_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you identify yourself as an employee, representative or colaborator of the place you intend to add?"])},
    "colaborator_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your role?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "aditional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes/Aditional Comments"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the form to contact us via email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "mandatory_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Field"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid e-mail format"])},
    "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the location to add:"])}
  },
  "form_map": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinates"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected place"])}
  },
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "destination_details": {
    "aveiro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveiro is a city and belongs to the Center Region. Important urban center, railway, port, with universities and very touristic. The Portuguese Venice, as it is nicknamed, with its moliceiros, there is nothing more beautiful! City on the west coast founded beside a lagoon, the Ria de Aveiro, with salt and naval commerce as assets. The Moliceiros, boats with attractive colors, traditionally used for harvesting seaweed, take tourists through the channels on a pleasant trip. Its beaches are an attraction, with a Gold Quality award. From the built heritage with its tile panels of rare beauty, through the museums and the different parks, take a break to enjoy the famous 'ovos moles'! With 19 municipalities, it has a very rich gastronomy, the oysters that come from the Ria...don't miss a bit of all this richness, so much wonder. The Travizco will be your companion in this crossing!"])},
    "acores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Azores is an archipelago formed by 9 islands. On Pico Island, the vineyards are planted in lava fields that look like labyrinths. The highest point is Pico with 2352 meters. The volcanic origin of the Azores has more expression on the island of São Miguel, in the Furnas Valley, where the 'Cozido das Furnas' is made. It is the largest and most populated island, with the Lagoa das Sete Cidades and the Lagoa do Fogo. The beauty and preservation of the natural landscape can be seen in all of them. Graciosa is considered a UNESCO World Biosphere Reserve, known as the White Island. With TRAVIZCO, visit the tea plantations, as they are the oldest and most unique in Europe, forming an incredible landscape!"])},
    "beja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beja, capital of the Baixo Alentejo, capital of the largest Portuguese district in area, is a city with about 36 thousand inhabitants, easy to explore on foot or by bike and where time is relative... In Roman times it was Pax Julia and to remember those times, Beja Romana is held in the spring for 3 days, where its greatness and grandeur are remembered. Territory divided into 14 municipalities, with green plains that turn golden during the summer, it is a territory of unique beauty that goes from Spain to the sea. Its wines are among the best in Portugal, its cuisine is simple, creative, but extremely rich in flavors. A good example of this creativity is the Alentejo-style pork, a perfect symbiosis between the land and the sea. Explore Beja with TRAVIZCO and let yourself be carried away calmly, marvel at Alqueva and if possible, enjoy the beautiful Cante Alentejano."])},
    "braga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City of Archbishops with over 2000 years of existence, Braga is the oldest city in Portugal, named by the Romans as 'Bracara Augusta'. It is known for its Religious and Cultural Tourism, with a large influx of people during Easter. Its shrines are well known, such as Bom Jesus do Monte and Sameiro. Braga has 14 municipalities, all of great importance for the growth of the territory, also in tourism terms. Quite hilly, with high mountains, with municipalities in the area of Peneda Gerês National Park (1545 meters), the only one in Portugal. The region's cuisine and wines attract more and more visitors, and where young people find a good quality of life here."])},
    "braganca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bragança is a city in the north-east of Portugal. There are 12 of its municipalities, composed of two distinct regions, the higher altitude ones, in the North, which constitute the Terra Fria Transmontana and the South, the Terra Quente Transmontana, with a milder climate. Overall, the district is mountainous. The Serra da Nogueira, with 1320 meters, separates the Tuela and Sabor valleys. The highest of all is the Serra de Montesinho, with immense trails, which rises to 1400 meters and extends into Spanish territory. History tells us that it was in Bragança that the secret marriage between D. Pedro and Inês de Castro took place. Walk through the historical center and don't miss the beauty of its monuments! Visit the neighboring counties, enjoy the landscape, and with Travizco, find out where to eat the best of these parts!"])},
    "castelo_branco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castelo Branco is located in the center interior of the country, in Beira Baixa. It is the capital of districts and there are eleven municipalities that belong to it. From the castle walls, you can see part of the city. Embroidery gained great importance in the region with the culture of linen as tradition. Local products are of great impact, such as olive oil and wine. From the pastoral tradition, cheese is produced, some of the most appreciated in the country. The production of cherry, ginja, and peach contributes to the economic development of the municipality. The geodetic landmark that marks the center of Portugal has a magnificent view! Religious and military architecture is spread throughout the various municipalities. With TRAVIZCO, come to the land of Albicastrenses!"])},
    "coimbra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coimbra is located in central Portugal, capital of the district. City of students that was once the capital of the country, with the Mondego river at its feet. An architectural complex that includes the University of Coimbra is a World Heritage Site. City of love and academic traditions, student residences and republics, a place of gatherings where several writers were formed. The beauty of the Joanina Library is unique! Taverns and snacks, convent sweets, typical dishes that can be found in all of its 17 municipalities. Golden sand beaches, high mountains, shale villages and Conímbriga, are great reasons for a visit. If you have never seen rice plantations, Travizco will show you where to find them!"])},
    "evora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évora, a city that breathes history and Portuguese art, its historic center has been declared a World Heritage Site by UNESCO. Praça do Giraldo, the Temple of Diana, the Chapel of Bones, the Cathedral, the Church of São Francisco... there is nothing like walking randomly with TRAVIZCO in your pocket to discover this wonderful city. But beauty and charm are not only found in Évora, don't miss the opportunity to explore the district where you will surely be captivated by its heritage and natural beauty. The landscape is typically Alentejo, flat with extensive olive groves and vineyards, arid in the driest months. From the top of Monsaraz, you can contemplate the vastness of the Alqueva reservoir, the largest artificial lake in Europe. At the foot of the Borba mountain range is Vila Viçosa characterized by a distinct royal atmosphere, in Arraiolos come and discover the tapestries, in Portel visit the imposing castle, schedule a visit to the Escoural Caves in Montemor-o-Novo... there is so much to say, but the best thing is to let yourself be carried away by TRAVIZCO on a journey through Central Alentejo, a territory of unique beauty, rich in culture, tradition, and gastronomy."])},
    "faro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faro is the capital of the Algarve, a region that needs no introduction. A territory known for its golden sand beaches, the barrocal from which its delicious honey, medronho, and cork stand out. Finally, the mountain range, which occupies 50% of the region, a land of great beauty whose highest peak is located in the Monchique mountain range at 902m altitude. Its temperate Mediterranean climate, bathed by more than 3000 hours of sunshine per year, makes the Algarve one of the most popular tourist destinations in the world and highly sought after for golfing. The Algarve is composed of 16 municipalities, each with a strong identity, full of stories and landscapes of unique beauty. Get lost in the Algarve and find your way with TRAVIZCO."])},
    "guarda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The district of Guarda is located in the center of the Beira region, between the Guarda-Sabugal plateau and the Serra da Estrela. It is within its walls that history has been told for over 800 years. A place of health and well-being due to the pure air that is breathed here. It is the region of the 5 F's: Strong for its granite, Abundant for its fertile valleys, Cold for its climate, Faithful for its people, and Beautiful for everything it has. The Mondego walkways invite you to take a stroll, starting at the Caldeirão Dam, with suspension bridges and incredible landscapes! There are 14 municipalities with places of rare beauty! Rock paintings, castles, and mountain cuisine are already reasons enough to come here! Take yourself and the Travizco and venture out!"])},
    "leiria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiria is located in the Central Coastal Region, it is the capital and seat of the district. The city grew up around the castle, near the Lis River. It has 16 municipalities with monuments of extraordinary beauty. The endless forests, pine forests, endless beaches, religious and civil architecture, its spas, and popular traditions are all of great importance!...the cuisine, between mountains and beaches, is very varied, with Portuguese fish and meat dishes, accompanied by wine from the Encostas de Aire DOC. Ceramics and glass are a reference. Other castles and monuments deserve a mandatory visit. If you like chocolate, don't miss the region's festival. TRAVIZCO will be with you to show you the way!"])},
    "lisboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisbon is the capital of the country, the district capital with the Tagus seeing its ships set sail for the discoveries more than 500 years ago. Lisbon is the city of the 7 hills, with its castles and imposing churches, with an incomparable beauty. It has been rebuilt from tragedy, with architectural monuments of great value, emblematic neighborhoods that have not ceased to have life over the years. With 18 municipalities, towns and cities with their own identities, with bullfighting arenas and traditions, vineyards and agricultural areas with the vegetable gardens that supply the great Lisbon. The beaches and the fishing villages that have become tourist sites, without losing what most characterizes them. The gastronomy is much more than you can write, it is to be there to taste. TRAVIZCO finds that place!"])},
    "madeira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madeira is an archipelago (with Porto Santo and Ilhas Desertas), with Funchal as its capital. Focused on tourism, visitors come from the four corners of the world, for the climate with mild temperatures and the landscapes with mountains and charming valleys. In its 11 municipalities, look for the viewpoints and admire everything you see (Cabo Girão and the skywalk). Be sure to climb Pico do Areeiro at over 1800m altitude. Natural pools, Santana houses, picturesque fishing villages, a world to discover with TRAVIZCO! Go up in the cable car, go down in the 'Carros de Cesto' and go to the Lavradores Market. In the gastronomy, fresh fish, espetada, bolo do caco, Madeira wine and Poncha...approved!"])},
    "portalegre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portalegre is located in Northern Alentejo, in the middle of the Serra de São Mamede Natural Park. It is a city surrounded by mountains, cliffs and plains, with landscapes of great beauty. The manufacture of tapestries has become an ex-libris of the town and throughout the region you can find handicrafts. There are many regional products, sausages, fruit, honey and cheeses that make the gastronomy rich, along with wines and craft beers. The conventual sweets that can be found throughout the territory are a delicacy of the Gods. The stud farms where the best Lusitano horses are bred, are lands of legends and traditions, festivals and pilgrimages that even have a 'Sintra of the Alentejo' for the beauty of its gardens, with some municipalities of Portalegre being the most fortified in Europe. With TRAVIZCO, come meet them!"])},
    "porto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city of Porto is the district and headquarters of the Oporto district located in the Northern Region, in the Douro Litoral. It groups 18 municipalities where most of the country's small and medium-sized enterprises are located. The Port of Leixões and the Francisco Sá Carneiro airport are two major and important infrastructures in the region. Porto is the city that gave Portugal its name when it was called Portus Cale. It is world-renowned for its wine (Port wine), the colors of the Ribeira houses, and the historic center, which is a World Heritage Site. Francesinha is more famous here than in France, but the 'Tripas à moda do Porto' are 'portuenses'! Invicta (invincible) city and region of great beauty, both natural and architectural, all municipalities maintain their own identity. The rabelo boats that sail along the Douro show the bridges and the banks of the Douro. Don't waste any more time and with Travizco you will find all the hidden treasures out there!"])},
    "santarem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santarém belongs to the Ribatejo region, capital and seat of the district. The Serras de Aire and Candeeiros are imposing. All the architectural heritage of the region is of great value. The Templars left much of it, such as castles and churches. Go by boat to the castle that is in the middle of the river. The Sanctuary of Fátima attracts thousands of pilgrims throughout the year, with the site receiving thousands of people. Get to know the land of phenomena, lands of wines and melons, as well as the city where Luís de Camões lived. River beaches that fill up in the summer. Feeling hungry? The gastronomy of the region with traditional dishes is a must-try. Then, find the next direction with TRAVIZCO."])},
    "setubal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the south coast of the Tagus River, in the Sado estuary, lies the beautiful city of Setúbal, the capital of a district consisting of 13 municipalities. In the city of Bocage and Luísa Todi, the Convent of Jesus in Gothic-Manueline style, which houses the City Museum, and the Fort of São Filipe, converted into a Pousada, from where you can contemplate the city, the Sado River, Troia, and the Arrábida Mountain Range, are a must-visit. Nearby, visitors can find preserved natural areas such as the Sado River estuary and the Arrábida Natural Park. The beaches in this region are of unparalleled beauty, and the generous sea brings the best fish and seafood in Portugal to the table of those who visit the region. Inland, poultry dishes enrich the tables, such as Arroz de Pato, Cabidela de Galinha, or Feijoada de Frango. The Roast Pork Loin à Montijo is also worth mentioning. Bring TRAVIZCO with you and let it guide you through this land of contrasts between the sea and the river, between the coast and the inland."])},
    "viana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viana do Castelo (district seat) is an Atlantic city in the north of Portugal, in Alto Minho. Natural landscape, with the presence of river, mountain and sea (the Lima river rises in Spain and flows into Viana). At the top, the Basilica of Santa Luzia from where you have a beautiful view, not to be missed! Take the Elevator that makes a 650 meters course (the longest in Portugal). There are 10 municipalities in Viana do Castelo, one of the most beautiful in the country. Viana do Castelo has a strong connection with codfish, from here the codfishers used to go to Newfoundland and Greenland. There are several gastronomic delicacies throughout the district. The Senhora da Agonia celebrated on August 20 attracts many visitors, as do the fine sandy beaches. The architecture of the historic center and the Eiffel Bridge... The well known Vinho Verde wines are not to be missed! And as confectionery, the Torta de Viana, certified by the City Council. With Travizco, let yourself go and surprise! As a souvenir, take a Valentine's handkerchief or an object in filigree."])},
    "vila_real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This way to Marão, those who are here are in charge! Vila Real, the city and district of the same name, is located in northern Portugal, in the former province of Trás-os-Montes and Alto Douro. It is located on the slopes of the Serra do Marão and next to the Serra do Alvão. Fourteen municipalities belong to the district of Vila Real, each with its own particularities that characterize them so well. The landscape is breathtaking! Wonderful Kingdom of Miguel Torga, is today a tourist destination of excellence! There are many Quintas you can visit and where you can do wine tasting. The region's gastronomy, so rich and diversified, is a must to try and taste. With the construction of the Marão tunnel with almost 6 kilometers, the longest road tunnel in the country, it brought even closer the districts of Braga, Porto and Guimarães. With the N2 passing through these municipalities, Travizco tells you what to find and visit!"])},
    "viseu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city of Viseu is the seat and capital of the district in the North Central region. It is the largest district in the country with 24 municipalities, each with unique characteristics. The Viseu Dão-Lafões region is protected by the Serra da Estrela, Montemuro, and Caramulo mountains, and it is in its terroirs that award-winning nectars are born. Enjoy the region's spas to rejuvenate yourself and let yourself be carried away by the mountain trails, breathe the fresh air, and appreciate the landscape. If you wish, you can also perfect your golf technique. The City of Viriato has so many monuments, an interesting architectural legacy in all the municipalities. The gastronomy of the region is rich and diverse. Going, going, with TRAVIZCO, on the way to Viseu!"])}
  }
}