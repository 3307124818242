<template>
  <v-layout class="main-div">
    <div class="intro d-block">
      <h1 class="logo-header">
        <!-- md and up -->
        <div v-if="$vuetify.display.smAndUp" class="d-block" style="text-align: center">
          <div class="d-flex align-end">
            <img ref="qrLogo" class="logo logo-image">
            <span class="logo travizco-text">Trav</span>
            <span class="logo travizco-text">iz</span>
            <span class="logo travizco-text">co</span>
          </div>
          <div>
            <span class="logo subtitle">{{
              $t("home.subtitle1")
            }}.</span>
          </div>
        </div>
        <!-- xs -->
        <div v-if="$vuetify.display.xs" class="d-block" style="text-align: center">
          <v-container fluid class="d-flex ">
            <img ref="qrLogo" class="logo logo-image-mobile mr-2">
            <span class="logo travizco-text-mobile">Trav</span>
            <span class="logo travizco-text-mobile">iz</span>
            <span class="logo travizco-text-mobile">co</span>
          </v-container>
          <div>
            <span class="logo subtitle-mobile">{{
              $t("home.subtitle1")
            }}</span>
          </div>
        </div>
      </h1>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"],
  methods: {
    setTime() {
      let intro = document.querySelector(".intro");
      //let logo = document.querySelector(".logo-header");
      let logoSpan = document.querySelectorAll(".logo");

      window.addEventListener("DOMContentLoaded", () => {
        setTimeout(() => {
          logoSpan.forEach((span, idx) => {
            setTimeout(() => {
              span.classList.add("active");
            }, (idx + 1) * 200);
          });

          setTimeout(() => {
            logoSpan.forEach((span, idx) => {
              setTimeout(() => {
                span.classList.remove("active");
                span.classList.add("fade");
              }, (idx + 1) * 50);
            });
          }, 2000);

          setTimeout(() => {
            intro.style.top = "-100vh";
          }, 2300);
        });
      });
    },
  },
  mounted() {
    const img = new Image();
    img.src = require("../assets/whiteqr.svg");
    img.onload = () => {
      this.$refs.qrLogo.src = img.src;
    };
    this.setTime();
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.25rem rgba(18, 243, 37, 0.5);
} */

.main-div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo-image {
  height: 105px;
  width: 150px;
}

.logo-image-mobile {
  height: 50px;
  width: 50px;
}

.travizco-text {
  color: white;
  font-family: Droidiga;
  font-size: 85px;
}

.travizco-text-mobile {
  color: white;
  font-family: Droidiga;
  font-size: 40px;
}

.subtitle {
  color: white;
  font-size: 25px;
}

.subtitle-mobile {
  color: white;
  font-size: 15px;
}

.intro {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(30, 104, 146);
  transition: 1s;
}

.logo-header {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  position: relative;
  display: inline-block;
  opacity: 0;
}

.logo.active {
  bottom: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}

.logo.fade {
  bottom: 150px;
  opacity: 0;
  transition: ease-in-out 0.5s;
}
</style>