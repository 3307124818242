import { createRouter, createWebHistory } from 'vue-router'
import Home from "../views/Home";
import Privacy from "../views/Privacy";
import Business from "../views/Business";
import Destinations from "../views/Destinations";
import DestinationsDetails from "../views/DestinationDetails"
import { nextTick } from "vue";
import { trackRouter } from "vue-gtag-next";

const DEFAULT_TITLE = 'Travizco';
const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/business',
    name: 'business',
    component: Business
  },
  {
    path: '/',
    name: 'home',
    component: Home
  }
  , {
    path: '/destinations',
    name: 'destinations',
    component: Destinations
  },
  , {
    path: '/destinations/:name',
    name: 'destinationDetails',
    component: DestinationsDetails
  },
  /* {
    path: '/blog',
    name: 'blog',
    beforeEnter(to, from, next) {
      window.location.href = "https://blog.travizco.com/";
    }
  } */,
  {
    path: '/:catchAll(.*)',
    name: 'error',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  //base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  }
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = DEFAULT_TITLE;
    document.im
  });
});

trackRouter(router);

export default router
