<template>
  <div>
    <AppBar :show-tabs="showTabs" :isTouchless="touchlessBoolean" :tabs="tabs" :tab-index="tab_selected" :shrink-bar="shrinkBar"></AppBar>

    <!-- title -->
    <!-- md and up -->
    <v-row v-if="$vuetify.display.mdAndUp" class="header justify-right align-right">
      <v-col cols="1"></v-col>
      <v-col cols="3">
        <v-img class="mt-6" :src="imagemTeste" max-height="230"></v-img>
      </v-col>
      <v-col class="text-start pt-6" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-center" cols="7">
        <p class="font-weight-medium header-title">{{ $t("destinations.title") }}</p>
        <p
          :class="{
            'mt-6 font-weight-medium text-colors text-body-1': $vuetify.display.lgAndUp,
            'mt-6 font-weight-medium text-colors text-body-2': $vuetify.display.md,
          }"
        >
          {{ $t("destinations.description") }}
        </p>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <!-- xs mobile -->
    <v-row v-else class="header-mobile justify-right align-right">
      <v-col cols="12" class="text-center" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-center">
        <p class="font-weight-medium pt-6 header-title-mobile">{{ $t("destinations.title") }}</p>
        <p class="pl-6 pr-6 pt-2 text-caption font-weight-medium text-color">
          {{ $t("destinations.description") }}
        </p>
      </v-col>
    </v-row>

    <!-- description -->
    <!--   <v-container fluid class="text-justify">
      <p v-if="$vuetify.display.mdAndUp" class="pl-12 pr-12 pt-8 ml-12 mr-12 text-h6 font-weight-medium text-colors">
        {{ $t("destinations.description") }}
      </p>
      <p v-else class="pl-6 pr-6 pt-2 text-caption font-weight-medium text-color">
        {{ $t("destinations.description") }}
      </p>
    </v-container>
 -->
    <!-- cards -->
    <v-container class="pa-5" fluid>
      <v-row class="justify-center mt-6">
        <v-col class="d-flex justify-center pa-8" v-for="district in districts" :key="district.id" cols="12" xl="4" lg="4" md="4" sm="5">
          <v-card v-on:click="goToDetails(district.name)" elevation="5" class="card-expand rounded-xl" width="350">
            <v-img
              class="title-hover text-center align-center justify-center"
              aspect-ratio="1"
              style="text-shadow: 1.2px 1.2px black"
              :src="district.image"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              cover
            >
              <p class="font-weight-medium text-h4 text-white" v-text="district.name"></p>

              <v-spacer style="height: 72%"></v-spacer>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Footer :show-business-logo="showBusinessLogo"></Footer>
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer.vue";

export default {
  name: "Destinations",

  data() {
    return {
      showBusinessLogo: true,
      show: false,
      showPills: false,
      imagemTeste: require("../assets/destination_mockup.png"),
      districts: [
        {
          id: 1,
          name: "Aveiro",
          image: require("../assets/destinations/aveiro.webp"),
        },
        {
          id: 2,
          name: "Açores",
          image: require("../assets/destinations/acores.webp"),
        },
        {
          id: 3,
          name: "Beja",
          image: require("../assets/destinations/beja.webp"),
        },
        {
          id: 4,
          name: "Braga",
          image: require("../assets/destinations/braga.webp"),
        },
        {
          id: 5,
          name: "Bragança",
          image: require("../assets/destinations/braganca.webp"),
        },
        {
          id: 6,
          name: "Castelo Branco",
          image: require("../assets/destinations/castelo_branco.webp"),
        },
        {
          id: 7,
          name: "Coimbra",
          image: require("../assets/destinations/coimbra.webp"),
        },
        {
          id: 8,
          name: "Évora",
          image: require("../assets/destinations/evora.webp"),
        },
        {
          id: 9,
          name: "Faro",
          image: require("../assets/destinations/faro.webp"),
        },
        {
          id: 9,
          name: "Guarda",
          image: require("../assets/destinations/guarda.webp"),
        },
        {
          id: 10,
          name: "Leiria",
          image: require("../assets/destinations/leiria.webp"),
        },
        {
          id: 11,
          name: "Lisboa",
          image: require("../assets/destinations/lisboa.webp"),
        },
        {
          id: 12,
          name: "Madeira",
          image: require("../assets/destinations/madeira.webp"),
        },
        {
          id: 13,
          name: "Portalegre",
          image: require("../assets/destinations/portalegre.webp"),
        },
        {
          id: 14,
          name: "Porto",
          image: require("../assets/destinations/porto.webp"),
        },
        {
          id: 15,
          name: "Santarém",
          image: require("../assets/destinations/santarem.webp"),
        },
        {
          id: 16,
          name: "Setúbal",
          image: require("../assets/destinations/setubal.webp"),
        },
        {
          id: 17,
          name: "Viana do Castelo",
          image: require("../assets/destinations/viana.webp"),
        },
        {
          id: 18,
          name: "Vila Real",
          image: require("../assets/destinations/vila_real.webp"),
        },
        {
          id: 19,
          name: "Viseu",
          image: require("../assets/destinations/viseu.webp"),
        },
      ],
    };
  },
  methods: {
    goToDetails(destinationName) {
      this.$router.push({
        name: "destinationDetails",
        params: { name: destinationName },
      });
    },
  },
  components: {
    AppBar,
    Footer,
  },
  created() {
    AOS.init();
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.15rem rgba(18, 243, 37, 0.5);
} */

.text-color {
  color: darkslategray;
}

.pills {
  display: none;
}

.pills:hover {
  display: flex;
}

.header {
  background-color: #d8e4ec;
  height: max-content;
  padding-top: 2vh;
  padding-bottom: 2vh;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.header-mobile {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #d8e4ec;
}

.header-title {
  font-family: Droidiga;
  color: #01579b;
  text-shadow: 1.5px 1.2px #002f5b;
  font-size: 45px;
  /* position: fixed; */
}

.header-title-mobile {
  font-family: Droidiga;
  color: #01579b;
  text-shadow: 1.5px 1.2px #002f5b;
  font-size: 35px;
}

.card-expand {
  transition: transform 0.5s;
}

.card-expand:hover {
  transform: scale(1.08);
  transition: transform 0.5s;
}
</style>
