import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import heading from './plugins/heading';
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app
  .use(i18n)
  .use(vuetify)
  .use(heading)
  .use(router)
  .use(VueGtag, {
    property: { id: process.env.VUE_APP_GTAG_KEY },
    globalObjectName: "gtag",
    appName: "Travizco",
    isEnabled: false
  })
  .mount('#app')
