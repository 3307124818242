<template>
  <v-container class="bg-primary" fluid>
    <v-row class="mt-10 d-flex ml-7">
      <div>
        <v-img :src="images.qrLogo" cover height="28" width="45"></v-img>
      </div>
      <div>
        <v-img :src="images.whiteTravizco" height="25" width="170"></v-img>
      </div>
    </v-row>

    <v-card-text class="text-white mt-2" :class="{ 'text-caption': $vuetify.display.xs }" style="margin-left: 20px">
      <p class="mt-4">{{ $t("home.footerDescription1") }}</p>
      <p class="mt-4">{{ $t("home.footerDescription2") }}</p>
      <p class="mt-4">{{ $t("home.footerDescription3") }}</p>
      <p class="mt-4">{{ $t("home.footerDescription4") }}</p>
      <p class="mt-4">{{ $t("home.footerDescription5") }}</p>
    </v-card-text>

    <!-- sm and up -->
    <div v-if="$vuetify.display.smAndUp">
      <h2 class="text-white pl-9 pt-6">Download:</h2>

      <v-row class="justify-space-between">
        <div class="d-block">
          <div class="d-flex ml-12 pt-5 pb-5" cols="2" style="height: fit-content">
            <a class="imageExpand" href="https://apps.apple.com/app/travizco/id1613139068" @click="btnClickAppStore">
              <v-img width="100px" class="mr-2" contain :src="images.appStoreButton"></v-img>
            </a>
            <a class="imageExpand" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco" @click="btnClickPlayStore">
              <v-img width="100px" class contain :src="images.googlePlayButton"></v-img>
            </a>
          </div>

          <p class="ml-12 text-body-2 font-weight-medium">{{ $t("contact") }}</p>

          <div class="underlineText pt-2 ml-12 d-flex text-body-2">
            <p>{{ $t("business_form.email") }}:</p>
            <a class="travizco-mail" href="mailto:support@travizco.com">&nbsp;support@travizco.com</a>
          </div>
          <div class="underlineText pt-2 ml-12 d-flex text-body-2">
            {{ $t("phone_number") }}:
            <span>&nbsp;+351 223 243 591</span>
          </div>
        </div>

        <div class="pr-12">
          <router-link to="/business" style="text-decoration: none">
            <v-container class="underlineText marginless-container white--text">
              <a>
                <v-img v-if="showBusinessLogo" class="imageExpand" width="150px" contain :src="images.whiteBusinessLogo"></v-img>
              </a>
            </v-container>
          </router-link>
        </div>
      </v-row>
    </div>

    <!-- mobile policy -->
    <div v-else>
      <v-row class="mt-5">
        <v-col cols="7" class="pl-12">
          <h2 class="text-white text-subtitle-1 font-weight-bold">Download:</h2>
          <div class="mt-3 d-block">
            <a class="imageExpand" href="https://apps.apple.com/app/travizco/id1613139068" @click="btnClickAppStore">
              <v-img width="95px" contain :src="images.appStoreButton"></v-img>
            </a>
            <a class="imageExpand" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco" @click="btnClickPlayStore">
              <v-img width="95px" contain :src="images.googlePlayButton"></v-img>
            </a>
          </div>
          <p class="text-caption font-weight-medium mt-3">{{ $t("contact") }}</p>

          <div class="underlineText pt-1 d-block text-caption">
            <p>{{ $t("business_form.email") }}:</p>
            <a class="travizco-mail" href="mailto:support@travizco.com">support@travizco.com</a>
          </div>
          <div class="underlineText pt-2 d-block text-caption">
            <div>{{ $t("phone_number") }}:</div>
            <div>+351 223 243 591</div>
          </div>
        </v-col>
        <v-col cols="5" class="mb-2" style="align-self: end">
          <router-link to="/business" style="text-decoration: none">
            <v-container class="underlineText marginless-container text-white">
              <a>
                <v-img v-if="showBusinessLogo" class="imageExpand" width="100px" contain :src="images.whiteBusinessLogo"></v-img>
              </a>
            </v-container>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <div style="height:105px"></div>
  </v-container>

  <!-- Footer -->
  <v-footer class="bg-primary pa-0 d-block" app>
    <v-row class="pa-0" style="margin:0px; max-height: 100%;" :class="{ 'd-flex': $vuetify.display.smAndUp, 'd-block': $vuetify.display.xs }">
      <!-- follow us -->
      <v-col cols="3" v-show="$vuetify.display.smAndUp">
        <div class="d-flex justify-start ml-8">
          <v-btn v-for="i in icons" :key="i.id" :href="i.link" :icon="i.icon" variant="text" size="32" @click="btnClickSocialMedia" />
          <v-btn href="https://www.tiktok.com/@travizco" variant="text" icon size="32" @click="btnClickSocialMedia">
            <v-img contain height="32" width="32" :src="tiktokIcon" aspect-ratio="1"></v-img>
          </v-btn>
        </div>
      </v-col>

      <!-- copyright -->
      <v-col cols="6" v-show="$vuetify.display.smAndUp">
        <div :class="{ 'text-subtitle-2 pt-1': $vuetify.display.mdAndUp, 'text-caption ': $vuetify.display.sm }">
          <p class="text-center">
            Copyright &copy;&nbsp;2009 - {{ new Date().getFullYear() }}&nbsp; Impactzero, Software lda.&nbsp;-
            <router-link to="/privacy" style="color:white">{{ $t('common.TermsConditions') }}</router-link>
          </p>
        </div>
      </v-col>

      <!-- for xs only -->
      <div v-show="$vuetify.display.xs">
        <div class="d-block example" style="text-align: center;">
          <div>
            <div style="font-size: 10px" class="pb-1">
              Copyright &copy;&nbsp;2009 - {{ new Date().getFullYear() }}&nbsp; Impactzero, Software lda.&nbsp;-
              <router-link to="/privacy" style="color:white">&nbsp;{{ $t('common.TermsConditions') }}</router-link>
            </div>
          </div>
          <div class="d-flex justify-center">
            <div class="d-flex">
              <v-btn class="mx-1" v-for="i in icons" :key="i.id" :href="i.link" :icon="i.icon" variant="text" size="23" style="font-size: 12px;" />
              <v-btn class="mx-1" href="https://www.tiktok.com/@travizco" variant="text" icon size="23">
                <v-img contain height="23" width="23" :src="tiktokIcon" aspect-ratio="1"></v-img>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- <GiveawayVue v-if="$vuetify.display.smAndDown"></GiveawayVue> -->
  </v-footer>
</template>

<script setup>
import { defineProps, defineAsyncComponent } from "vue";

defineProps({
  showBusinessLogo: {
    type: Boolean,
  },
});

/* const GiveawayVue = defineAsyncComponent(() =>
  import("../components/GiveawayComponent.vue")
); */

const images = {
  appStoreButton: require("../assets/app_store_icon.png"),
  googlePlayButton: require("../assets/google_play_icon.png"),
  whiteBusinessLogo: require("../assets/home/business_logo.svg"),
  whiteTravizco: require("../assets/headerimg.png"),
  qrLogo: require("../assets/qrLogo.jpg"),
};

const icons = [
  {
    icon: "mdi-facebook",
    link: "https://www.facebook.com/Travizco-112803288127105",
  },
  {
    icon: "mdi-instagram",
    link: "https://www.instagram.com/travizco_iz",
  },
  {
    icon: "mdi-linkedin",
    link: "https://www.linkedin.com/company/86613576",
  },
];

function btnClickSocialMedia() {
  this.$gtag.event("Social Media Button", {
    event_category: "Link",
    event_label: "click link",
    value: 1,
  });
}
const tiktokIcon = require("../assets/tiktok_icon.png");
</script>
<style scoped>
/* * {
  outline: solid 0.25rem rgba(18, 243, 37, 0.5);
}
 */
body {
  overflow-x: hidden;
  width: 100%;
}

.tiktok:hover {
  background-color: rgb(255, 255, 255, 0.1);
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 35px;
}

.footer {
  vertical-align: middle;
  height: 40px;
}

.example {
  display: flex;
  justify-content: center;
}

.travizco-mail {
  text-decoration: none;
  color: white;
}

.travizco-mail:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 331px) {
  .example {
    margin-top: 10px;
  }
}
</style>