<template>
  <!-- sm and up -->
  <v-card v-if="showCookieCard && $vuetify.display.smAndUp" class="myCookieCard bg-dark-gray-1 pa-2" color="white" :title="$t('cookies.title')" variant="tonal">
    <v-card-text>
      {{ $t('cookies.textToShow') }}
      <router-link to="/privacy" style="color:white">{{ $t('common.TermsConditions') }}</router-link>
    </v-card-text>
    <v-card-actions>
      <v-btn class="bg-success" v-on:click="enable">{{ $t('cookies.accept_all') }}</v-btn>
      <v-btn variant="outlined" v-on:click="moreOptions">{{ $t('cookies.options') }}</v-btn>
    </v-card-actions>
  </v-card>
  <!-- mobile -->
  <v-card v-if="showCookieCard && $vuetify.display.xs" class="myCookieCardMobile bg-dark-gray-1 pa-2" color="white" :title="$t('cookies.title')" variant="tonal">
    <v-card-text class="text-caption">
      {{ $t('cookies.textToShow') }}
      <router-link to="/privacy" style="color:white">{{ $t('common.TermsConditions') }}</router-link>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn class="bg-success" size="small" v-on:click="enable">{{ $t('cookies.accept_all') }}</v-btn>
      <v-btn variant="outlined" size="small" v-on:click="moreOptions">{{ $t('cookies.options') }}</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model="showCookieDialog" persistent class="pa-2 justify-center" :width="$vuetify.display.smAndDown ? '100%' : '50%'">
    <v-card class="pa-3">
      <v-card-title class="text-h6 align-center" prepend-icon="mdi-cookie">{{ $t('cookies.title') }}</v-card-title>
      <v-card-text class="text-caption">
        {{ $t('cookies.about_description') }}
        {{ $t('cookies.textToShow') }}
        <router-link to="/privacy">{{ $t('common.TermsConditions') }}</router-link>
      </v-card-text>
      <!--   <v-card-title class="text-h6">{{ $t('cookies.list') }}</v-card-title> -->
      <v-row v-for="cookieDetails in cookieArray" class="pa-7" align="center">
        <v-col cols="9">
          <div class="text-h6">{{ $t('cookies.target') }}</div>
          <div class="text-caption">{{ $t('cookies.description') }}</div>
        </v-col>
        <v-col cols="3">
          <div class="d-flex justify-center align-center">
            <v-switch class="switch-center" inset color="success" v-model="cookieDetails.accepted"></v-switch>
          </div>
        </v-col>
      </v-row>
      <v-card-actions v-if="$vuetify.display.smAndUp">
        <v-btn class="bg-success" v-on:click="saveAndExit">{{ $t('cookies.save_exit') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" v-on:click="refuseAll">{{ $t('cookies.reject') }}</v-btn>
        <v-btn class="bg-success" v-on:click="enable">{{ $t('cookies.accept_all') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-block ml-4">
        <v-btn class="bg-success ml-2" v-on:click="saveAndExit">{{ $t('cookies.save_exit') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" class="mt-3" v-on:click="refuseAll">{{ $t('cookies.reject') }}</v-btn>
        <v-btn class="bg-success mt-3" v-on:click="enable">{{ $t('cookies.accept_all') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script scoped>
import { ref, watch, onMounted } from "vue";
import { useState, isTracking, useGtag } from "vue-gtag-next";

let showCookieCard = ref(true);
let showCookieDialog = ref(false);
export default {
  setup() {
    const acceptAllCookiesKey = "ALL-COOKIES-ACCEPTED";
    const { isEnabled } = useState();
    const { config } = useGtag();

    const enable = () => {
      isEnabled.value = true;
      localStorage.setItem(acceptAllCookiesKey, "true");
      showCookieCard.value = false;
      showCookieDialog.value = false;
    };

    const cookieArray = ref([
      {
        key: "COOKIE-TARGETING",
        title: "title",
        description: "desc",
        accepted: true,
      },
    ]);

    function refuseAll() {
      localStorage.setItem(acceptAllCookiesKey, "false");
      for (const cookieObj of cookieArray.value) {
        cookieObj.accepted = false;
      }
    }

    function saveAndExit() {
      localStorage.setItem(acceptAllCookiesKey, "false");
      for (const cookieObj of cookieArray.value) {
        localStorage.setItem(cookieObj.key, cookieObj.accepted);
      }
      showCookieCard.value = false;
      showCookieDialog.value = false;
    }

    function moreOptions() {
      showCookieCard.value = false;
      showCookieDialog.value = true;
    }

    watch(isEnabled, (newValue, oldValue) => {
      if (newValue || isEnabled.value) {
        config({ category: "Tracking", action: "Enabled", isEnabled: "true" });
      }
    });

    // lifecycle hooks
    onMounted(() => {
      showCookieCard.value = !localStorage.getItem(acceptAllCookiesKey);
    });

    return {
      isTracking,
      enable,
      showCookieDialog,
      showCookieCard,
      moreOptions,
      refuseAll,
      saveAndExit,
      cookieArray,
    };
  },
};
</script>
<style scoped>
.myCookieCard {
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  width: 20%;
  margin: 1rem;
  min-width: 400px;
  margin-bottom: 60px;
}

.myCookieCardMobile {
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  width: 20%;
  margin: 1rem;
  min-width: 300px;
  margin-bottom: 60px;
}

.switch-center {
  display: flex;
  justify-content: center;
}
</style>