<template>
  <!-- mdAndUp -->
  <v-form v-if="$vuetify.display.mdAndUp" ref="form" class="text-white">
    <v-container style="padding: 2vw 25vh">
      <div class="align-center d-flex" style="height: fit-content">
        <v-card-title class="headline font-weight-bold">
          {{
          $t("business_form.register_title")
          }}
        </v-card-title>
        <v-tooltip location="end" text="Preencha o formulário para nos contactar diretamente via email">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" color="#fff">mdi-information</v-icon>
          </template>
        </v-tooltip>
      </div>

      <!-- select type -->
      <v-select required class="mb-3 mt-6" v-model="selectedPlace" :items="placeTypes" :rules="requiredRule" :label="$t('business_form.place')" variant="outlined"></v-select>

      <!-- POIs -->
      <div v-if="selectedPlace === placeTypes[0]">
        <v-autocomplete
          class="mb-3"
          required
          v-model="selectedPoiType"
          :items="poiTypes"
          :rules="requiredRule"
          :label="$t('business_form.poi_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
        ></v-autocomplete>
      </div>

      <!-- Service -->
      <div v-if="selectedPlace === placeTypes[1]">
        <v-autocomplete
          class="mb-3"
          required
          v-model="selectedServiceType"
          :items="(serviceObject = serviceTypes.map((item) => item.name))"
          :rules="requiredRule"
          :label="$t('business_form.service_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
        ></v-autocomplete>

        <v-autocomplete
          required
          class="mb-3"
          v-model="selectedServiceSubType"
          :items="getServiceSubtypes"
          :label="$t('business_form.service_subtype')"
          :disabled="!selectedServiceType"
          :rules="requiredRule"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
        ></v-autocomplete>
      </div>

      <!-- name -->
      <v-text-field class="mb-3" required v-model="name" :rules="requiredRule" :label="$t('business_form.name')" variant="outlined"></v-text-field>

      <!-- email -->
      <v-text-field class="mb-3" required v-model="email" :rules="emailRules" :label="$t('business_form.email')" variant="outlined"></v-text-field>

      <!-- Contacto telefónico -->
      <v-text-field class="mb-3" v-model="phone" :rules="phoneRules" :label="$t('business_form.phone')" variant="outlined"></v-text-field>

      <!-- Morada Completa -->
      <v-text-field class="mb-3" v-model="address" :rules="requiredRule" :label="$t('business_form.address')" variant="outlined"></v-text-field>

      <!-- pergunta colaborador-->
      <h3 class="mb-3 ml-1">{{ $t("business_form.colaborator_question") }}</h3>

      <div class="ml-1">
        <v-radio-group class="mb-1 mt-1" inline v-model="colaborator" :rules="requiredRule">
          <v-radio :label="$t('business_form.yes')" value="Sim" class="mr-3"></v-radio>
          <v-radio :label="$t('business_form.no')" value="Não"></v-radio>
        </v-radio-group>
      </div>

      <!-- função colaborador -->
      <div v-if="colaborator === 'Sim'">
        <h3 class="mb-1">{{ $t("business_form.colaborator_role") }}</h3>
        <v-autocomplete required class="mt-3 mb-2" v-model="colaboratorRole" :items="colaboratorRoles" label="Função" :rules="requiredRule" :menu-props="{ maxHeight: '300px' }" variant="outlined"></v-autocomplete>
      </div>

      <!-- Form map component -->
      <FormMap class="mb-12" v-on:local-selected="onLocalSelected" v-on:latitude-selected="onLatitudeSelected" v-on:longitude-selected="onLongitudeSelected"></FormMap>

      <!-- notas -->
      <h3 class="mb-1 ml-1 mt-5">{{ $t("business_form.aditional_comments") }}</h3>
      <v-textarea single-line class="mt-3 mb-2" v-model="notes" :label="$t('business_form.notes')" variant="outlined"></v-textarea>

      <v-btn color="green" class="mt-3 mb-1" @click="validate">
        {{
        $t("business_form.send")
        }}
      </v-btn>
    </v-container>
  </v-form>

  <!-- sm -->
  <v-form v-if="$vuetify.display.sm" ref="form" style="overflow: auto">
    <v-container class="text-white text-body-2 overflow-y-auto">
      <!-- select type -->
      <v-select required class="mb-1" v-model="selectedPlace" :items="placeTypes" :rules="requiredRule" :label="$t('business_form.place')" variant="outlined" density="comfortable"></v-select>

      <!-- POIs -->
      <div v-if="selectedPlace === placeTypes[0]">
        <v-autocomplete
          class="mb-1"
          required
          v-model="selectedPoiType"
          :items="poiTypes"
          :rules="requiredRule"
          :label="$t('business_form.poi_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <!-- Service -->
      <div v-if="selectedPlace === placeTypes[1]">
        <v-autocomplete
          class="mb-1"
          required
          v-model="selectedServiceType"
          :items="
          (serviceObject = serviceTypes.map((item) => item.name))
        "
          :rules="requiredRule"
          :label="$t('business_form.service_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>

        <v-autocomplete
          required
          class="mt-1 mb-2"
          v-model="selectedServiceSubType"
          :items="getServiceSubtypes"
          :label="$t('business_form.service_subtype')"
          :disabled="!selectedServiceType"
          :rules="requiredRule"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <!-- name -->
      <v-text-field required class="mb-1" v-model="name" :rules="requiredRule" :label="$t('business_form.name')" variant="outlined" density="comfortable"></v-text-field>

      <!-- email -->
      <v-text-field required class="mb-1" v-model="email" :rules="emailRules" :label="$t('business_form.email')" variant="outlined" density="comfortable"></v-text-field>

      <!-- Contacto telefónico -->
      <v-text-field required class="mb-1" type="number" v-model="phone" :rules="requiredRule" :label="$t('business_form.phone')" variant="outlined" density="comfortable"></v-text-field>

      <!-- Morada Completa -->
      <v-text-field class="mb-3" v-model="address" :rules="requiredRule" :label="$t('business_form.address')" variant="outlined"></v-text-field>

      <!-- pergunta colaborador-->
      <h3 class="mb-1 ml-1">{{ $t("business_form.colaborator_question") }}</h3>

      <div class="ml-1">
        <v-radio-group class="mb-1 mt-1" inline v-model="colaborator" :rules="requiredRule">
          <v-radio :label="$t('business_form.yes')" value="Sim" class="mr-3"></v-radio>
          <v-radio :label="$t('business_form.no')" value="Não"></v-radio>
        </v-radio-group>
      </div>

      <!-- função colaborador -->
      <div v-if="colaborator === 'Sim'">
        <h3 class="mb-1">{{ $t("business_form.colaborator_role") }}</h3>
        <v-autocomplete
          required
          class="mt-3 mb-2"
          v-model="colaboratorRole"
          :items="colaboratorRoles"
          label="Função"
          :rules="requiredRule"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <FormMap v-on:local-selected="onLocalSelected" v-on:latitude-selected="onLatitudeSelected" v-on:longitude-selected="onLongitudeSelected"></FormMap>

      <!-- notas -->
      <h3 class="mb-1 ml-1 mt-4">{{ $t("business_form.aditional_comments") }}</h3>
      <v-textarea single-line class="mt-3 mb-2" v-model="notes" :label="$t('business_form.notes')" variant="outlined"></v-textarea>

      <div class="text-center">
        <v-btn color="green" class="mt-2 mb-1" @click="validate">
          {{
          $t("business_form.send")
          }}
        </v-btn>
      </div>
    </v-container>
  </v-form>

  <!-- xs -->
  <v-form v-if="$vuetify.display.xs" ref="form" style="overflow-y: auto">
    <v-container class="text-white text-body-2 overflow-y-auto">
      <!-- select type -->
      <v-select required class="mb-1" v-model="selectedPlace" :items="placeTypes" :rules="requiredRule" :label="$t('business_form.place')" variant="outlined" density="comfortable"></v-select>

      <!-- POIs -->
      <div v-if="selectedPlace === placeTypes[0]">
        <v-autocomplete
          class="mb-1"
          required
          v-model="selectedPoiType"
          :items="poiTypes"
          :rules="requiredRule"
          :label="$t('business_form.poi_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <!-- Service -->
      <div v-if="selectedPlace === placeTypes[1]">
        <v-autocomplete
          class="mb-1"
          required
          v-model="selectedServiceType"
          :items="
          (serviceObject = serviceTypes.map((item) => item.name))
        "
          :rules="requiredRule"
          :label="$t('business_form.service_type')"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>

        <v-autocomplete
          required
          class="mt-1 mb-2"
          v-model="selectedServiceSubType"
          :items="getServiceSubtypes"
          :label="$t('business_form.service_subtype')"
          :disabled="!selectedServiceType"
          :rules="requiredRule"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <!-- name -->
      <v-text-field required class="mb-1" v-model="name" :rules="requiredRule" :label="$t('business_form.name')" variant="outlined" density="comfortable"></v-text-field>

      <!-- email -->
      <v-text-field required class="mb-1" v-model="email" :rules="emailRules" :label="$t('business_form.email')" variant="outlined" density="comfortable"></v-text-field>

      <!-- Contacto telefónico -->
      <v-text-field required class="mb-1" type="number" v-model="phone" :rules="requiredRule" :label="$t('business_form.phone')" variant="outlined" density="comfortable"></v-text-field>

      <!-- Morada Completa -->
      <v-text-field class="mb-3" v-model="address" :rules="requiredRule" :label="$t('business_form.address')" variant="outlined"></v-text-field>

      <!-- pergunta colaborador-->
      <h3 class="mb-1 ml-1">{{ $t("business_form.colaborator_question") }}</h3>

      <div class="ml-1">
        <v-radio-group class="mb-1 mt-1" inline v-model="colaborator" :rules="requiredRule">
          <v-radio :label="$t('business_form.yes')" value="Sim" class="mr-3"></v-radio>
          <v-radio :label="$t('business_form.no')" value="Não"></v-radio>
        </v-radio-group>
      </div>

      <!-- função colaborador -->
      <div v-if="colaborator === 'Sim'">
        <h3 class="mb-1">{{ $t("business_form.colaborator_role") }}</h3>
        <v-autocomplete
          required
          class="mt-3 mb-2"
          v-model="colaboratorRole"
          :items="colaboratorRoles"
          label="Função"
          :rules="requiredRule"
          :menu-props="{ maxHeight: '300px' }"
          variant="outlined"
          density="comfortable"
        ></v-autocomplete>
      </div>

      <FormMap v-on:local-selected="onLocalSelected" v-on:latitude-selected="onLatitudeSelected" v-on:longitude-selected="onLongitudeSelected"></FormMap>

      <!-- notas -->
      <h3 class="mb-1 ml-1 mt-4">{{ $t("business_form.aditional_comments") }}</h3>
      <v-textarea single-line class="mt-3 mb-2" v-model="notes" :label="$t('business_form.notes')" variant="outlined"></v-textarea>

      <div class="text-center">
        <v-btn color="green" class="mt-2 mb-1" @click="validate">
          {{
          $t("business_form.send")
          }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import FormMap from "./FormMap.vue";

export default {
  name: "BusinessForm",
  data() {
    return {
      formValid: false,
      selectedPlace: null,
      selectedPoiType: null,
      selectedServiceType: null,
      selectedServiceSubType: null,
      email: null,
      phone: null,
      name: null,
      address: null,
      checkbox: false,
      colaborator: null,
      colaboratorRole: null,
      notes: null,
      valid: true,
      placeTypes: ["Ponto de Interesse", "Serviço"],
      poiTypes: [
        "Alminhas",
        "Arte Rupestre",
        "Baloiço",
        "Basílica",
        "Busto",
        "Campo de Gold",
        "Capela",
        "Cascata",
        "Castelo",
        "Castelo Roqueiro",
        "Catedral",
        "Chafariz",
        "Convento",
        "Cruzeiro",
        "Ecovia",
        "Edifício",
        "Edifício Religioso",
        "Espigueiro",
        "Estancia de Ski",
        "Estátua",
        "Floresta",
        "Fonte",
        "Fortaleza",
        "Forte",
        "Fortim",
        "Grutas",
        "Igreja",
        "Jardim",
        "Lagoa",
        "Mamoa",
        "Mercado",
        "Mesquita",
        "Militar",
        "Miradouro",
        "Mosteiro",
        "Museu",
        "Palácio",
        "Parque",
        "Parque Aquático",
        "Parque Temático",
        " Parque de Diversão",
        "Passadiços",
        "Piscina Natural",
        "Ponte",
        "Povoado Fortificado",
        "Praia",
        "Praia Fluvial",
        "Ruína",
        "Sepultura",
        "Sinagoga",
        "Solar",
        "Sítio Arqueológico",
        "Teleférico",
        "Templo",
        "Termas",
        "Torre",
        "Vulcão",
      ],
      serviceTypes: [
        {
          id: 1,
          name: "Abastecimento",
          subType: ["Bombas de Combustível", "Carregamento Carros Elétricos"],
        },
        {
          id: 2,
          name: "Bebidas",
          subType: ["Bar", "Café", "Pub", "Salão de Chá", "Taberna"],
        },
        {
          id: 3,
          name: "Compras",
          subType: ["Artesanato", "Produtos Locais", "Qiosque"],
        },
        {
          id: 4,
          name: "Dormidas",
          subType: [
            "Agroturismo",
            "Alojamento Local",
            "Aparthotel",
            "Enoturismo",
            "Hostel",
            "Hotel",
            "Hotel Rural",
            "Hotel de Charme",
            "Motel",
            "Parque de Autocaravanas",
            "Parque de Campismo",
            "Pensão",
            "Pousada",
            "Pousada da Juventude",
            "Residencial",
            "Turismo Rural",
            "Turismo de Habitação",
          ],
        },
        {
          id: 5,
          name: "Multibanco",
          subType: [
            "Abanca",
            "Banco BIG",
            "Banco Bilbao e Vizcaya",
            "Banco Ctt",
            "Banco Popular",
            "Banco Português de Investimentp",
            "Bankinter",
            "Barclays",
            "Best Bank",
            "Caixa Geral de Depósitos",
            "Crédito Agrícola",
            "Eurobic",
            "Milenium",
            "Montepio",
            "Novo Banco",
            "Santander Totta",
          ],
        },
        {
          id: 6,
          name: "Restaurante",
          subType: [
            "Contemporâneo",
            "Internacional",
            "Pastelaria",
            "Restaurante Angolano",
            "Restaurante Argentino",
            "Restaurante Asiático",
            "Restaurante Brasileiro",
            "Restaurante Britanico",
            "Restaurante Chinês",
            "Restaurante Churrasqueira",
            "Restaurante Espanhol",
            "Restaurante Francês",
            "Restaurante Grego",
            "Restaurante Hamburger",
            "Restaurante Indiano",
            "Restaurante Israelita",
            "Restaurante Italiano",
            "Restaurante Japonês",
            "Restaurante Mariqueira",
            "Restaurante Marroquino",
            "Restaurante Mediterrâneo",
            "Restaurante Moçambicano",
            "Restaurante Norte-Americano",
            "Restaurante Tailandês",
            "Restaurante Turco",
            "Restaurante Vegano",
            "Restaurante Vegetariano",
            "Restaurante Vietnamita",
            "Restaurante Àrabe",
            "Sanduicheira",
            "Tapas",
          ],
        },
        {
          id: 7,
          name: "Saúde",
          subType: [
            "Centro de Saúde",
            "Clínicas",
            "Farmácia",
            "Hospital",
            "Veterinário",
          ],
        },
        {
          id: 8,
          name: "Segurança",
          subType: [
            "Bombeiros",
            "Guarda Nacional Republicana",
            "Polícia Municipal",
            "Polícia de Segurança Pública",
          ],
        },
        {
          id: 9,
          name: "Serviços",
          subType: ["Diversos"],
        },
        {
          id: 10,
          name: "Serviços Públicos",
          subType: ["Junta de Freguesia"],
        },
      ],
      colaboratorRoles: [
        "Atendimento Cliente",
        "Consultor",
        "Contabilidade",
        "Gestor",
        "Proprietário",
        "Marketing",
        "Vendas",
        "Outro",
      ],
      /* rules */
      requiredRule: [],
      emailRules: [],
      phoneRules: [],
    };
  },
  mounted() {
    /* default map values */
    this.localSelected = "";
    this.longitudeSelected = "0";
    this.latitudeSelected = "0";
    /* push rules with translations to the array */

    this.requiredRule.push(
      (v) => !!v || this.$t("business_form.mandatory_field")
    );
    this.emailRules.push(
      (v) => !!v || this.$t("business_form.mandatory_field"),
      (v) => /.+@.+\..+/.test(v) || this.$t("business_form.invalid_email")
    );
    this.phoneRules.push(
      (v) => !!v || this.$t("business_form.mandatory_field"),
      (v) => /^[0-9]*$/.test(v) || this.$t("business_form.invalid_phone")
    );
  },
  methods: {
    /* form methods */
    async validate() {
      //console.log("place: " + this.localSelected);
      //console.log("lat: " + this.latitudeSelected);
      //console.log("long: " + this.longitudeSelected);
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.sendEmail();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    sendEmail() {
      let place,
        placeType,
        colaborator,
        aditionalComments,
        local = "";
      if (this.selectedPlace !== undefined) {
        place = `Local a adicionar: ${this.selectedPlace}\n`;
        if (this.selectedPoiType) {
          placeType = `Categoria do Ponto de Interesse: ${this.selectedPoiType}\n`;
        } else {
          if (
            this.selectedServiceType &&
            this.selectedServiceSubType !== undefined
          ) {
            placeType = `Categoria do Serviço: ${this.selectedServiceType}\nSub-Categoria do Serviço: ${this.selectedServiceSubType}\n`;
          }
        }
      }

      if (this.colaborator === "Sim") {
        colaborator = `Sim\nFunção:${this.colaboratorRole}`;
      } else {
        colaborator = "Não";
      }

      if (this.notes !== null) {
        aditionalComments = `Comentários adicionais: ${this.notes}\n`;
      } else {
        aditionalComments = "Comentários adicionais: sem comentários\n";
      }

      if (this.localSelected !== "") {
        local = `Local Selecionado: ${this.localSelected}\nLatitude: ${this.latitudeSelected}\nLongitude: ${this.longitudeSelected}`;
      } else {
        local = "Local Selecionado: Não selecionou\n";
      }

      const body = `Nome: ${this.name}\nNúmero de telemóvel: ${this.phone}\nEmail: ${this.email}\nMorada Completa: ${this.address}\nÉ colaborador/representante/proprietário do local: ${colaborator}\n${place}${placeType}${aditionalComments}${local}`;

      const mailtoLink = `mailto:support@travizco.com?subject=${encodeURIComponent(
        "Pedido para Registo de Local - Travizco Business"
      )}&body=${encodeURIComponent(body)}`;

      window.open(mailtoLink);
    },
    onLocalSelected(value) {
      this.localSelected = value;
    },
    onLatitudeSelected(value) {
      this.latitudeSelected = value;
    },
    onLongitudeSelected(value) {
      this.longitudeSelected = value;
    },
  },
  computed: {
    /* get service subtypes to fill the autocomplete */
    getServiceSubtypes: function () {
      while (this.selectedServiceType != null) {
        const serviceObject = this.serviceTypes.find(
          (item) => item.name === this.selectedServiceType
        );
        return serviceObject.subType;
      }
    },
  },
  components: {
    FormMap,
  },
};
</script>

<style scoped></style>