<template>
  <v-card class="card-style text-white text-center align-center">
    <v-row v-if="$vuetify.display.mdAndUp" class="pt-4">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <p class="text-h6">
          Queres ganhar um smartphone Xiaomi 13 5G? Participa no nosso giveaway
          <a style="color: inherit;" target="_blank" href="https://www.instagram.com/p/Crlz1DEIbyC/">
            <span>aqui</span>
          </a>
          <span>!</span>
        </p>
      </v-col>
      <v-col cols="1">
        <!-- <v-icon @click="toggleCard" icon="mdi-close" size="x-large"></v-icon> -->
      </v-col>
    </v-row>
    <v-row v-else class="pt-3 pb-2">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <p class="text-caption">
          Queres ganhar um smartphone Xiaomi 13 5G? Participa no nosso giveaway
          <a style="color: inherit;" target="_blank" href="https://www.instagram.com/p/Crlz1DEIbyC/">
            <span>aqui</span>
          </a>
          <span>!</span>
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script >
export default {
  name: "giveaway",
  data() {
    return {
      //showGiveaway: true,
    };
  },
  methods: {
    /* toggleCard() {
      this.showGiveaway = !this.showGiveaway;
    }, */
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.15rem rgba(18, 243, 37, 0.5);
} */

.card-style {
  border-radius: 0;
  width: 100%;
  height: 100%;
  background-color: #0b2437;
}

.link {
  text-decoration: none;
}

span {
  text-decoration: underline;
}
</style>