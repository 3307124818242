<template>
  <!-- app bar -->
  <!--  <v-app-bar v-if="$vuetify.display.smAndDown">
    <GiveawayVue></GiveawayVue>
  </v-app-bar>-->

  <AppBar :show-tabs="showTabs" :isTouchless="touchlessBoolean" :tabs="tabs" :tab-index="tab_selected" :shrink-bar="shrinkBar"></AppBar>
  <div>
    <v-card data-aos="fade-down" data-aos-duration="1000" flat class="home-section">
      <!-- parallax XL screen -->
      <v-parallax v-if="$vuetify.display.xlAndUp" height="75vh" :src="headerParallax">
        <v-container>
          <v-row align="center" style="height: 75vh">
            <v-spacer></v-spacer>
            <!-- logo and buttons -->
            <v-col cols="6" xl="4" lg="5" md="5" sm="6" class="header-column justify-space-around">
              <div class="d-block">
                <p
                  :class="{
                    'welcome-title-xl': $vuetify.display.lgAndUp,
                  }"
                >
                  {{ $t("home.travizco") }}
                </p>
                <p
                  :class="{
                    'welcome-subtitle-xl ml-2 ': $vuetify.display.lgAndUp,
                  }"
                >
                  {{ $t("home.subtitle1") }}
                </p>
                <div class="d-flex mt-8 ml-2">
                  <a class="imageExpand" href="https://apps.apple.com/app/travizco/id1613139068" @click="btnClickAppStore">
                    <v-img
                      :width="$vuetify.display.xl ? '150px' : $vuetify.display.lg ? '135px' : $vuetify.display.sm ? '110px' : '120px'"
                      class="mr-2"
                      :src="appStoreButton"
                    ></v-img>
                  </a>
                  <a class="imageExpand" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco" @click="btnClickPlayStore">
                    <v-img
                      :width="$vuetify.display.xl ? '150px' : $vuetify.display.lg ? '135px' : $vuetify.display.sm ? '110px' : '120px'"
                      :src="googlePlayButton"
                    ></v-img>
                  </a>
                </div>
              </div>

              <div class="d-block font-weight-medium text-white text-h6">
                <p>{{ $t("home.advertBusiness") }}</p>
                <p>{{ $t("home.partner") }}</p>
                <v-btn
                  color="white"
                  @click="navigateToBusiness"
                  class="mt-2 font-weight-bold"
                  style="color: #083c5c"
                  :size="$vuetify.display.xlAndUp ? 'large' : $vuetify.display.lg ? 'default' : $vuetify.display.md ? 'default' : 'small'"
                  >{{ $t("home.registerNow") }}</v-btn
                >
              </div>
            </v-col>
            <!-- image mockup -->
            <v-col cols="6" xl="4" lg="5" md="5" sm="5" style="height: 50vh">
              <v-img :src="headerPhoneMockup" aspect-ratio="4:3" />
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-parallax>

      <!-- parallax sm tp lg screen -->
      <v-parallax v-if="$vuetify.display.sm || $vuetify.display.md || $vuetify.display.lg" height="80vh" :src="headerParallax">
        <v-container>
          <v-row align="center" style="height: 80vh">
            <v-spacer></v-spacer>
            <!-- logo and buttons -->
            <v-col cols="6" xl="4" lg="5" md="5" sm="6" class="header-column">
              <div class="d-block">
                <p
                  :class="{
                    'welcome-title-lg': $vuetify.display.lgAndUp,
                    'welcome-title-md': $vuetify.display.md,
                    'welcome-title-sm': $vuetify.display.sm,
                  }"
                >
                  {{ $t("home.travizco") }}
                </p>
                <p
                  :class="{
                    'welcome-subtitle-lg ml-2 ': $vuetify.display.lgAndUp,
                    'welcome-subtitle-md ml-2': $vuetify.display.md,
                    'welcome-subtitle-sm ml-2': $vuetify.display.sm,
                  }"
                >
                  {{ $t("home.subtitle1") }}
                </p>
                <div class="d-flex mt-8 ml-2">
                  <a class="imageExpand" href="https://apps.apple.com/app/travizco/id1613139068" @click="btnClickAppStore">
                    <v-img
                      :width="$vuetify.display.xl ? '150px' : $vuetify.display.lg ? '135px' : $vuetify.display.sm ? '110px' : '120px'"
                      class="mr-2"
                      :src="appStoreButton"
                    ></v-img>
                  </a>
                  <a class="imageExpand" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco" @click="btnClickPlayStore">
                    <v-img
                      :width="$vuetify.display.xl ? '150px' : $vuetify.display.lg ? '135px' : $vuetify.display.sm ? '110px' : '120px'"
                      :src="googlePlayButton"
                    ></v-img>
                  </a>
                </div>
              </div>

              <v-container class="mb-8">
                <div
                  class="d-block font-weight-medium text-white"
                  :class="{
                    '': $vuetify.display.lgAndUp,
                    'text-body-1': $vuetify.display.md,
                    'text-body-2': $vuetify.display.sm,
                  }"
                >
                  <p>{{ $t("home.advertBusiness") }}</p>
                  <p>{{ $t("home.partner") }}</p>
                  <v-btn
                    color="white"
                    @click="navigateToBusiness"
                    class="mt-2 font-weight-bold"
                    style="color: #083c5c"
                    :size="$vuetify.display.xlAndUp ? 'large' : $vuetify.display.lg ? 'default' : $vuetify.display.md ? 'default' : 'small'"
                    >{{ $t("home.registerNow") }}</v-btn
                  >
                </div>
              </v-container>
            </v-col>
            <!-- image mockup -->
            <v-col cols="6" xl="4" lg="5" md="5" sm="5" style="height: 60vh">
              <v-img :src="headerPhoneMockup" aspect-ratio="4:3" />
            </v-col>
            <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer>
          </v-row>
        </v-container>
      </v-parallax>

      <!-- parallax xs only -->
      <v-img v-if="$vuetify.display.xs" height="75vh" cover :src="headerParallax">
        <v-container data-aos="fade-up" data-aos-duration="1500">
          <v-row align="center" class="d-block text-center">
            <v-col>
              <v-col class="d-block" align-self="center">
                <div class="welcome-title-xs">{{ $t("home.travizco") }}</div>
                <div class="welcome-subtitle-xs">{{ $t("home.subtitle1") }}</div>
                <v-row class="justify-center mt-5">
                  <a class="imageExpand" href="https://apps.apple.com/app/travizco/id1613139068">
                    <v-img width="90px" class="mr-2" :src="appStoreButton"></v-img>
                  </a>
                  <a class="imageExpand" href="https://play.google.com/store/apps/details?id=pt.impactzero.travizco">
                    <v-img width="90px" class :src="googlePlayButton"></v-img>
                  </a>
                </v-row>

                <div class="mt-5 d-block font-weight-bold text-caption text-white">
                  <p>{{ $t("home.advertBusiness") }}</p>
                  <p>{{ $t("home.partner") }}</p>
                  <v-btn
                    color="white"
                    @click="navigateToBusiness"
                    class="mt-2 font-weight-bold"
                    style="color: #083c5c"
                    :size="$vuetify.display.xlAndUp ? 'large' : $vuetify.display.lg ? 'default' : $vuetify.display.md ? 'default' : 'small'"
                  >
                    {{ $t("home.registerNow") }}
                  </v-btn>
                </div>
              </v-col>
            </v-col>

            <v-col style="height: 35vh">
              <v-img :src="headerPhoneMockup" aspect-ratio="4:3" />
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>

    <!-- divider with logo full screen -->
    <div>
      <v-row v-if="$vuetify.display.smAndUp" class="justify-center align-center ma-10">
        <v-col class="justify-center align-center">
          <v-divider></v-divider>
        </v-col>
        <v-col sm="auto" class="justify-center align-center">
          <v-img
            :id="'start_card'"
            class="rounded-lg"
            :src="qrLogo"
            contain
            :width="
              $vuetify.display.xs ? '70px' : $vuetify.display.sm ? '70px' : $vuetify.display.md ? '80px' : $vuetify.display.lg ? '80px' : '80px'
            "
          ></v-img>
        </v-col>
        <v-col class="justify-center align-center">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-else class="justify-center align-center mt-4">
        <v-col cols="4" class="justify-center align-center">
          <v-divider style="color: white"></v-divider>
        </v-col>
        <v-col cols="3" class="justify-center align-center">
          <v-img max-width="50px" contain :id="'ref_1'" class="mx-auto" style="border-radius: 10px" :src="require('../assets/qrLogo.jpg')"></v-img>
        </v-col>
        <v-col cols="4" class="justify-center align-center">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </div>

    <!--section 1 pois-->
    <v-img contain :src="worldMap" :id="'ref_1'">
      <v-card flat color="transparent" class="poi-section-card">
        <span :id="'sec_1'" v-intersect="handleIntersect"></span>
        <v-row class="align-center">
          <!-- text col -->
          <v-col data-aos="fade-right" data-aos-duration="1500" cols="7">
            <h1
              class="font-weight-black text-left"
              :class="{
                'text-h2': $vuetify.display.lgAndUp,
                'text-h3': $vuetify.display.md,
                'text-h3': $vuetify.display.sm,
                'text-h6': $vuetify.display.xs,
              }"
            >
              <div class="d-block">
                <div class="discover-text">{{ $t("home.discover") }}</div>
                <div class="poi-section-title">{{ $t("home.poiTitle") }}</div>
              </div>
            </h1>
            <br />
            <h4 class="font-weight-bold text-left poi-section-subtitle text-body-1" :class="{ 'text-left caption': $vuetify.display.xs }">
              {{ $t("home.poiSubtitle") }}
            </h4>
          </v-col>

          <!-- image col -->
          <v-col
            data-aos="fade-left"
            data-aos-duration="1500"
            cols="5"
            class="align-center"
            :class="{
              'mr-16 ml-16 pa-16': $vuetify.display.mdandUp,
            }"
          >
            <v-img
              :src="poisMockup"
              contain
              :height="
                $vuetify.display.xs
                  ? '200px'
                  : $vuetify.display.sm
                  ? '230px'
                  : $vuetify.display.md
                  ? '300px'
                  : $vuetify.display.lg
                  ? '450px'
                  : '550px'
              "
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-img>

    <!-- md and up number animation -->
    <v-card tile flat class="mt-6 pt-12" v-if="$vuetify.display.mdAndUp">
      <v-row class="justify-space-between">
        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-church" class="mb-5 disable-hover text-white bg-primary remove-hover" size="large" />
          <NumberCounter class="text-h3 font-weight-medium d-flex number-generator-color justify-center" :target-count="3140" />
          <v-col class="text-h5 font-weight-bold">{{ $t("home.poiTitle") }}</v-col>
        </v-col>

        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-silverware-fork-knife" class="mb-5 disable-hover text-white bg-primary remove-hover" size="large" />
          <NumberCounter class="text-h3 font-weight-medium d-flex number-generator-color justify-center" :target-count="6792" />
          <v-col class="text-h5 font-weight-bold">{{ $t("services") }}</v-col>
        </v-col>

        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-map-marker" class="mb-5 disable-hover text-white bg-primary remove-hover" size="large" />
          <NumberCounter class="text-h3 font-weight-medium d-flex number-generator-color justify-center" :target-count="308" />
          <v-col class="text-h5 font-weight-bold">{{ $t("destinations.title") }}</v-col>
        </v-col>
      </v-row>
    </v-card>

    <!-- sm and down number animation -->
    <v-card tile flat v-if="$vuetify.display.smAndDown">
      <v-row class="justify-space-around">
        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-church" class="mb-2 disable-hover text-white bg-primary remove-hover" />
          <NumberCounter class="text-h5 font-weight-medium d-flex number-generator-color justify-center" :target-count="3140" />
          <v-col class="subtitle font-weight-bold">{{ $t("home.poiTitle") }}</v-col>
        </v-col>

        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-silverware-fork-knife" class="mb-2 disable-hovertext-white bg-primary remove-hover" />
          <NumberCounter class="text-h5 font-weight-medium d-flex number-generator-color justify-center" :target-count="6792" />
          <v-col class="subtitle font-weight-bold">{{ $t("services") }}</v-col>
        </v-col>

        <v-col cols="4" class="text-center">
          <v-btn icon="mdi-map-marker" class="mb-2 disable-hover text-white bg-primary remove-hover" />
          <NumberCounter class="text-h5 font-weight-medium d-flex number-generator-color justify-center" :target-count="308" />
          <v-col class="subtitle font-weight-bold">{{ $t("destinations.title") }}</v-col>
        </v-col>
      </v-row>
    </v-card>

    <!-- jingle section -->
    <AudioJingle></AudioJingle>

    <!-- access blog -->
    <!-- <v-card class="text-center" variant="elevated" color="#90A4AE" height="max-content" style="margin-top: 10vh;">
        <v-row style="height:35vh; ">
          <v-col cols="2"></v-col>

          <v-col cols="4" align-self="center">
            <h2>Check out our blog </h2>
            <h4>Learn about the latest news regarding travizco destinations</h4>
          </v-col>

          <v-col cols="4" align-self="center">
            <v-btn variant="elevated" color="primary" href="https://blog.travizco.com/">Visit Blog</v-btn>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
    </v-card>-->

    <!-- destinations card -->
    <span :id="'sec_2'" v-intersect="handleIntersect"></span>
    <v-container>
      <v-row class="text-center justify-center" :id="'ref_2'">
        <!-- title -->
        <v-container v-if="$vuetify.display.mdAndUp" class="text-center destinations-container mt-12">
          <p class="font-weight-medium destination-title">
            {{ $t("destinations.visit") }}
            <span class="destination-title-visit">{{ $t("destinations.title") }}</span>
            {{ $t("destinations.atractVisitants") }}
          </p>
        </v-container>

        <v-container v-else class="text-center mt-5">
          <p class="font-weight-medium destination-title-mobile">
            {{ $t("destinations.visit") }}
            <span class="destination-title-visit-mobile">{{ $t("destinations.title") }}</span>
            {{ $t("destinations.atractVisitants") }}
          </p>
        </v-container>

        <!-- description -->
        <!-- <v-container :class="{
          'text-justify ml-12 mr-12': $vuetify.display.lgAndUp, 'text-justify': $vuetify.display.xs || $vuetify.display.sm,
          'text-justify': $vuetify.display.md
        }">
          <div v-if="$vuetify.display.mdAndUp" class="pl-4 pr-4 ml-12 mr-12 mt-5 text-body-1 font-weight-medium" style="color: darkslategray">
            {{ $t("destinations.description") }}
          </div>
          <p v-else class="text-caption font-weight-medium text-justify" style="color: darkslategray">
            {{ $t("destinations.description") }}
          </p>
        </v-container>-->

        <v-container class="ma-5 mt-8">
          <v-row class="justify-center" v-if="$vuetify.display.mdAndUp">
            <v-col class="d-flex justify-center pa-8" v-for="destination in destinations" :key="destination.id" cols="12" xl="4" lg="4" md="4" sm="5">
              <v-card
                v-on:click="goToDetails(destination.title)"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-easing="linear"
                elevation="5"
                class="card-expand rounded-xl"
                width="350"
              >
                <v-img
                  class="title-hover text-center align-center justify-center card-img"
                  aspect-ratio="1"
                  style="text-shadow: 1.2px 1.2px black"
                  :src="destination.image"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                  cover
                >
                  <h1 class="font-weight-medium" v-text="destination.title"></h1>
                  <v-spacer style="height: 72%"></v-spacer>

                  <!--   <div class="d-flex text-white mr-2 justify-end">
                    <v-btn size="small" rounded="pill" color="primary" class="mr-1 ml-1 disable-hover">
                      <p class="text-body-2 mr-1" v-text="destination.pois"></p>
                      <p class="text-body-2">Pontos de Interesse</p>
                    </v-btn>
                    <v-btn size="small" rounded="pill" color="primary" class="disable-hover">
                      <p v-text="destination.pois" class="text-body-2 mr-1"></p>
                      <p class="text-body-2 ">Serviços</p>
                    </v-btn>
                  </div>-->
                </v-img>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="justify-center" v-if="$vuetify.display.xs || $vuetify.display.sm">
            <v-col
              class="d-flex justify-center pa-8"
              v-for="(destination, index) in destinations"
              :key="destination.id"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="5"
            >
              <v-card
                v-on:click="goToDetails(destination.title)"
                v-if="index < 4"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-easing="linear"
                elevation="5"
                class="card-expand rounded-xl"
                width="350"
              >
                <v-img
                  class="title-hover text-center align-center justify-center card-img"
                  aspect-ratio="1"
                  style="text-shadow: 1.2px 1.2px black"
                  :src="destination.image"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                  cover
                >
                  <h1 class="font-weight-medium" v-text="destination.title"></h1>
                </v-img>
              </v-card>
            </v-col>
            <v-btn size="large" to="/destinations" color="primary">{{ $t("destinations.button") }}</v-btn>
          </v-row>
        </v-container>
      </v-row>
    </v-container>

    <!-- more places button -->
    <v-row v-if="$vuetify.display.mdAndUp" class="justify-center ma-12" style="padding-bottom: 5vh">
      <v-btn size="large" to="/destinations" color="primary">{{ $t("destinations.button") }}</v-btn>
    </v-row>

    <!-- video -->
    <v-row class="justify-center mb-12 mt-12" data-aos="zoom-in-up" data-aos-duration="1200">
      <v-card tile flat>
        <iframe
          class="rounded"
          :width="
            $vuetify.display.xs ? '300px' : $vuetify.display.sm ? '450px' : $vuetify.display.md ? '550px' : $vuetify.display.lg ? '550px' : '650px'
          "
          :height="
            $vuetify.display.xs ? '190px' : $vuetify.display.sm ? '250px' : $vuetify.display.md ? '290px' : $vuetify.display.lg ? '290px' : '340px'
          "
          :src="youtubeURl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-card>
    </v-row>

    <!--section 2 routes-->
    <v-card color="#002f5b" class="home-section" tile>
      <v-img contain :src="routeSnippet" max-height="65vh" style="z-index: 1">
        <div
          :id="'ref_3'"
          data-aos="zoom-out-down"
          data-aos-duration="1000"
          :class="{
            'd-flex ml-16 mr-16 pl-16 pr-16 align-center': $vuetify.display.mdAndUp,
            'd-flex align-center': $vuetify.display.sm,
            'd-flex flex-column-reverse align-center': $vuetify.display.xs,
          }"
        >
          <!-- image -->
          <v-col data-aos="fade-left" data-aos-duration="1500" cols="8" xl="5" lg="5" md="5" sm="5" align-self="center">
            <v-img
              :src="routesMockup"
              contain
              :width="
                $vuetify.display.xs
                  ? '180px'
                  : $vuetify.display.sm
                  ? '350px'
                  : $vuetify.display.md
                  ? '350px'
                  : $vuetify.display.lg
                  ? '350px'
                  : '450px'
              "
            ></v-img>
          </v-col>

          <v-col xl="1" lg="1" md="1" sm="1"></v-col>
          <span :id="'sec_3'" v-intersect="handleIntersect"></span>
          <!-- text -->
          <v-col data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" cols="12" xl="6" lg="6" md="6" sm="6" align-self="center">
            <h1
              class="font-weight-black text-left white--text"
              :class="{
                'text-h2 text-left ': $vuetify.display.lgAndUp,
                'text-h3 text-left ': $vuetify.display.md,
                'text-h4 text-left': $vuetify.display.sm,
                'text-h5 text-center': $vuetify.display.xs,
              }"
            >
              <div class="adventure-text">
                {{ $t("home.adventure") }}
                <span class="routes-section-title">{{ $t("home.routesTitle") }}</span>
              </div>
            </h1>

            <br />
            <h4
              class="font-weight-normal text-left"
              style="color: #bbdefb"
              :class="{
                'caption text-center': $vuetify.display.xs,
              }"
            >
              {{ $t("home.routesSubtitle") }}
            </h4>
          </v-col>
        </div>
      </v-img>
    </v-card>

    <!--section 3 qr scanner-->
    <v-card color="#eaf1f4" class="last-section1 bg1" :id="'ref_4'" flat tile>
      <span :id="'sec_4'" v-intersect="handleIntersect"></span>
      <v-row style="height: 15vh"></v-row>

      <v-row
        data-aos="slide-left"
        data-aos-duration="1500"
        :class="{
          'd-flex ml-16 mr-16 pl-16 pr-16': $vuetify.display.mdAndUp,
          'd-flex ml-10 mr-10': $vuetify.display.sm,
          'd-block': $vuetify.display.xs,
        }"
      >
        <!-- text -->
        <v-col cols="12" xl="6" lg="6" sm="6" md="6" align-self="center">
          <h1
            class="font-weight-black text-left"
            :class="{
              'text-h2 text-left ': $vuetify.display.lgAndUp,
              'text-h3 text-left ': $vuetify.display.md,
              'text-h4 text-left': $vuetify.display.sm,
              'text-h5 text-center ml-12 mr-12': $vuetify.display.xs,
            }"
          >
            <div class="moreinfo-text">
              {{ $t("home.moreinfo") }}
              <span class="qr-section-title">{{ $t("home.qrscannerTitle") }}</span>
            </div>
          </h1>
          <br />

          <h4
            class="font-weight-bold text-left qr-section-subtitle"
            :class="{
              'caption text-center ml-15 mr-15': $vuetify.display.xs,
            }"
          >
            {{ $t("home.qrscannerSubtitle") }}
          </h4>
        </v-col>

        <v-col xl="1" lg="1" md="1" sm="1"></v-col>
        <!-- pic -->
        <v-col cols="12" xl="5" lg="5" sm="5" md="5">
          <v-img
            :src="qrScanMockup"
            contain
            :height="
              $vuetify.display.xs ? '200px' : $vuetify.display.sm ? '400px' : $vuetify.display.md ? '400px' : $vuetify.display.lg ? '400px' : '400px'
            "
          ></v-img>
        </v-col>
      </v-row>

      <!--section 4 explorer-->
      <v-row class="transparent-row"></v-row>
      <section flat class="last-section2 bg2" :id="'ref_5'">
        <div
          data-aos="slide-right"
          data-aos-duration="1500"
          :class="{
            'd-flex ml-16 mr-16 pl-16 pr-16': $vuetify.display.mdAndUp,
            'd-flex ': $vuetify.display.sm,
            'd-flex flex-column-reverse ': $vuetify.display.xs,
          }"
        >
          <v-col cols="8" xl="5" lg="5" md="5" sm="5" align-self="center">
            <v-img
              :src="bikeMockup"
              contain
              :height="
                $vuetify.display.xs
                  ? '200px'
                  : $vuetify.display.sm
                  ? '350px'
                  : $vuetify.display.md
                  ? '400px'
                  : $vuetify.display.lg
                  ? '400px'
                  : '450px'
              "
            ></v-img>
          </v-col>

          <v-col xl="1" lg="1" md="1" sm="1"></v-col>

          <v-col cols="12" xl="6" lg="6" md="6" sm="6" align-self="center">
            <h1
              class="font-weight-black text-left"
              :class="{
                'text-h2 text-left ': $vuetify.display.lgAndUp,
                'text-h3 text-left ': $vuetify.display.md,
                'text-h4 text-left': $vuetify.display.sm,
                'text-h5 text-center': $vuetify.display.xs,
              }"
            >
              <div class="dare-text">
                {{ $t("home.dare") }}
                <span class="explorer-section-title">{{ $t("home.explorerTitle") }}</span>
              </div>
            </h1>
            <br />
            <p
              class="font-weight-bold text-left explorer-section-subtitle"
              :class="{
                '': $vuetify.display.lgAndUp,
                'caption text-center': $vuetify.display.xs,
              }"
            >
              {{ $t("home.explorerSubtitle") }}
            </p>
          </v-col>
        </div>
        <span :id="'sec_5'" v-intersect="handleIntersect"></span>
      </section>
    </v-card>
    <Footer :show-business-logo="showBusinessLogo"></Footer>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import AppBar from "../components/AppBar.vue";
import NumberCounter from "../components/NumberCounter.vue";
import AudioJingle from "../components/AudioJingle.vue";
import Footer from "../components/Footer.vue";
import GiveawayVue from "../components/GiveawayComponent.vue";

export default {
  name: "Home",
  data() {
    return {
      /* Footer */
      showBusinessLogo: true,
      /* youtube video */
      youtubeURl: "",
      youtubeNoCookieURl: "https://www.youtube-nocookie.com/embed/NDsrodaECKg?controls=0",
      youtubeWithCookieURl: "https://www.youtube.com/embed/NDsrodaECKg?controls=0",
      /* App bar */
      isIntersecting: false,
      touchlessBoolean: false,
      appBar: true,
      showTabs: true,
      shrinkBar: false,
      tab_selected: 0,
      tabs: [
        { index: 0, name: this.$t("tabname.poi"), scrollToId: "ref_1" },
        {
          index: 1,
          name: this.$t("tabname.destinations"),
          scrollToId: "ref_2",
        },
        { index: 2, name: this.$t("tabname.routes"), scrollToId: "ref_3" },
        { index: 3, name: this.$t("tabname.qrscanner"), scrollToId: "ref_4" },
        { index: 4, name: this.$t("tabname.explorer"), scrollToId: "ref_5" },
        { index: 5, name: this.$t("tabname.partner"), scrollToId: "ref_6" },
      ],
      /* destinations */
      destinations: [
        {
          id: 1,
          title: "Porto",
          image: require("../assets/destinations/porto.webp"),
          pois: "30",
          services: "50",
        },
        {
          id: 2,
          title: "Madeira",
          image: require("../assets/destinations/madeira.webp"),
          pois: "30",
          services: "50",
        },
        {
          id: 3,
          title: "Lisboa",
          image: require("../assets/destinations/lisboa.webp"),
          pois: "30",
          services: "50",
        },
        {
          id: 4,
          title: "Açores",
          image: require("../assets/destinations/acores.webp"),
          pois: "30",
          services: "50",
        },
        {
          id: 5,
          title: "Aveiro",
          image: require("../assets/destinations/aveiro.webp"),
          pois: "30",
          services: "50",
        },
        {
          id: 6,
          title: "Faro",
          image: require("../assets/destinations/faro.webp"),
          pois: "30",
          services: "50",
        },
      ],
      /* images */
      headerParallax: require("../assets/home/header_image.svg"),
      appStoreButton: require("../assets/app_store_icon.png"),
      googlePlayButton: require("../assets/google_play_icon.png"),
      headerPhoneMockup: require("../assets/home/header_mockup.webp"),
      worldMap: require("../assets/home/world_map.png"),
      qrLogo: require("../assets/qrLogo.jpg"),
      poisMockup: require("../assets/home/pois_mockup.webp"),
      routeSnippet: require("../assets/home/route_snippet.png"),
      routesMockup: require("../assets/home/routes_mockup.webp"),
      qrScanMockup: require("../assets/home/qr_mockup.webp"),
      bikeMockup: require("../assets/home/bike.png"),
    };
  },
  methods: {
    goToDetails(destinationName) {
      this.$router.push({
        name: "destinationDetails",
        params: { name: destinationName },
      });
    },
    /* intersect for tabs */
    handleIntersect(isIntersecting, entries) {
      let intersecting_element = entries[0];

      if (intersecting_element?.isIntersecting) {
        let id = intersecting_element.target.id;
        let index = Number(id.split("_")[1]);
        this.tab_selected = index - 1;
      }
    },
    navigateToBusiness() {
      this.$router.push("business");
    },
    btnClickPlayStore() {
      this.$gtag.event("Play Store Button", {
        event_category: "Link",
        event_label: "click link",
        value: 1,
      });
    },
    btnClickAppStore() {
      this.$gtag.event("App Store Button", {
        event_category: "Link",
        event_label: "click link",
        value: 1,
      });
    },
    youtubeVideoInteractions() {
      this.$gtag.event("Video youtube", {
        event_label: "Link",
        event_category: "click link",
        value: 1,
      });
    },
  },
  created() {
    AOS.init();

    const acceptAllCookiesKey = "ALL-COOKIES-ACCEPTED";
    const targetingCookiesKey = "COOKIE-TARGETING";
    const allCookiesAccepted = localStorage.getItem(acceptAllCookiesKey) === "true";
    const targetingCookiesAccepted = localStorage.getItem(targetingCookiesKey) === "true";

    if (allCookiesAccepted || targetingCookiesAccepted) {
      this.youtubeURl = this.youtubeWithCookieURl;
    } else {
      this.youtubeURl = this.youtubeNoCookieURl;
    }
    //this.showCard = true;
  },
  components: {
    AppBar,
    NumberCounter,
    AudioJingle,
    Footer,
    GiveawayVue,
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.15rem rgba(18, 243, 37, 0.5);
} */

.header-column {
  height: 62vh;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disable-hover {
  pointer-events: none;
}

.title-hover {
  color: white;
  transition: 0.3s;
}

.title-hover:hover {
  transition: 0.3s;
}

.card-expand {
  transition: transform 0.5s;
}

.card-expand:hover {
  transform: scale(1.08);
  transition: transform 0.5s;
}

.v-divider {
  line-height: 2 !important;
}

.imageExpand:hover {
  transform: scale(1.04);
  transition: all 0.2s ease;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-family: "League Gothic";
}

.underlineText {
  color: white;
}

.underlineText:hover {
  text-decoration: underline;
}

.home-section {
  position: relative;
}

.home-section:not(:first-child) {
  padding: 20vh 32px;
}

.home-section > span {
  position: absolute;
  left: 50%;
  top: 50%;
}

.last-section1 .last-section2 {
  position: relative;
}

.last-section1:not(:first-child) .last-section2:not(:first-child) {
  padding: 20vh 32px;
}

.last-section1 > span {
  position: absolute;
  left: 50%;
  top: 20%;
}

.last-section2 > span {
  position: absolute;
  left: 50%;
  top: 80%;
}

.tab-text {
  color: white !important;
  font-weight: 700 !important;
}

.v-tab--selected {
  color: rgb(var(--v-theme-primary-darken-2)) !important;
}

.v-application--is-ltr .v-responsive__sizer .v-responsive__content {
  margin-left: 0;
}

.bg1 {
  background: url("../assets/home/top_route.png") no-repeat center center;
  background-size: 740px;
}

.bg2 {
  background: url("../assets/home/bottom_route.png") no-repeat center bottom;
  background-size: 740px;
}

.tab-icon::before {
  display: none;
}

.navigate-business:hover {
  transform: scaleY(1.02);
  transition: all 0.2s ease;
  cursor: pointer;
  color: #002f5b;
  font-weight: 900;
  text-decoration: underline;
}

.welcome-title-xl {
  font-family: "Droidiga";
  color: white;
  text-shadow: 1.2px 1.2px black;
  font-size: 90px;
}

.welcome-title-lg {
  font-family: "Droidiga";
  color: white;
  text-shadow: 1.2px 1.2px black;
  font-size: 85px;
}

.welcome-title-md {
  font-family: "Droidiga";
  color: white;
  text-shadow: 1.2px 1.2px black;
  font-size: 65px;
}

.welcome-title-sm {
  font-family: "Droidiga";
  color: white;
  font-size: 55px;
  text-shadow: 1px 1px black;
}

.welcome-title-xs {
  font-family: "Droidiga";
  color: white;
  font-size: 50px;
  text-shadow: 1px 1px black;
}

.welcome-subtitle-xl {
  color: rgb(232, 232, 232);
  font-size: 25px;
  text-shadow: 1px 0px black;
}

.welcome-subtitle-lg {
  color: rgb(232, 232, 232);
  font-size: 18px;
  text-shadow: 1px 0px black;
}

.welcome-subtitle-md {
  color: rgb(232, 232, 232);
  font-size: 15px;
  text-shadow: 1px 0px black;
  width: 85%;
}

.welcome-subtitle-sm {
  color: rgb(232, 232, 232);
  text-shadow: 1px 0px black;
  font-size: 14px;
  width: 65%;
}

.welcome-subtitle-xs {
  color: darkslategray;
  font-size: 13px;
  text-shadow: 1px 0px black;
}

.poi-section-card {
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.poi-section-title {
  color: #01579b;
  text-shadow: 1px 1px #002f5b;
}

.discover-text {
  color: #002f5b;
  text-shadow: 1px 1px black;
}

.poi-section-subtitle {
  color: darkslategray;
  width: 90%;
  text-shadow: 0.2px 0.2px black;
}

.adventure-text {
  color: #4dd0e1;
  text-shadow: 0.5px 0.5px white;
}

.routes-section-title {
  color: white;
  text-shadow: 1.5px 1.5px black;
}

.moreinfo-text {
  color: #1e6892;
  text-shadow: 1px 1px black;
}

.qr-section-title {
  color: #1a374d;
  text-shadow: 1px 1px black;
}

.qr-section-subtitle {
  color: darkslategray;
  text-shadow: 0.2px 0.2px black;
}

.dare-text {
  color: #334257;
  text-shadow: 1px 1px white;
}

.explorer-section-title {
  color: #61a4bc;
  text-shadow: 1.5px 1.5px grey;
}

.explorer-section-subtitle {
  color: darkslategray;
  text-shadow: 0.2px 0.2px black;
}

.destination-title {
  font-size: 31px;
  color: rgb(15, 76, 117);
  font-weight: 500;
  text-shadow: 0.5px 0.5px rgb(15, 76, 117);
}

.destination-title-visit {
  font-size: 32px;
  text-shadow: 0.5px 0.5px black;
  color: #4a93e7;
  font-weight: 700;
}

.destination-title-mobile {
  font-size: 20px;
  color: rgb(15, 76, 117);
  font-weight: 500;
  text-shadow: 0.5px 0.5px rgb(15, 76, 117);
}

.destination-title-visit-mobile {
  font-size: 22px;
  text-shadow: 0.5px 0.5px black;
  color: #4a93e7;
  font-weight: 700;
}

.number-generator-color {
  color: rgb(15, 76, 117);
}

.transparent-row {
  background-color: transparent;
  height: 10vh;
}

.v-application .caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
