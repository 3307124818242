export default {
  "common": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenido a ", _interpolate(_named("municipal"))])},
    "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos & Condiciones"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer más"])},
    "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer menos"])},
    "AppDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga la aplicacion para tener acceso completo"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando informacion, espere por favor..."])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síganos"])}
  },
  "notFound": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina no encontrada"])}
  },
  "tabname": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de Interés"])},
    "destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinos"])},
    "routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rutas"])},
    "qrscanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escáner qr"])},
    "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertirse en socio"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase"])}
  },
  "home": {
    "travizco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal en una app, una app para el mundo"])},
    "poiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de interés"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre"])},
    "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aventúrate con nuestras "])},
    "poiSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la página inicial enseñamos puntos de interés cercanos y servicios locales, destacando el municipio actual."])},
    "routesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rutas"])},
    "routesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora nuestras rutas turísticas mostradas en diferentes colores y descubre qué puntos de interés incluye."])},
    "routesTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creamos rutas diferenciadas entre distintos puntos de interés para descubrirlos de forma ordenada"])},
    "routesTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada ruta contiene un orden específico de puntos de interés que se pueden encontrar y consultar mientras se viaja con la app"])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtiene más información utilizando el "])},
    "qrscannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escáner QR"])},
    "qrscannerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza el escáner QR en un punto de interés para obtener más información sobre él."])},
    "dare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrévete a viajar de una nueva forma con "])},
    "explorerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador"])},
    "explorerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con el modo explorador puedes descubrir puntos de interés cercanos durante tu viaje en tiempo real, con o sin el teléfono encendido."])},
    "qrscannerTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al conferir un punto de interés a través del QR Scanner, puedes obtener toda la información sobre el mismo"])},
    "footerDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco administrado por Impactzero, Software lda., Tiene como objetivo descubrir el patrimonio cultural e histórico de un lugar en particular."])},
    "footerDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin embargo, antes de cualquier consulta con la APP, se deben leer los Términos de uso y la Política de tratamiento."])},
    "footerDescription3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La permanencia y uso de la APP por parte del visitante / usuario implica la aceptación de sus términos y condiciones, sin reserva alguna, por lo que si no está de acuerdo con ellos deberá dejar de navegar de inmediato."])},
    "footerDescription4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco se reserva el derecho, en cualquier momento, de cambiar la presentación y configuración de la APP, así como sus términos y condiciones de uso y la política de manejo de datos personales, por lo que el visitante / usuario debe siempre que acceda a la APP, consultar estos términos y condiciones por adelantado."])},
    "footerDescription5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se puede obtener información adicional de Impactzero, Software lda. enviando un correo electrónico a support", "@", "travizco.com."])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé nuestro socio con travizco business"])},
    "advertBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncia tu negocio"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conviértete en nuestro socio"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Regístrate!"])}
  },
  "giveaway_popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurso fotográfico"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre cómo puedes ser el ganador de nuestro concurso de fotografía"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta 14/11"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar"])},
    "contest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurso"])}
  },
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habla con nosotros"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "business": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sea nuestro socio."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate aquí"])},
    "findout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre cómo amplificar tu negocio con nosotros"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrecemos:"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtiene más Visibilidad y Credibilidad"])},
    "topic1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese Ahora"])},
    "topic1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate en TRAVIZCO BUSINESS y añade Puntos de Interés, Servicios y Rutas temáticas para dar más visibilidad a tu empresa."])},
    "topic2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impulsa tu Negocio"])},
    "topic2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potencia tu negocio de una forma creativa y original con publicidad personalizada para promocionar mejor tu empresa."])},
    "topic3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rutas Personalizadas"])},
    "topic3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrecemos rutas turísticas en 30 idiomas diferentes en modo automático incluso con el teléfono bloqueado. Contacta con nosotros para promocionar tus ideas."])}
  },
  "destinations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinos"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más Destinos"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite"])},
    "atractVisitants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["que atraen a millones de viajeros en todo el mundo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunos ejemplos incluyen ciudades históricas como Porto y Lisboa, paisajes naturales impresionantes como la costa Vicentine y las montañas de la isla de Madeira, y playas de arena volcánica pueden encontrarse en las Azores. Estos lugares ofrecen una amplia gama de actividades, desde tours culturales e históricos hasta aventuras al aire libre y momentos de relax en la playa. Además, muchos destinos turísticos tienen una rica gastronomía y una vida nocturna animada para disfrutar. Al planificar su próximo viaje, considere uno de los muchos increíbles destinos turísticos disponibles en Travizco."])}
  },
  "blog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita nuestro blog"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre las novedades y más sobre tus destinos favoritos"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitar"])}
  },
  "cookies": {
    "accept_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar todas"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más opciones"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies de segmentación se utilizan para identificar a los visitantes a través de diferentes sitios web, por ejemplo. socios de contenido, redes de banners. Estas cookies pueden ser utilizadas por las empresas para construir un perfil de los intereses de los visitantes o mostrar anuncios relevantes en otro sitio web."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este website utiliza cookies"])},
    "textToShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al utilizar nuestro sitio web, acepta todas las cookies de acuerdo con nuestra Política de cookies."])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de las cookies"])},
    "about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies son pequeños archivos de texto colocados en su ordenador por los sitios web que visita. Los sitios web utilizan cookies para ayudar a los usuarios a navegar de manera eficiente y realizar ciertas funciones. Las cookies necesarias para el correcto funcionamiento del sitio se pueden establecer sin el permiso del usuario Todas las demás cookies necesitan ser aprobadas antes de ser establecidas en el navegador.Usted puede cambiar su consentimiento para el uso de cookies en cualquier momento en nuestra página de Política de Privacidad."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cookies"])},
    "save_exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar e Salir"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfoque"])}
  },
  "jingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Escucha nuestro Jingle!"])},
  "business_form": {
    "register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese aquí"])},
    "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar lugar"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "poi_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Punto de Interés"])},
    "service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Servicio"])},
    "service_subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtipo de Servicio"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección completa"])},
    "colaborator_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se identifica como empleado, representante o colaborador del lugar que pretende añadir?"])},
    "colaborator_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es su papel?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "aditional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas/Comentarios adicionales"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene el formulario para contactar con nosotros por correo electrónico"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "mandatory_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de correo electrónico inválido"])},
    "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono inválido"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "searchPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar ubicación para añadir:"])}
  },
  "form_map": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización seleccionada"])}
  },
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "destination_details": {
    "aveiro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveiro es una ciudad y pertenece a la Región Centro. Importante centro urbano, ferroviario, portuario, con universidades y muy turístico. La Venecia portuguesa, como la apodan, con sus moliceiros, ¡no hay nada más bonito! Ciudad de la costa oeste fundada junto a una laguna, la Ría de Aveiro, con la sal y el comercio naval como activos. Los moliceiros, embarcaciones de atractivos colores, utilizadas tradicionalmente para la recolección de algas, llevan a los turistas por los canales en un agradable paseo. Sus playas son una atracción, con el premio Calidad Oro. Desde el patrimonio construido, con sus paneles de azulejo de rara belleza, pasando por los museos y los diferentes parques, ¡haga una pausa para disfrutar de los famosos ovos moles! Con 19 municipios, tiene una gastronomía muy rica, las ostras que vienen de la Ría... no se pierda ni un poco de toda esta riqueza, tanta maravilla. Travizco será tu compañero en esta travesía!"])},
    "acores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Azores son un archipiélago formado por 9 islas. En la isla de Pico, los viñedos están plantados en campos de lava que parecen laberintos. El punto más alto es Pico, con 2352 metros. El origen volcánico de las Azores tiene más expresión en la isla de Sao Miguel, en el valle de Furnas, donde se elabora el 'Cozido das Furnas'. Es la isla más grande y poblada, con el lago de Sete Cidades y el lago de Fogo. En todas ellas se puede apreciar la belleza y la preservación del paisaje natural. Graciosa es considerada Reserva Mundial de la Biosfera por la UNESCO, conocida como la Isla Blanca. Con TRAVIZCO, visite las plantaciones de té, ya que son las más antiguas y singulares de Europa, ¡formando un paisaje increíble!"])},
    "beja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beja, capital del Bajo Alentejo, capital del mayor distrito portugués en superficie, es una ciudad de unos 36 mil habitantes, fácil de recorrer a pie o en bicicleta y donde el tiempo es relativo... En la época romana era Pax Júlia y para recordar aquellos tiempos, se celebra en primavera durante 3 días la Beja Romana donde recordamos su grandeza y esplendor. Territorio dividido en 14 concelhos, de verdes llanuras que se tornan doradas durante el verano, es un territorio de belleza única que se extiende desde España hasta el mar. Sus vinos son de los mejores de Portugal, su gastronomía es sencilla, creativa, pero extremadamente rica en sabores. Un buen ejemplo de esta creatividad es el cerdo al estilo alentejano, una simbiosis perfecta entre tierra y mar. Explore Beja con TRAVIZCO y déjese llevar, maravíllese con la Alqueva y, si puede, disfrute del hermoso Cante Alentejano"])},
    "braga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad de los Arzobispos con más de 2000 años de existencia, Braga es la ciudad más antigua de Portugal, llamada 'Bracara Augusta' por los romanos. Es conocida por su Turismo Religioso y Cultural, con gran afluencia de gente en Semana Santa. Son conocidos sus Santuarios como el Bom Jesus do Monte y Sameiro. Hay 14 municipios en Braga, todos con una gran importancia para el crecimiento del territorio, también en términos turísticos. Bastante accidentado, con altas montañas, con municipios en la zona del Parque Nacional Peneda Gerês (1545 metros), el único en Portugal. La gastronomía y los vinos de la región, atraen cada vez más visitantes y donde la población joven encuentra aquí una buena calidad de vida"])},
    "braganca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bragança es una ciudad fronteriza, sede y distrito situado en el Nordeste de Portugal. Son 12 municipios, compuestos por dos regiones distintas, las de mayor altitud, al norte, que constituyen la Terra Fria Transmontana y al sur, la Terra Quente Transmontana, con un clima más suave. En general, el distrito es montañoso. La Serra da Nogueira, con 1320 metros, separa los valles de Tuela y Sabor. La más alta de todas es la Serra de Montesinho, con inmensos senderos, que se eleva hasta los 1400 metros y se extiende por territorio español. La historia cuenta que fue en Bragança donde se celebró el matrimonio secreto entre D. Pedro e Inés de Castro. Pasee por el centro histórico y no se pierda la belleza de sus monumentos. Visite los municipios vecinos, disfrute del paisaje y, con Travizco, descubra dónde comer lo mejor de estas partes!"])},
    "castelo_branco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castelo Branco se encuentra en el interior central del país, en Beira Baixa. Es la capital de distrito y a ella pertenecen once municipios. Desde las murallas del castillo se puede ver parte de la ciudad. El bordado adquirió gran importancia en la región con la cultura del lino como tradición. Los productos de la tierra son de gran repercusión, como el aceite de oliva y el vino. Del pastoreo se producen quesos, algunos de los más apreciados del país. La producción de cerezas, guindas y melocotones contribuye al desarrollo económico de la comarca. La marca geodésica que marca el centro de Portugal ofrece una vista magnífica. La arquitectura religiosa y militar se extiende por los distintos concelhos. Con TRAVIZCO, ¡venga a la tierra de los albicastrenses!"])},
    "coimbra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coimbra se encuentra en el centro de Portugal, capital del distrito. Ciudad de estudiantes que alguna vez fue la capital del país, con el río Mondego a sus pies. Un complejo arquitectónico que incluye la Universidad de Coimbra es Patrimonio de la Humanidad. Ciudad de amor y tradiciones académicas, residencias y repúblicas estudiantiles, un lugar de reunión donde se formaron varios escritores. ¡La belleza de la Biblioteca Joanina es única! Tabernas y aperitivos, dulces de conventos, platos típicos que se pueden encontrar en sus 17 municipios. Playas de arena dorada, altas montañas, pueblos de pizarra y Conímbriga son excelentes razones para visitar. Si nunca ha visto plantaciones de arroz, ¡Travizco le mostrará dónde encontrarlas!"])},
    "evora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évora, una ciudad que respira historia y arte portugués, su centro histórico ha sido declarado Patrimonio de la Humanidad por la UNESCO. La Praça do Giraldo, el Templo de Diana, la Capilla de los Huesos, la Catedral, la Iglesia de São Francisco... no hay nada como caminar al azar con TRAVIZCO en el bolsillo para descubrir esta maravillosa ciudad. Pero la belleza y el encanto no se encuentran solo en Évora, no pierdas la oportunidad de explorar el distrito donde seguramente te cautivarás con su patrimonio y belleza natural. El paisaje es típicamente alentejano, plano con extensos olivares y viñedos, árido en los meses más secos. Desde la cima de Monsaraz, se puede contemplar la inmensidad del embalse de Alqueva, el lago artificial más grande de Europa. A los pies de la sierra de Borba se encuentra Vila Viçosa, caracterizada por una atmósfera real distinta, en Arraiolos ven y descubre los tapices, en Portel visita el imponente castillo, programa una visita a las Cuevas de Escoural en Montemor-o-Novo... hay tanto que decir, pero lo mejor es dejarse llevar por TRAVIZCO en un recorrido por el Alentejo Central, un territorio de belleza única, rico en cultura, tradición y gastronomía."])},
    "faro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faro es la capital del Algarve, una región que no necesita presentación. Un territorio conocido por sus playas de arena dorada, el barrocal del que destacan su deliciosa miel, medronho y corcho. Por último, la cordillera, que ocupa el 50% de la región, una tierra de gran belleza cuya cumbre más alta se encuentra en la cordillera de Monchique a 902m de altitud. Su clima mediterráneo templado, bañado por más de 3000 horas de sol al año, convierte al Algarve en uno de los destinos turísticos más populares del mundo y muy solicitado para jugar al golf. El Algarve se compone de 16 municipios, cada uno con una identidad fuerte, lleno de historias y paisajes de belleza única. Pierdete en el Algarve y encuentra tu camino con TRAVIZCO."])},
    "guarda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El distrito de Guarda se encuentra en el centro de la región beirã, entre el Planalto Guarda-Sabugal y la Serra da Estrela. Es en sus murallas donde se cuenta la historia desde hace más de 800 años. Un lugar de salud y bienestar gracias al aire puro que se respira aquí. Es la región de los 5 F: Fuerte por el granito, Fértil por los valles, Fría por el clima, Fiel por sus gentes y Formosa por todo lo que posee. ¡Los pasillos del Mondego invitan a pasear, comenzando cerca de la presa de Caldeirão, con puentes colgantes e increíbles paisajes! ¡Hay 14 municipios con lugares de rara belleza! Pinturas rupestres, castillos y gastronomía serrana reconfortante, ¡ya son suficientes razones para venir aquí! ¡Tome a Travizco y aventúrese!"])},
    "leiria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiria se encuentra en la Región Centro Litoral, es la capital y sede del distrito. La ciudad creció junto al castillo, cerca del río Lis. Tiene 16 municipios con monumentos de una belleza extraordinaria. Los bosques, los interminables pinares, playas interminables, arquitectura religiosa y civil, sus termas y tradiciones populares son todos de gran importancia. La gastronomía, entre montañas y playas, es muy variada, con platos portugueses de pescado y carne, acompañados del vino de las Encostas de Aire DOC. La cerámica y el vidrio son una referencia. Otros castillos y monumentos merecen una visita obligada. Si te gusta el chocolate, no te pierdas el festival de la región. ¡Travizco estará contigo para indicarte el camino!"])},
    "lisboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisboa es la capital del país, la capital del distrito con el Tajo viendo zarpar sus barcos hacia los descubrimientos hace más de 500 años. Lisboa es la ciudad de las siete colinas, con sus castillos e imponentes iglesias de incomparable belleza. Ha sido reconstruida a partir de la tragedia, con monumentos arquitectónicos de gran valor, barrios emblemáticos que no han dejado de tener vida a lo largo de los años. Con 18 municipios, pueblos y ciudades con identidad propia, con plazas y tradiciones taurinas, viñedos y zonas agrícolas con las huertas que abastecen a la gran Lisboa. Las playas y los pueblos de pescadores que se han convertido en puntos turísticos, sin perder lo que más les caracteriza. La gastronomía es mucho más de lo que se puede escribir, está ahí para ser degustada. ¡TRAVIZCO encuentra ese lugar!"])},
    "madeira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madeira es un archipiélago (con Porto Santo e Ilhas Desertas), cuya capital es Funchal. Centrado en el turismo, llegan visitantes de los cuatro rincones del mundo, por el clima con temperaturas suaves y los paisajes con montañas y valles encantadores. En sus 11 municipios, busque los miradores y admire todo lo que ve (Cabo Girão y el skywalk). No deje de subir al Pico do Areeiro, a más de 1.800 m de altitud. Piscinas naturales, casas de Santana, aldeas pintorescas y pueblos de pescadores, ¡un mundo por descubrir con TRAVIZCO! Suba al teleférico, baje en los 'Carros de Cesto' y visite el Mercado de Lavradores. Gastronomía: pescado fresco, espetada, bolo do caco, vino de Madeira y Poncha... ¡muy recomendable!"])},
    "portalegre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portalegre está situada en el norte del Alentejo, en pleno Parque Natural de la Sierra de São Mamede. Es una ciudad rodeada de montañas, acantilados y llanuras, con paisajes de gran belleza. La fabricación de tapices se ha convertido en un ex-libris de la ciudad y en toda la región se puede encontrar artesanía. Hay muchos productos regionales, embutidos, frutas, miel y quesos que hacen que la gastronomía sea muy rica, junto con vinos y cervezas artesanales. Los dulces conventuales que se pueden encontrar por todo el territorio son un manjar de dioses. Las yeguadas donde se crían los mejores caballos lusitanos son una tierra de leyendas y tradiciones, fiestas y romerías que cuenta incluso con una 'Sintra del Alentejo' por la belleza de sus jardines, siendo algunos municipios de Portalegre los más fortificados de Europa. Con TRAVIZCO, ¡venga a conocerlos!"])},
    "porto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ciudad de Oporto es el distrito y sede del distrito de Oporto situado en la Región Norte, en el Duero Litoral. Agrupa 18 municipios donde se localizan la mayoría de las pequeñas y medianas empresas del país. El Puerto de Leixões y el aeropuerto Francisco Sá Carneiro son dos grandes e importantes infraestructuras de la región. Oporto es la ciudad que dio nombre a Portugal cuando se llamaba Portus Cale. Es mundialmente famosa por su vino (vino de Oporto), los colores de las casas de la Ribeira y el centro histórico, Patrimonio de la Humanidad. Francesinha es más famosa aquí que en Francia, ¡pero Tripas à moda do Porto es Porto! Ciudad invencible y región de gran belleza, tanto natural como arquitectónica, todos los municipios mantienen su propia identidad. Los barcos de rabelo que navegan por el Duero muestran los puentes y las orillas del Duero. No pierda más tiempo y con Travizco encontrará todos los tesoros escondidos."])},
    "santarem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santarém pertenece a la región del Ribatejo, capital y sede del distrito. Las Serras de Aire y Candeeiros son imponentes. Todo el patrimonio arquitectónico de la región tiene gran valor. Los Templarios dejaron gran parte de él, como castillos e iglesias. Ir en barco al castillo que está en el medio del río. El Santuario de Fátima atrae a miles de peregrinos durante todo el año, con el sitio recibiendo a miles de personas. Conoce la tierra de fenómenos, tierras de vinos y melones, así como la ciudad donde vivió Luís de Camões. Playas fluviales que se llenan en verano. ¿Tienes hambre? La gastronomía de la región con platos tradicionales es una obligación probar. Luego, encuentra la próxima dirección con TRAVIZCO."])},
    "setubal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la costa sur del río Tajo, en el estuario de Sado, se encuentra la hermosa ciudad de Setúbal, la capital de un distrito compuesto por 13 municipios. En la ciudad de Bocage y Luísa Todi, el Convento de Jesús en estilo gótico-manuelino, que alberga el Museo de la Ciudad, y el Fuerte de São Filipe, convertido en una Pousada, desde donde se puede contemplar la ciudad, el río Sado, Troia y la cordillera de la Arrábida, son lugares que hay que visitar. Cerca, los visitantes pueden encontrar áreas naturales preservadas como el estuario del río Sado y el Parque Natural de Arrábida. Las playas de esta región son de una belleza incomparable, y el generoso mar trae a la mesa de aquellos que visitan la región el mejor pescado y marisco de Portugal. En el interior, los platos de aves enriquecen las mesas, como el Arroz de Pato, la Cabidela de Galinha o la Feijoada de Frango. También vale la pena mencionar el Lomo de Cerdo Asado à Montijo. Traiga TRAVIZCO con usted y déjelo guiarlo por esta tierra de contrastes entre el mar y el río, entre la costa y el interior."])},
    "viana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viana do Castelo (capital de distrito) es una ciudad atlántica del norte de Portugal, en el Alto Minho. Paisaje natural, con presencia de río, montaña y mar (el río Lima nace en España y desemboca en Viana). En lo alto, la Basílica de Santa Luzia desde donde se tiene una hermosa vista, ¡imperdible! Suba al Elevador que hace un recorrido de 650 metros (el más largo de Portugal). Hay 10 municipios en Viana do Castelo, uno de los más bellos del país. Viana do Castelo tiene una fuerte conexión con el bacalao, desde aquí partían los bacaladeros hacia Terranova y Groenlandia. Hay varias delicias gastronómicas por todo el distrito. La Senhora da Agonia, que se celebra el 20 de agosto, atrae a muchos visitantes, al igual que las playas de arena fina. La arquitectura del centro histórico y el Puente Eiffel... ¡No hay que perderse los conocidos vinos Vinho Verde! Y como golosina, la Torta de Viana, certificada por el Ayuntamiento. Con Travizco, ¡déjese llevar y sorprender! Como recuerdo, llévese un pañuelo de San Valentín o un objeto en filigrana."])},
    "vila_real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por aquí se va a Marão, ¡los que están aquí mandan! Vila Real, ciudad y distrito del mismo nombre, está situada en el norte de Portugal, en la antigua provincia de Trás-os-Montes y Alto Douro. Está situada en las laderas de la Serra do Marão y cerca de la Serra do Alvão. Catorce municipios pertenecen al distrito de Vila Real, cada uno con sus particularidades que tanto los caracterizan. ¡El paisaje es impresionante! Maravilloso Reino de Miguel Torga, ¡es hoy un destino turístico de excelencia! Son muchas las Quintas que se pueden visitar y donde se pueden hacer catas de vino. La gastronomía de la región, tan rica y diversificada, es de obligada degustación. La construcción del túnel de Marão, con casi 6 kilómetros, el túnel carretero más largo del país, acercó aún más los distritos de Braga, Oporto y Guimarães. Con la N2 pasando por estos municipios, Travizco le cuenta qué encontrar y visitar."])},
    "viseu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ciudad de Viseu es la sede y capital del distrito, en la región del Norte Centro. Es el distrito más grande del país con 24 municipios, cada uno con características únicas. La región de Viseu Dão-Lafões está protegida por las montañas de Serra da Estrela, Montemuro y Caramulo, y es en sus terroirs donde nacen los néctares premiados. Disfrute de los balnearios de la región para revitalizarse y déjese llevar por los senderos de montaña, respire el aire fresco y aprecie el paisaje. Si lo desea, también puede perfeccionar su técnica de golf. La ciudad de Viriato tiene tantos monumentos, un interesante legado arquitectónico en todos los municipios. La gastronomía de la región es rica y diversa. ¡Yendo, yendo, con TRAVIZCO, en camino a Viseu!"])}
  }
}