<template>
  <v-card style="margin-top: 64px" flat>
    <v-container>
      <div v-if="$vuetify.display.mdAndUp" class="d-flex ml-3 mr-5" style="justify-content: space-between">
        <div>
          <h3 class="text-h4 font-weight-bold text-primary">Termos e condi&ccedil;&otilde;es</h3>
        </div>
        <div>
          <router-link to="/home">
            <v-img contain :src="require('../assets/privacy/travizco_azul.png')" max-height="100px" width="250px"></v-img>

          </router-link>
        </div>
      </div>

      <div v-else class="d-flex flex-column-reverse" align="center">
        <div class>
          <h3 class="text-h5 text-center font-weight-bold text-primary">Termos e condi&ccedil;&otilde;es</h3>
        </div>
        <div class="mb-7">
          <router-link to="/home">
            <v-img contain :src="require('../assets/privacy/travizco_azul.png')" max-height="100px" width="250px"></v-img>
          </router-link>

        </div>
      </div>
    </v-container>

    <v-container class="container" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-center">
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">1. Condi&ccedil;&otilde;es
        gerais</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">1. Condi&ccedil;&otilde;es gerais
      </p>
      <v-card flat>
        <v-card-text>
          <p>
            O
            <b>Travizco</b> administrado pela
            <b>Impactzero, Software lda.</b> tem por objetivo descobrir o patrim&oacute;nio cultural e
            hist&oacute;rico de uma determinada localidade.
          </p>
          <p>
            Antes, por&eacute;m, de qualquer consulta &agrave; APP, devem ser lidos os Termos de
            Utiliza&ccedil;&atilde;o e Pol&iacute;tica de Tratamento.
          </p>
          <p>
            A perman&ecirc;ncia e a utiliza&ccedil;&atilde;o da APP pelo visitante/utilizador implicam a
            aceita&ccedil;&atilde;o dos seus termos e
            condi&ccedil;&otilde;es, sem qualquer reserva, pelo que se com estes n&atilde;o concordar deve imediatamente
            cessar a
            navega&ccedil;&atilde;o.
          </p>
          <p>
            O
            <b>Travizco</b>, reserva-se o direito de, a todo o tempo, alterar a apresenta&ccedil;&atilde;o e
            configura&ccedil;&atilde;o da APP,
            bem como dos seus termos e condi&ccedil;&otilde;es de utiliza&ccedil;&atilde;o e da pol&iacute;tica de
            tratamento de dados pessoais, devendo,
            assim, o visitante/utilizador sempre que aceder a APP, consultar previamente estes termos e
            condi&ccedil;&otilde;es.
          </p>
          <p>
            Informa&ccedil;&otilde;es complementares poder&atilde;o ser obtidas junto da
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio através do e-mail support@travizco.com.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">2. Direitos de
        propriedade</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">2. Direitos de propriedades</p>
      <v-card flat>
        <v-card-text>
          <p>
            Todos os conte&uacute;dos da APP, designadamente, os textos, as fotografias, as imagens, as
            ilustra&ccedil;&otilde;es, os desenhos, os gr&aacute;ficos, as gravuras, as marcas,
            os log&oacute;tipos, a publicidade, os materiais de &aacute;udio ou de v&iacute;deo, o Web design e o
            software ou quaisquer outros sinais distintivos suscet&iacute;veis de utiliza&ccedil;&atilde;o
            comercial e industrial, encontram-se protegidos nos termos gerais de direito e pela legisla&ccedil;&atilde;o
            relativa &agrave; propriedade intelectual e industrial,
            dos direitos de autor e direitos conexos, n&atilde;o podendo ser utilizados fora da APP sem o pr&eacute;vio
            consentimento escrito da
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio.
          </p>
          <p>
            Sem preju&iacute;zo do que antecede, o utilizador pode descarregar ou copiar os conte&uacute;dos do APP
            estritamente para seu uso pessoal. &Eacute; expressamente proibida a utiliza&ccedil;&atilde;o da APP para
            fins ilegais ou
            para fins que possam ser considerados indignos da imagem do
            <b>Travizco</b>.
          </p>
          <p>
            A usurpa&ccedil;&atilde;o, contrafa&ccedil;&atilde;o, aproveitamento do conte&uacute;do usurpado ou
            contrafeito, a identifica&ccedil;&atilde;o ileg&iacute;tima e a concorr&ecirc;ncia desleal s&atilde;o
            pun&iacute;veis criminalmente.
          </p>
          <p>
            Ao
            <b>Travizco</b>, reserva-se o direito de agir judicialmente contra os autores de qualquer c&oacute;pia,
            reprodu&ccedil;&atilde;o ou outra utiliza&ccedil;&atilde;o n&atilde;o autorizada do conte&uacute;do da APP
            por terceiros.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">3. Tratamento de dados
        pessoais</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">3. Tratamento de dados pessoais
      </p>
      <v-card flat>
        <v-card-text class>
          <p>
            Com a entrada em vigor no dia 25 de maio de 2018, em todos os Estados-Membros da Uni&atilde;o Europeia, do
            Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (Regulamento
            Geral sobre a Prote&ccedil;&atilde;o de Dados (RGPD)), revela-se necess&aacute;rio publicitar a presente
            Pol&iacute;tica de Tratamento
            de Dados Pessoais do Instituto, a qual tem subjacente o compromisso da
            <b>Impactzero Software, lda.</b> em
            respeitar as regras de prote&ccedil;&atilde;o de dados pessoais.
          </p>
          <p>
            A presente Pol&iacute;tica estabelece, assim, a base na qual s&atilde;o objeto de tratamento desta APP
            quaisquer dados
            pessoais obtidos ou fornecidos pelos respetivos titulares.
            O termo “dados pessoais”, conforme usado nesta Pol&iacute;tica, refere-se a determinada
            informa&ccedil;&atilde;o como o nome,
            endere&ccedil;o de e-mail ou qualquer outra informa&ccedil;&atilde;o que possa ser usada para identificar o
            titular.
          </p>
          <p>
            <b>3.1 Consentimento</b>
            <br />Ao registar-se na APP
            <b>Travizco</b> e/ou fornecer quaisquer dados pessoais, o titular dos dados est&aacute; a prestar
            livremente o seu consentimento expl&iacute;cito para que os dados pessoais submetidos possam ser tratados
            para as
            finalidades e nos termos descritos nesta Pol&iacute;tica e em conformidade com as leis e regulamentos em
            vigor
            relacionados com dados pessoais.
          </p>
          <p>
            <b>3.2 Tratamento</b>
            <br />O tratamento de dados pessoais consiste numa opera&ccedil;&atilde;o ou conjunto de
            opera&ccedil;&otilde;es efetuadas sobre dados
            pessoais ou conjuntos de dados pessoais, atrav&eacute;s de meios automatizados, ou n&atilde;o, nomeadamente
            a recolha,
            o registo, a organiza&ccedil;&atilde;o, a estrutura&ccedil;&atilde;o, a conserva&ccedil;&atilde;o, a
            adapta&ccedil;&atilde;o, a recupera&ccedil;&atilde;o, a consulta, a utiliza&ccedil;&atilde;o, a
            divulga&ccedil;&atilde;o, difus&atilde;o, compara&ccedil;&atilde;o, interconex&atilde;o, a
            limita&ccedil;&atilde;o, o apagamento ou a destrui&ccedil;&atilde;o.
            Alguns dos dados pessoais s&atilde;o de fornecimento obrigat&oacute;rio para a
            disponibiliza&ccedil;&atilde;o do servi&ccedil;o e/ou
            informa&ccedil;&atilde;o em causa.
          </p>
          <p>
            O
            <b>Travizco</b> informar&aacute; dessa obrigatoriedade e na eventualidade de falta ou de fornecimento
            incorreto dos
            dados exigidos, o
            <b>Travizco</b> n&atilde;o poder&aacute; prestar o servi&ccedil;o e/ou informa&ccedil;&atilde;o solicitado.
            A disponibiliza&ccedil;&atilde;o de
            dados incorretos ou inexatos &eacute; da exclusiva responsabilidade do respetivo titular.
          </p>
          <p>
            <b>3.3 Respons&aacute;vel pelo tratamento</b>
            <br />Esta APP &eacute; propriedade exclusiva da
            <b>Impactzero, Software lda.</b> pessoa coletiva n.º 509212956, com sede na
            Rua Separra nº289 4950-609 Moreira Mon&ccedil;&atilde;o, sendo esta a entidade respons&aacute;vel pelo
            tratamento dos dados
            pessoais obtidos ou fornecidos na APP link . O contacto poder&aacute; ser, ainda, efetuado atrav&eacute;s do
            endere&ccedil;o
            eletr&oacute;nico: geral@travizco.com
          </p>
          <p>
            <b>3.4 Finalidades da recolha dos dados pessoais</b>
            <br />Os dados pessoais recolhidos na APP
            <b>Travizco</b> s&atilde;o objeto de tratamento no &acirc;mbito da promo&ccedil;&atilde;o do Destino e
            da presta&ccedil;&atilde;o de informa&ccedil;&otilde;es para os utilizadores que pretendam conhecer e/ou
            visitar um determinado
            munic&iacute;pio.
          </p>
          <p>
            Os dados pessoais tamb&eacute;m poder&atilde;o ser tratados para responder a quest&otilde;es,
            sugest&otilde;es ou reclama&ccedil;&otilde;es
            apresentadas pelo respetivo titular.
          </p>
          <p>
            <b>3.5 Direitos dos titulares dos dados pessoais</b>
            <br />Nos termos e para os efeitos do disposto nos artigos 13.º a 22.º do RGPD, a
            <b>Impactzero, Software lda.</b>
            informa que quaisquer dados que estejam na sua posse podem ser consultados, limitados no seu tratamento,
            retificados (por incorre&ccedil;&atilde;o, inexatid&atilde;o ou incomplei&ccedil;&atilde;o) pelo respetivo
            titular e, no caso de o tratamento se
            ter fundado no consentimento, tem, ainda, o titular direito a solicitar o apagamento dos dados cujo
            tratamento n&atilde;o se enquadre
            numa das situa&ccedil;&otilde;es de licitude previstas no artigo 6.º, n.º 1, al&iacute;neas b) a f) do RGPD.
          </p>
          <p>
            Mais informa a
            <b>Impactzero, Software lda.</b> que nos casos em que o tratamento dependa de consentimento, o
            titular dos dados tem o direito de retirar o seu consentimento a qualquer momento. A retirada do
            consentimento n&atilde;o compromete a licitude do tratamento efetuado com base no consentimento previamente
            dado.
          </p>
          <p>
            O titular dos dados tem, ainda, direito a opor-se &aacute; continua&ccedil;&atilde;o do tratamento dos seus
            dados pessoais, a ser
            informado, a pedido, sobre as finalidades do tratamento, as categorias de dados envolvidos, a identidade dos
            destinat&aacute;rios a quem tenham sido divulgados e o per&iacute;odo de conserva&ccedil;&atilde;o dos seus
            dados pessoais, assim
            como a receber os dados pessoais que lhe dizem respeito e que tenha fornecido e envi&aacute;-los para outro
            respons&aacute;vel pelo tratamento (“portabilidade dos dados”).
          </p>
          <p>
            <b>3.6 Subcontratantes</b>
            <br />A
            <b>Impactzero, Software lda.</b> pode recorrer a subcontratantes para a manuten&ccedil;&atilde;o, alojamento
            e gest&atilde;o dos
            seus sistemas e equipamentos inform&aacute;ticos, nos termos permitidos pela legisla&ccedil;&atilde;o, que
            regula o tratamento
            de dados pessoais, ficando estas entidades obrigadas a guardar sigilo e a garantir a seguran&ccedil;a
            relativamente
            aos dados dos utilizadores da APP
            <b>Travizco</b> , a que, para o efeito, tenham acesso, n&atilde;o podendo utilizar esses
            dados para quaisquer outros fins, ou em benef&iacute;cio pr&oacute;prio, nem relacion&aacute;-los com outros
            dados que
            possuam.
          </p>
          <p>
            Em qualquer dos casos, a
            <b>Impactzero, Software lda.</b> e ou respetivo Munic&iacute;pio permanece respons&aacute;vel pelos
            dados pessoais que lhe sejam disponibilizados.
          </p>
          <p>
            <b>3.7 Comunica&ccedil;&atilde;o e transfer&ecirc;ncia de dados pessoais</b>
            <br />Os pedidos de informa&ccedil;&atilde;o submetidos atrav&eacute;s do canal “Contacte-nos” podem ser
            encaminhados a outras
            entidades mais habilitadas a prestar o esclarecimento ou o atendimento solicitado. Esse envio &eacute;
            efetuado
            individualmente e nunca em ficheiros de dados, e desse encaminhamento &eacute; dado conhecimento ao
            utilizador.
          </p>
          <p>
            Os dados pessoais ser&atilde;o transmitidos a outras entidades no caso atr&aacute;s identificado e
            poder&atilde;o ser
            disponibilizados aos tribunais e demais autoridades competentes, no estrito cumprimento do disposto na lei,
            nomeadamente quando se revelem necess&aacute;rios para a resolu&ccedil;&atilde;o de lit&iacute;gios e para
            atividades relativas &aacute;
            prote&ccedil;&atilde;o da seguran&ccedil;a pública, da defesa e seguran&ccedil;a do Estado e &aacute;
            preven&ccedil;&atilde;o, investiga&ccedil;&atilde;o ou dete&ccedil;&atilde;o de
            il&iacute;citos criminais.
          </p>
          <p>
            <b>3.8 Conserva&ccedil;&atilde;o dos dados pessoais</b>
            <br />O per&iacute;odo de tempo durante o qual os dados pessoais s&atilde;o armazenados e conservados varia
            de acordo com a
            finalidade para a qual a informa&ccedil;&atilde;o &eacute; tratada.
          </p>
          <p>
            O
            <b>Travizco</b> conserva os dados pessoais apenas durante o tempo que for necess&aacute;rio para atingir o
            objetivo
            para o qual foram fornecidos ou para cumprir quaisquer obriga&ccedil;&otilde;es contratuais ou
            jur&iacute;dicas a que se
            encontra adstrito.
            Assim, e sempre que n&atilde;o exista uma exig&ecirc;ncia legal espec&iacute;fica, os dados ser&atilde;o
            conservados apenas pelo
            per&iacute;odo m&iacute;nimo necess&aacute;rio para a prossecu&ccedil;&atilde;o das finalidades que
            motivaram a sua recolha ou o seu
            posterior tratamento, nos termos definidos na lei.
          </p>
          <p>
            <b>3.9 Menores</b>
            <br />Os menores de 16 anos dever&atilde;o obter autoriza&ccedil;&atilde;o dos pais ou tutores antes de
            acederem ou disponibilizarem
            dados pessoais na APP.
          </p>
          <p>
            A responsabilidade na identifica&ccedil;&atilde;o dos conteúdos e p&aacute;ginas a que os menores de 16 anos
            acedem &eacute; dos seus
            pais ou tutores legais.
          </p>
          <p>
            <b>3.10 Reclama&ccedil;&otilde;es</b>
            <br />Sem preju&iacute;zo de poder apresentar reclama&ccedil;&otilde;es diretamente a
            <b>Travizco vs Impactzero</b>. atrav&eacute;s dos contactos
            disponibilizados para o efeito, o titular dos dados pode reclamar diretamente para a Comiss&atilde;o
            Nacional de
            Prote&ccedil;&atilde;o de Dados (CNPD), que &eacute; a Autoridade de Controlo em Portugal, utilizando os
            contactos
            disponibilizados por esta entidade para o efeito.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">4. Privacidade</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">4. Privacidade</p>
      <v-card flat>
        <v-card-text>
          <p>
            A
            <b>Impactzero, Software lda.</b> respeita integralmente o direito &aacute; privacidade dos utilizadores da
            APP raz&atilde;o pela
            qual, por princ&iacute;pio, n&atilde;o captura nem utiliza informa&ccedil;&atilde;o de
            navega&ccedil;&atilde;o associada &aacute; identifica&ccedil;&atilde;o dos seus
            visitantes, limitando-se a recolha de informa&ccedil;&otilde;es de natureza pessoal &aacute; que for
            voluntariamente fornecida
            pelos visitantes/utilizadores, sendo as mesmas utilizadas exclusivamente para os fins a que se destinam.
          </p>
          <p>
            Em complemento do que antecede, importa esclarecer que o APP recolhe e utiliza a seguinte
            informa&ccedil;&atilde;o, em
            linha com o disposto em mat&eacute;ria de cookies:
          </p>
          <ul class="font-weight-light ml-5">
            <li>
              O pa&iacute;s de origem do utilizador, por forma a melhorar a sintonia entre os seus objetivos e
              interesses e
              a informa&ccedil;&atilde;o que &eacute; mostrada;
            </li>
            <li>
              &eacute; expressamente indicada pelo utilizador sobre conteúdos e outras p&aacute;ginas, que aquele pode
              depois
              organizar no sentido de criar um plano para a sua visita, e que s&atilde;o guardadas entre visitas (para
              utilizadores com cookies ou com login), podendo, desta forma, APP prestar um servi&ccedil;o de suporte ao
              planeamento de uma viagem a Portugal por parte do visitante e/ou de um grupo;
            </li>
            <li>
              A expressamente indicada pelo utilizador sobre o seu perfil e interesses, por forma a mostrar os
              conteúdos que melhor se identificam com o visitante;
            </li>
            <li>
              A identifica&ccedil;&atilde;o do visitante que se regista, solicita informa&ccedil;&atilde;o ao
              utilizador.
            </li>
          </ul>
          <p>
            Qualquer tipo de intera&ccedil;&atilde;o com a APP ficar&aacute; registada para efeitos de controle de
            qualidade e reporting,
            designadamente, mas n&atilde;o exclusivamente, a que for submetida para efeitos de registo, pedidos de
            informa&ccedil;&atilde;o e passatempos.
            <br />A atualiza&ccedil;&atilde;o e retifica&ccedil;&atilde;o de dados pessoais pode ser efetuada na APP na
            &aacute;rea de Registo/Perfil.
          </p>
          <p>
            O utilizador pode ainda solicitar na APP a inativa&ccedil;&atilde;o da sua conta sendo eliminados os dados
            sobre “Perfil”,
            conteúdos adicionados ao “Planeamento”. A inativa&ccedil;&atilde;o da conta n&atilde;o significa, no
            entanto, que sejam
            apagados os dados de registo, designadamente para efeitos de hist&oacute;rico, mas sim que estes n&atilde;o
            ser&atilde;o usados
            pelo
            <b>Travizco</b>.
          </p>
          <p>
            A APP elabora, ainda, uma base de dados com informa&ccedil;&atilde;o agregada meramente estat&iacute;stica,
            sem qualquer
            identifica&ccedil;&atilde;o pessoal, com os perfis, interesses e pa&iacute;ses de origem dos seus
            visitantes. Esta informa&ccedil;&atilde;o &eacute;
            utilizada para melhorar a resposta da APP aos interesses e solicita&ccedil;&otilde;es dos seus visitantes,
            podendo ser, para
            esse mesmo efeito, disponibilizada a terceiros.
          </p>
          <p>
            Para auxiliar a recolha, armazenamento e tratamento de dados estat&iacute;sticos a
            <b>Impactzero, Software lda.</b> ou
            respetivo Munic&iacute;pio podem recorrer a ferramentas de terceiros.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">5. Exclus&atilde;o de
        resposabilidade</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">5. Exclus&atilde;o de
        resposabilidade</p>
      <v-card flat>
        <v-card-text>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio parceiro, reserva-se o direito de a qualquer
            momento
            suspender, modificar ou descontinuar a publica&ccedil;&atilde;o de totalidade ou parte da APP Os
            visitantes/utilizadores
            aceitam que o acesso &aacute; APP e aos seus canais e servi&ccedil;os pode ser terminado a qualquer momento,
            com ou
            sem aviso pr&eacute;vio, n&atilde;o podendo a Impactzero Software ou respetivo Munic&iacute;pio ser
            responsabilizado por esse
            facto.
          </p>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio compromete-se a realizar todos os
            esfor&ccedil;os e dilig&ecirc;ncias
            necess&aacute;rias para manter o normal funcionamento da APP com a disponibilidade e continuidade
            poss&iacute;veis
            face aos meios t&eacute;cnicos existentes, adotando, em cada momento, as melhores pr&aacute;ticas
            internacionais
            geralmente utilizadas no setor, em particular em mat&eacute;ria de seguran&ccedil;a e de garantia da
            confidencialidade e
            privacidade dos visitantes/utilizadores da APP, evitando, tamb&eacute;m por todos os meios poss&iacute;veis,
            a exist&ecirc;ncia
            de v&iacute;rus ou outros elementos lesivos que possam provocar altera&ccedil;&otilde;es no sistema
            inform&aacute;tico do
            visitante/utilizador.
          </p>
          <p>
            Sem preju&iacute;zo do empenho declarado, a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio n&atilde;o pode garantir, e por
            isso declina a sua responsabilidade, pelos danos provocados por interrup&ccedil;&otilde;es, avarias ou
            defici&ecirc;ncias no
            funcionamento da APP. Do mesmo modo, a
            <b>Impactzero Software lda</b> ou respetivo Munic&iacute;pio n&atilde;o pode
            assegurar que a APP funcione corretamente de modo permanente, bem como que o mesmo se encontra a
            todo o momento livre de v&iacute;rus ou outros elementos lesivos que possam danificar ou provocar
            altera&ccedil;&otilde;es no
            sistema inform&aacute;tico do visitante/utilizador, nos seus documentos eletr&oacute;nicos ou ficheiros,
            n&atilde;o sendo, de
            modo algum, respons&aacute;vel por eventuais danos e preju&iacute;zos causados.
          </p>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio ainda qualquer responsabilidade pelos
            preju&iacute;zos que possam
            resultar de interven&ccedil;&otilde;es ou comportamentos de terceiros que violem direitos de personalidade,
            de
            propriedade intelectual e industrial, segredos comerciais ou industriais, concorr&ecirc;ncia desleal e
            publicidade
            il&iacute;cita, bem como o resultado de intromiss&otilde;es abusivas que possam afetar a privacidade e a
            seguran&ccedil;a da
            utiliza&ccedil;&atilde;o da APP.
          </p>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio desenvolver&aacute; todos os esfor&ccedil;os
            no sentido de garantir que a
            informa&ccedil;&atilde;o e os factos constantes da APP s&atilde;o, de entre os dispon&iacute;veis, os
            melhores e os mais relevantes.
            Todavia, a
            <b>Impactzero, Software lda</b> ou respetivo Munic&iacute;pio., n&atilde;o d&aacute; qualquer garantia,
            expl&iacute;cita ou impl&iacute;cita, de
            que as informa&ccedil;&otilde;es da APP que sejam fornecidas, produzidas ou emitidas por terceiros estejam
            sempre
            atualizadas e corretas, n&atilde;o assumindo qualquer responsabilidade pelas consequ&ecirc;ncias, diretas
            e/ou indiretas,
            que decorram da utiliza&ccedil;&atilde;o da informa&ccedil;&atilde;o prestada. A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio
            recomenda, assim, que os utilizadores da APP contactem diretamente as organiza&ccedil;&otilde;es e empresas
            mencionadas, por forma a confirmarem as informa&ccedil;&otilde;es recolhidas.
          </p>
          <p>
            O
            <b>Travizco</b> agradece que o visitante/utilizador o informe sempre que encontre na APP dados incorretos
            e/ou
            desatualizados, de forma a que os mesmos possam ser corrigidos.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">
        6. Participa&ccedil;&atilde;o dos utilizadores nos conteúdos
        da APP
      </v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">
        6. Participa&ccedil;&atilde;o dos utilizadores nos conteúdos
        da APP
      </p>
      <v-card flat>
        <v-card-text>
          <p>
            A APP permite e incentiva a participa&ccedil;&atilde;o direta dos seus utilizadores, atrav&eacute;s do
            carregamento de textos,
            filmes e fotos, adiante designado por “Conteúdo”, partilhando a sua experi&ecirc;ncia num determinado
            munic&iacute;pio
            ou local, atrav&eacute;s de funcionalidades criadas para o efeito.
          </p>
          <p>
            Os utilizadores que carreguem diretamente conteúdos na APP n&atilde;o podem assumir a identidade de
            terceiros
            nem falsear a sua identidade.
            <br />Os conteúdos inseridos pelos utilizadores diretamente no APP s&atilde;o da exclusiva responsabilidade
            dos seus
            autores, expressando unicamente a sua opini&atilde;o pessoal, n&atilde;o podendo a
            <b>Impactzero, Software lda.</b> ou respetivo
            Munic&iacute;pio seja a que t&iacute;tulo for, serem responsabilizado pelos mesmos.
          </p>
          <p>
            Sem preju&iacute;zo do que antecede, a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio poder&aacute; monitorizar os
            conteúdos carregados diretamente pelos utilizadores na APP, reservando-se o direito de editar, recusar a
            publica&ccedil;&atilde;o ou
            remover/eliminar, total ou parcialmente, os conteúdos que, nomeadamente:
          </p>
          <ul class="font-weight-light ml-5">
            <li>
              N&atilde;o cumpram os presentes termos de refer&ecirc;ncia;
              <br />
            </li>
            <li>
              Violem qualquer lei nacional ou internacional aplic&aacute;vel;
              <br />
            </li>
            <li>
              Contenham mensagens consideradas il&iacute;citas, ofensivas, difamat&oacute;rias, abusivas, obscenas ou
              discriminat&oacute;rias, de car&aacute;ter racial, sexual, religioso, pol&iacute;tico ou por
              quest&otilde;es relacionadas com cren&ccedil;as,
              nacionalidade, idade ou condi&ccedil;&atilde;o social;
              <br />
            </li>
            <li>
              Violem direitos de terceiros, nos dom&iacute;nios da personalidade, de autor e conexos, industrial,
              comercial ou
              outros.
              <br />
              <b>Nota</b>: Caso o utilizador pretenda carregar conteúdos cuja titularidade n&atilde;o lhe
              perten&ccedil;a, este deve obter
              pr&eacute;via autoriza&ccedil;&atilde;o dos leg&iacute;timos titulares desses direitos que lhe permita
              utilizar esses conteúdos e realizar o
              seu carregamento na APP, de acordo com as condi&ccedil;&otilde;es constantes nos presentes termos de
              refer&ecirc;ncia;
              <br />
            </li>
            <li>
              Contenham v&iacute;rus inform&aacute;ticos, programas e outras componentes que possam afetar ou danificar
              os dados e revelar-se prejudiciais ou contaminantes;
              <br />
            </li>
            <li>
              Constituam “spam”, “correntes”;
              <br />
            </li>
            <li>
              Contenham endere&ccedil;os de correio eletr&oacute;nico ou de websites ou tags de html;
              <br />
            </li>
            <li>
              Visem objetivos de natureza comercial ou publicit&aacute;ria, ou constituam publicidade enganosa,
              il&iacute;cita ou
              desleal;
              <br />
            </li>
            <li>
              N&atilde;o estejam relacionados com o &acirc;mbito da APP ou contenham informa&ccedil;&otilde;es falsas,
              amb&iacute;guas ou
              enganadoras.
              <br />
            </li>
          </ul>
          <p></p>
          <p>Os conteúdos inseridos pelos utilizadores diretamente na APP est&atilde;o limitados a:</p>
          <ul class="font-weight-light ml-5">
            <li>
              Texto – at&eacute; 5000 carateres
              <br />
            </li>
            <li>
              Imagem – 4MB m&aacute;ximo
              <br />
            </li>
          </ul>
          <br />
          <p>
            Os conteúdos inseridos diretamente na APP pelos utilizadores podem estar redigidos em portugu&ecirc;s,
            ingl&ecirc;s,
            franc&ecirc;s e espanhol.
          </p>
          <p>
            As orienta&ccedil;&otilde;es antes indicadas, n&atilde;o sendo exaustivas, t&ecirc;m um car&aacute;ter
            meramente exemplificativo,
            reservando-se a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio o direito de as alterar a qualquer momento.
          </p>
          <p>
            Ao carregar diretamente conteúdos na pp os utilizadores autorizam, a t&iacute;tulo gratuito e sem qualquer
            tipo de
            limita&ccedil;&otilde;es ou reservas, que a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio proceda &aacute; livre
            utiliza&ccedil;&atilde;o dos
            conteúdos carregados, tal como foram introduzidos ou com as altera&ccedil;&otilde;es que entenda conveniente
            realizar,
            por si ou por interm&eacute;dio de terceiros, pela forma que melhor entender em qualquer local,
            independentemente
            do suporte e dos meios adotados.
          </p>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio n&atilde;o garante aos seus utilizadores que o
            seu conteúdo ser&aacute;
            total ou parcialmente publicado.
            <br />O utilizador responder&aacute; pelos danos e preju&iacute;zos de qualquer natureza que o
            <b>Travizco</b> possa sofrer, direta ou
            indiretamente, em resultado do uso dos conteúdos cedidos, designadamente por viola&ccedil;&atilde;o de
            direitos de
            terceiros e/ou viola&ccedil;&atilde;o dos presentes Termos de Utiliza&ccedil;&atilde;o e Pol&iacute;tica de
            Tratamento de Dados Pessoais.
          </p>
          <p>
            Sem preju&iacute;zo da responsabilidade por danos e preju&iacute;zos que possam resultar, a
            <b>Impactzero, Software lda.</b> ou
            respetivo Munic&iacute;pio pode, a qualquer momento e sem necessidade de pr&eacute;-aviso, resolver e fazer
            cessar a sua
            rela&ccedil;&atilde;o com o utilizador, impedindo/bloqueando o seu acesso a APP ou a qualquer dos seus
            canais ou
            funcionalidades, se detetar um uso desconforme com o estipulado nos presentes Termos de
            Utiliza&ccedil;&atilde;o e
            Pol&iacute;tica de Tratamento de Dados Pessoais ou se contr&aacute;rio &aacute; lei.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">7. Cookies</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">7. Cookies</p>
      <v-card flat>
        <v-card-text>
          <p>
            A APP utiliza um mecanismo denominado cookies, que s&atilde;o pequenos ficheiros de texto guardados no
            computador do utilizador e que permitem a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio identificar os
            computadores que acedem a APP e quais os conteúdos que interessem aos respetivos utilizadores.
          </p>
          <p>
            Apesar de a
            <b>Impactzero, Software lda</b> ou respetivo Munic&iacute;pio n&atilde;o ceder a terceiros qualquer
            informa&ccedil;&atilde;o
            individualizada, ainda que n&atilde;o identificada, a APP guarda informa&ccedil;&atilde;o estat&iacute;stica
            agregada sobre as
            prefer&ecirc;ncias dos seus visitantes, podendo ced&ecirc;-las a entidades oficiais tais como museus,
            turismo Portugal
            etc...
          </p>
          <p>
            Os cookies permitem a APP reconhecer o utilizador de um computador num posterior visita, agindo, na nova
            visita, como uma continua&ccedil;&atilde;o da anterior.
          </p>
          <p>
            Caso o utilizador se registe e utilize o login para se identificar, a APP recuperar&aacute; a
            informa&ccedil;&atilde;o que j&aacute; tiver
            sobre o utilizador, mesmo que essa informa&ccedil;&atilde;o tenha sido capturada em outro computador.
          </p>
          <p>
            A utiliza&ccedil;&atilde;o de cookies pode ser desligada no seu computador/telem&oacute;vel. Em alguns
            sistemas operativos
            e/ou browsers pode ser desligada apenas para determinados sites. Consulte o manual do seu computador
            para efetuar esta opera&ccedil;&atilde;o.
          </p>
          <p>
            Se desligar a utiliza&ccedil;&atilde;o de cookies, a APP trat&aacute;-lo-&aacute; como um novo utilizador
            sempre que ao mesmo aceder
            e n&atilde;o guardar&aacute; qualquer informa&ccedil;&atilde;o sua entre as visitas que efetuar.
          </p>
          <p>
            Simultaneamente, o n&atilde;o uso de cookies pode traduzir-se na inoperacionalidade de determinadas
            funcionalidades da APP, tais como “Planeamento”, cria&ccedil;&atilde;o de registos “Recordar e Partilhar” ou
            impress&atilde;o
            de conteúdos adicionados ao “Planeamento”.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">8. Links</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">8. Links</p>
      <v-card flat>
        <v-card-text>
          <p>
            A APP cont&eacute;m endere&ccedil;os de websites de empresas e entidades tur&iacute;sticas e outras fontes
            de informa&ccedil;&atilde;o que
            podem ser de interesse para o visitante/utilizador a
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio declina
            qualquer responsabilidade no que diz respeito &aacute; informa&ccedil;&atilde;o prestada por esses websites,
            nem tem controlo
            sobre a pol&iacute;tica de tratamento de dados pessoais e seguran&ccedil;a desses websites. (Google e
            Facebook).
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">
        9. Altera&ccedil;&otilde;es aos termos de
        utiliza&ccedil;&atilde;o e pol&iacute;tica de tratamento de dados pessoais
      </v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">
        9. Altera&ccedil;&otilde;es aos termos de
        utiliza&ccedil;&atilde;o e pol&iacute;tica de tratamento de dados pessoais
      </p>
      <v-card flat>
        <v-card-text>
          <p>
            A
            <b>Impactzero, Software lda.</b> ou respetivo Munic&iacute;pio reserva-se o direito de a qualquer altura,
            proceder a
            reajustamentos ou altera&ccedil;&otilde;es aos presentes Termos de Utiliza&ccedil;&atilde;o e
            Pol&iacute;tica de Tratamento de Dados
            Pessoais, publicando tais altera&ccedil;&otilde;es nos meios de divulga&ccedil;&atilde;o pr&oacute;prios.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">10. Interpreta&ccedil;&atilde;o
        e foro</v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">10. Interpreta&ccedil;&atilde;o e
        foro</p>
      <v-card flat>
        <v-card-text>
          <p>
            A interpreta&ccedil;&atilde;o destes Termos de Utiliza&ccedil;&atilde;o e Pol&iacute;tica de Tratamento de
            Dados Pessoais, bem como a
            resolu&ccedil;&atilde;o de quaisquer lit&iacute;gios que surjam no &acirc;mbito da APP ser&atilde;o
            regulados exclusivamente pela lei
            portuguesa e submetidos &aacute; jurisdi&ccedil;&atilde;o exclusiva do Tribunal Administrativo de
            C&iacute;rculo de Lisboa.
          </p>
        </v-card-text>
      </v-card>
      <v-card-title class="font-weight-black text-primary" v-if="$vuetify.display.smAndUp">
        11. Agradecimento às seguintes autarquias pela cedência de
        conteúdo
      </v-card-title>
      <p class="font-weight-black text-primary title-mobile" v-if="$vuetify.display.xs">
        11. Agradecimento às seguintes autarquias pela cedência de
        conteúdo
      </p>
      <v-card flat class="mb-10">
        <v-card-text>
          <p>
            Corvo, São Roque do Pico, Lajes do Pico, Nordeste, Velas, Madalena, Povoação, Vila do Porto, Praia
            da Vitória, Angra do Heroísmo,
            Sever do Vouga, Castelo de Paiva, São João da Madeira, Oliveira do Bairro, Vale de Cambra,
            Albergaria-a-Velha, Espinho, Ílhavo, Águeda,
            Ovar, Oliveira de Azeméis, Santa Maria da Feira, Almodôvar, Mértola, Ferreira do Alentejo, Beja,
            Terras de Bouro, Vieira do Minho,
            Cabeceiras de Basto, Celorico de Basto, Vizela, Esposende, Vila Verde, Fafe, Barcelos, Vila Nova de
            Famalicão, Braga, Miranda do Douro,
            Torre de Moncorvo, Vinhais, Macedo de Cavaleiros, Vila de Rei, Oleiros, Penamacor, Covilhã, Castelo
            Branco, Góis, Pampilhosa da Serra,
            Vila Nova de Poiares, Arganil, Lousã, Oliveira do Hospital, Montemor-o-Velho, Coimbra, Mourão, Portel,
            Borba, Estremoz, Évora, Aljezur,
            Castro Marim, São Brás de Alportel, Lagoa, Lagos, Albufeira, Faro, Loulé, Fornos de Algodres, Mêda,
            Figueira de Castelo Rodrigo,
            Vila Nova de Foz Côa, Celorico da Beira, Sabugal, Gouveia, Figueiró dos Vinhos, Óbidos, Ansião,
            Bombarral, Porto de Mós, Peniche, Pombal,
            Leiria, Arruda dos Vinhos, Lourinhã, Alenquer, Torres Vedras, Vila Franca de Xira, Odivelas, Loures,
            Cascais, Porto Moniz, Porto Santo,
            Santana, Ribeira Brava, Câmara de Lobos, Funchal, Arronches, Castelo de Vide, Crato, Gavião, Marvão,
            Avis, Ponte de Sor, Elvas, Baião,
            Trofa, Lousada, Marco de Canaveses, Amarante, Paços de Ferreira, Santo Tirso, Vila do Conde, Gondomar,
            Vila Nova de Gaia, Constância,
            Sardoal, Golegã, Vila Nova da Barquinha, Chamusca, Coruche, Entroncamento, Almeirim, Benavente,
            Torres Novas, Abrantes, Ourém,
            Alcácer do Sal, Sines, Alcochete, Santiago do Cacém, Sesimbra, Barreiro, Almada, Vila Nova de
            Cerveira, Caminha, Arcos de Valdevez,
            Ponte de Lima, Viana do Castelo, Mesão Frio, Murça, Sabrosa, Santa Marta de Penaguião, Montalegre,
            Vila Pouca de Aguiar, Valpaços,
            Penedono, Armamar, Tabuaço, Tarouca, Carregal do Sal, Moimenta da Beira, Mortágua, Resende, Vouzela, Sátão,
            Santa Comba Dão,
            São Pedro do Sul, Cinfães, Mangualde e Lamego.
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "Privacy",
  created() {
    AOS.init();
  },
};
</script>
<style scoped>
p {
  margin-bottom: 16px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: rgba(0, 0, 0, 0.6);
}

.title-mobile {
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>