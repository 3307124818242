<template>
  <AppBar
    :show-tabs="showTabs"
    :isTouchless="touchlessBoolean"
    :tabs="tabs"
    :tab-index="tab_selected"
    :shrink-bar="shrinkBar"
  ></AppBar>

  <div v-if="$vuetify.display.mdAndUp">
    <v-parallax
      height="65vh"
      scale="0.85"
      :src="destinationImage"
      gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.1)"
    >
      <div class="d-flex flex-column fill-height justify-end align-start text-white">
        <h1 class="font-weight-medium text-h4 text-white mb-4 ml-10 pl-6">{{ destinationName }}</h1>
      </div>
    </v-parallax>

    <v-container fluid class="mb-12 pl-8 pr-8">
      <v-row class="mt-10">
        <v-col cols="5" class="text-justify ml-8 mr-12">
          <h2>
            {{ $t('about') }}
            <span>{{ destinationName }}</span>
          </h2>
          <h4 class="mt-4">{{ destinationDescription }}</h4>
        </v-col>
        <v-col cols="6">
          <!-- map -->
          <DestinationMap :destino="destinationName"></DestinationMap>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <v-parallax
      height="60vh"
      scale="0.85"
      :src="destinationImage"
      gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.1)"
    >
      <div class="d-flex flex-column fill-height justify-end align-start text-white">
        <h1 class="font-weight-medium text-h4 text-white mb-4 ml-7">{{ destinationName }}</h1>
      </div>
    </v-parallax>

    <v-container>
      <v-col>
        <h2>
          {{ $t('about') }}
          <span>{{ destinationName }}</span>
        </h2>
        <p class="caption mt-4">{{ destinationDescription }}</p>
      </v-col>
      <v-col>
        <!-- map -->
        <DestinationMap :destino="destinationName"></DestinationMap>
      </v-col>
    </v-container>
  </div>

  <Footer></Footer>
</template>

<script>
import AppBar from "../components/AppBar.vue"
import Footer from "../components/Footer.vue"
import DestinationMap from "../components/DestinationMap.vue"

export default {
  name: "destinationDetails",
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      destinationName: this.$route.params.name,
      destinationImage: "",
      destinationDescription: "",
      districts: [
        {
          id: 1,
          name: "Aveiro",
          image: require("../assets/destination_details/aveiro.jpg"),
          description: "",
        },
        {
          id: 2,
          name: "Açores",
          image: require("../assets/destination_details/acores.jpg"),
          description: "",
        },
        {
          id: 3,
          name: "Beja",
          image: require("../assets/destination_details/beja.jpg"),
          description: "",
        },
        {
          id: 4,
          name: "Braga",
          image: require("../assets/destination_details/braga.jpg"),
          description: "",
        },
        {
          id: 5,
          name: "Bragança",
          image: require("../assets/destination_details/braganca.jpg"),
          description: "",
        },
        {
          id: 6,
          name: "Castelo Branco",
          image: require("../assets/destination_details/castelo_branco.jpg"),
          description: "",
        },
        {
          id: 7,
          name: "Coimbra",
          image: require("../assets/destination_details/coimbra.jpg"),
          description: "",
        },
        {
          id: 8,
          name: "Évora",
          image: require("../assets/destination_details/evora.jpg"),
          description: "",
        },
        {
          id: 9,
          name: "Faro",
          image: require("../assets/destination_details/faro.jpg"),
          description: "",
        },
        {
          id: 9,
          name: "Guarda",
          image: require("../assets/destination_details/guarda.jpg"),
          description: "",
        },
        {
          id: 10,
          name: "Leiria",
          image: require("../assets/destination_details/leiria.jpg"),
          description: "",
        },
        {
          id: 11,
          name: "Lisboa",
          image: require("../assets/destination_details/lisboa.jpg"),
          description: "",
        },
        {
          id: 12,
          name: "Madeira",
          image: require("../assets/destination_details/madeira.jpg"),
          description: "",
        },
        {
          id: 13,
          name: "Portalegre",
          image: require("../assets/destination_details/portalegre.jpg"),
          description: "",
        },
        {
          id: 14,
          name: "Porto",
          image: require("../assets/destination_details/porto.jpg"),
          description: "",
        },
        {
          id: 15,
          name: "Santarém",
          image: require("../assets/destination_details/santarem.jpg"),
          description: "",
        },
        {
          id: 16,
          name: "Setúbal",
          image: require("../assets/destination_details/setubal.jpg"),
          description: "",
        },
        {
          id: 17,
          name: "Viana do Castelo",
          image: require("../assets/destination_details/viana.jpg"),
          description: "",
        },
        {
          id: 18,
          name: "Vila Real",
          image: require("../assets/destination_details/vila_real.jpg"),
          description: "",
        },
        {
          id: 19,
          name: "Viseu",
          image: require("../assets/destination_details/viseu.jpg"),
          description: "",
        },
      ],
    }
  },
  mounted() {
    this.districts.find((element) => {
      if (element.name === this.destinationName) {
        this.getDescription(element.name)
        this.destinationImage = element.image
        element.description = this.$t("destination_details.faro")
      }
    })
  },
  methods: {
    /* need improvment.. */
    getDescription(n) {
      const destinationDetails = {
        Aveiro: "aveiro",
        Açores: "acores",
        Beja: "beja",
        Braga: "braga",
        Bragança: "braganca",
        "Castelo Branco": "castelo_branco",
        Coimbra: "coimbra",
        Évora: "evora",
        Faro: "faro",
        Guarda: "guarda",
        Leiria: "leiria",
        Lisboa: "lisboa",
        Madeira: "madeira",
        Portalegre: "portalegre",
        Porto: "porto",
        Santarém: "santarem",
        Setúbal: "setubal",
        "Viana do Castelo": "viana",
        "Vila Real": "vila_real",
        Viseu: "viseu",
      }

      this.destinationDescription = this.$t( `destination_details.${destinationDetails[n]}` )
      return this.destinationDescription
    },
  },
  components: {
    AppBar,
    Footer,
    DestinationMap,
  },
}
</script>

<style scoped>
/* * {
    outline: solid 0.15rem rgba(18, 243, 37, 0.5);
} */
</style>