// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'mainTheme',
    themes: {
      mainTheme: {
        dark: false,
        colors: {
          background: '#FFFFFF',
          surface: '#FFFFFF',
          primary: '#1E6892',
          'primary-darken-1': '#0F4C75',
          'primary-darken-2': '#1d3557',
          secondary: '#0F4C75',
          'secondary-darken-1': '#018786',
          'dark-gray-1': '#002b2b',
          error: '#ff726f',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      }
    }
  },
})
export default vuetify;