<template>
  <h3>{{ $t("business_form.searchPlace") }}</h3>

  <!-- text input -->
  <v-text-field
    class="mt-4 text-white"
    variant="outlined"
    :density="$vuetify.display.xs ? 'comfortable' : 'default'"
    prepend-inner-icon="mdi-map-marker"
    :label="$t('business_form.search')"
    type="text"
    clearable
    v-model="searchText"
    @input="fetchSuggestions"
  />
  <!-- Search results -->

  <v-container style="padding-top: 0">
    <v-list v-if="searchText != null && searchText != '' && suggestions != null && suggestions != ''" max-height="300px">
      <v-list-item v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectSuggestion(suggestion)">
        <v-list-item-title>{{ suggestion.display_name }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list v-if="searchText != null && searchText != '' && suggestions != null && suggestions != '' && $vuetify.display.xs">
      <v-list-item v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectSuggestion(suggestion)">
        <v-list-item-title>{{ suggestion.display_name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-container>

  <div :style="$vuetify.display.xs ? 'height: 37vh; width:auto;' : 'height: 40vh; width:auto;'">
    <l-map :zoom="$vuetify.display.xs ? '2' : '3'" :center="centerMap">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
      <l-control-layers />
      <l-marker :lat-lng="markerLatLng" draggable @dragend="updateMarkerPosition">
        <l-tooltip>
          <v-col v-if="lat !== null && lng !== null">
            <h4>{{ $t("form_map.coordinates") }}:</h4>
            <div>{{ markerLatLng }}</div>
          </v-col>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
  <v-container>
    <v-row v-if="lat !== null && lng !== null" class="text-center">
      <!-- sm and up -->
      <v-row v-if="$vuetify.display.smAndUp" class="mt-6 mb-6">
        <v-col v-if="suggestionSelected != ''">
          <h4>{{ $t("form_map.selected") }}:</h4>
          <div class="ml-2">{{ suggestionSelected }}</div>
        </v-col>
        <v-col>
          <h4>Latitude:</h4>
          <div>{{ markerLatLng[0] }}</div>
        </v-col>
        <v-col>
          <h4>Longitude:</h4>
          <div>{{ markerLatLng[1] }}</div>
        </v-col>
      </v-row>
      <!-- mobile -->
      <v-col v-if="$vuetify.display.xs" class="d-block">
        <v-col v-if="suggestionSelected != ''">
          <h4>{{ $t("form_map.selected") }}:</h4>
          <div class="ml-2">{{ suggestionSelected }}</div>
        </v-col>
        <div>
          <h4>{{ $t("form_map.coordinates") }}:</h4>
          <div class="ma-2">Latitude:{{ markerLatLng[0] }}</div>
          <div class="ma-2">Longitude:{{ markerLatLng[1] }}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { LMap, LIcon, LTileLayer, LMarker, LControlLayers, LTooltip, LPopup, LPolyline, LPolygon, LRectangle } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { reactive } from "vue";

// create a reactive cache object
const cache = reactive({});
export default {
  data() {
    return {
      markerLatLng: [0, 0],
      lat: "0",
      lng: "0",
      searchQuery: "",
      suggestions: [],
      suggestionSelected: "",
      showSuggestions: false,
      markerText: "",
      searchText: "",
      isLoading: false,
      menu: false,
      noDataText: "Type at least 3 characters to search for a location",
    };
  },
  props: {
    localSelected: {
      type: String,
    },
    latitudeSelected: {
      type: String,
    },
    longitudeSelected: {
      type: String,
    },
  },
  computed: {
    centerMap() {
      return this.markerLatLng;
    },
  },
  methods: {
    updateMarkerPosition(event) {
      this.suggestionSelected = "";
      this.lat = event.target._latlng.lat;
      this.lng = event.target._latlng.lng;

      // Update center of the map with the new marker coordinates
      this.markerLatLng = [this.lat, this.lng];

      this.$emit("local-selected", this.suggestionSelected);
      this.$emit("latitude-selected", this.markerLatLng[0]);
      this.$emit("longitude-selected", this.markerLatLng[1]);
    },
    fetchSuggestions() {
      if (this.searchText !== "" && this.searchText.length > 3) {
        // check if the search text is already in the cache
        if (cache[this.searchText]) {
          // use the cached results if available
          this.suggestions = cache[this.searchText];
          /* console.log(
            "the request made is in cache, query as already been used before"
          ); */
        } else {
          // make a new API request if the search text is not in the cache
          // using nominatim.travizco.com
          setTimeout(() => {
            const url = `https://nominatim.travizco.com/search?q=${encodeURIComponent(this.searchText)}&format=jsonv2`;
            fetch(url)
              .then((response) => response.json())
              .then((data) => {
                //console.log(data);
                cache[this.searchText] = data;
                this.suggestions = data;
              });
          }, 1000); // delay API request by 1 second
        }
      } else {
        this.suggestions = [];
      }
    },
    selectSuggestion(suggestion) {
      this.searchText = suggestion.display_name;
      this.center = L.latLng(suggestion.lat, suggestion.lon);
      this.markerLatLng = L.latLng(suggestion.lat, suggestion.lon);
      this.markerText = suggestion.display_name;
      this.suggestions = [];
      this.suggestionSelected = suggestion.display_name;
      // Get the latitude and longitude from the selected suggestion
      const { lat, lon } = suggestion;

      // Update the markerLatLng data property with the new latitude and longitude
      this.markerLatLng = [lat, lon];

      //this.latitude = this.markerLatLng[0];
      //this.longitude = this.markerLatLng[1];
      //this.localSelected = this.suggestionSelected;
      //console.log("localSelected", this.suggestionSelected);

      /* emit suggestion and cords as a prop to business view */
      this.$emit("local-selected", this.suggestionSelected);
      this.$emit("latitude-selected", this.markerLatLng[0]);
      this.$emit("longitude-selected", this.markerLatLng[1]);
    },
  },
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
  },
};
</script>

<style scoped>
.v-list-item:hover {
  background-color: #9eb4c4;
}

.v-list-item {
  padding: 10px;
  border-radius: 5px;
}

.v-list {
  border-radius: 5px;
}
</style>
