<template>
  <!-- app bar -->
  <AppBar :show-tabs="showTabs" :isTouchless="touchlessBoolean" :shrink-bar="shrinkBar"></AppBar>

  <!-- md and up header -->
  <v-row v-if="$vuetify.display.mdAndUp" class="text-center header-color">
    <v-col cols="4" class="align-self-start d-flex justify-start">
      <v-img :src="leftWave" :max-width="
        $vuetify.display.md
          ? '80%'
          : $vuetify.display.lg
            ? '75%'
            : $vuetify.display.xl
              ? '75%'
              : ''
      "></v-img>
    </v-col>

    <v-col cols="4" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="center-center">
      <v-img class="mx-auto mt-10" :max-width="$vuetify.display.lgAndUp ? '300px' : '230px'" :src="headerImage"></v-img>
      <v-img class="mx-auto mt-8 business-logo" :max-width="$vuetify.display.lgAndUp ? '300px' : '230px'" :src="businessLogo"></v-img>
      <v-btn color="secondary" elevation="3" class="mt-8 mb-4" variant="outlined" large @click="scrollToElementById('sec_0')">{{ $t("business_form.register_header")
      }}</v-btn>
    </v-col>

    <v-col cols="4" class="align-self-start d-flex justify-end">
      <v-img :src="leftWave" :max-width="
        $vuetify.display.md
          ? '80%'
          : $vuetify.display.lg
            ? '75%'
            : $vuetify.display.xl
              ? '75%'
              : ''
      " style="transform: scaleX(-1)"></v-img>
    </v-col>
  </v-row>

  <!--sm header -->
  <v-row v-if="$vuetify.display.sm" class="text-center header-color">
    <!-- waves sm -->
    <v-col cols="4" class="align-self-start d-flex justify-start">
      <v-img :src="leftWave" :max-width="
        $vuetify.display.lg ? '75%' : $vuetify.display.xl ? '75%' : ''
      "></v-img>
    </v-col>

    <v-col cols="4">
      <v-img class="mx-auto" contain max-width="200px" :src="headerImage"></v-img>
      <v-img max-width="250px" class="mx-auto business-logo mt-3" contain :src="require('../assets/business/blue_business.svg')">
      </v-img>
      <!-- dialog with form mobile -->
      <v-dialog v-model="dialog" max-height="75vh">
        <template v-slot:activator="{ props }">
          <v-btn color="secondary" elevation="2" class="mt-3" variant="outlined" v-bind="props" size="small">
            {{ $t("business_form.register_header") }}</v-btn>
        </template>
        <v-card style="background-color: #013358; overflow: hidden" class="pa-3 text-white">
          <div class="d-flex justify-space-between align-center" style="overflow-y: none">
            <div class="align-center ml-1 d-flex" style="height: fit-content">
              <v-card-title class="headline font-weight-bold">{{
                $t("business_form.register_title")
              }}</v-card-title>
              <v-tooltip location="bottom" :text="$t('business_form.tooltip')">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#fff">mdi-information </v-icon>
                </template>
              </v-tooltip>
            </div>
            <div>
              <v-icon size="large" @click="dialog = false">mdi-close</v-icon>
            </div>
          </div>

          <!-- Business form sm-->
          <BusinessForm></BusinessForm>
        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="4" class="align-self-start d-flex justify-end">
      <v-img :src="leftWave" :max-width="
        $vuetify.display.lg ? '75%' : $vuetify.display.xl ? '75%' : ''
      " style="transform: scaleX(-1)"></v-img>
    </v-col>
  </v-row>

  <!--xs  header -->
  <v-row v-if="$vuetify.display.xs" class="text-center header-color">
    <!-- waves xs -->
    <v-col cols="6" class="align-self-start">
      <v-img :src="leftWave" :max-height="$vuetify.display.sm ? '65%' : '85%'" :max-width="$vuetify.display.sm ? '65%' : '85%'"></v-img>
    </v-col>

    <v-col cols="6" class="align-self-end d-flex justify-end">
      <v-img :src="leftWave" :max-height="$vuetify.display.sm ? '65%' : '85%'" :max-width="$vuetify.display.sm ? '65%' : '85%'" style="transform: scaleX(-1)"></v-img>
    </v-col>

    <!-- middle section -->
    <v-row :class="{ 'mb-6': $vuetify.display.xs, 'mb-4': $vuetify.display.sm }">
      <v-col cols="3"></v-col>

      <v-col cols="6">
        <v-img class="mx-auto" contain max-width="200px" :src="headerImage"></v-img>
        <v-img max-width="250px" class="mx-auto business-logo mt-3" contain :src="require('../assets/business/blue_business.svg')">
        </v-img>

        <!-- dialog with form mobile -->
        <v-dialog v-model="dialog" max-height="75vh">
          <template v-slot:activator="{ props }">
            <v-btn color="secondary" elevation="2" class="mt-3" variant="outlined" v-bind="props" size="small">
              {{ $t("business_form.register_header") }}</v-btn>
          </template>

          <v-card style="background-color: #013358; overflow: hidden" class="pa-3 text-white">
            <div class="d-flex justify-space-between align-center">
              <div class="align-center ml-1 d-flex" style="height: fit-content">
                <v-card-title class="headline font-weight-bold">{{
                  $t("business_form.register_title")
                }}</v-card-title>
                <v-tooltip location="bottom" :text="$t('business_form.tooltip')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="#fff">mdi-information
                    </v-icon>
                  </template>
                </v-tooltip>
              </div>
              <div>
                <v-icon size="large" @click="dialog = false">mdi-close</v-icon>
              </div>
            </div>

            <!-- Business form xs -->
            <BusinessForm></BusinessForm>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
  </v-row>

  <!-- 2nd section -->
  <v-card variant="flat" :class="{
    'background-gradient-mobile': $vuetify.display.xs || $vuetify.display.sm,
    'background-gradient': $vuetify.display.mdAndUp,
  }">
    <!-- md and up -->
    <div v-if="$vuetify.display.mdAndUp">
      <!-- divider -->
      <v-row class="justify-center align-center mb-2 mt-6">
        <v-col cols="4" class="justify-center align-center">
          <v-divider style="color: white"></v-divider>
        </v-col>
        <v-col cols="3" class="justify-center align-center">
          <v-img :width="
            $vuetify.display.sm
              ? '70px'
              : $vuetify.display.md
                ? '70px'
                : $vuetify.display.lg
                  ? '70px'
                  : '80px'
          " contain :id="'ref_1'" class="mx-auto" style="border-radius: 10px" :src="qrLogo"></v-img>
        </v-col>
        <v-col cols="4" class="justify-center align-center">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <div data-aos="fade-down" data-aos-duration="1500" data-aos-anchor-placement="top-center">
        <!-- title  -->
        <v-card flat color="transparent" class="mt-12 mb-12 text-center font-weight-black text-white justify-center align-center">
          <p class="text-h4" style="text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5)">
            {{ $t("business.findout") }}
          </p>
        </v-card>

        <!-- carousel -->
        <v-row class="text-white ml-12 mr-12 mt-6" style="height: fit-content">
          <v-col cols="4" v-for="slide in carouselSlides" :key="slide.id" style="text-align: -webkit-center">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card color="transparent" class="item-card pa-7" :elevation="hover ? 2 : 0">
                  <v-img max-width="350px" :src="slide.image"></v-img>
                  <p class="justify-center font-weight-medium text-h5 mt-6" style="color: white">
                    {{ slide.title }}
                  </p>
                  <p class="text-body-1 mt-5">{{ slide.description }}</p>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </div>

      <div :id="'sec_0'" class="pt-12"></div>

      <!-- Business form md and up -->
      <BusinessForm></BusinessForm>
    </div>

    <!--xs and sm -->
    <div v-else>
      <!-- divider -->
      <v-row class="justify-center align-center mb-2 mt-4">
        <v-col cols="4" class="justify-center align-center">
          <v-divider style="color: white"></v-divider>
        </v-col>
        <v-col cols="3" class="justify-center align-center">
          <v-img max-width="50px" contain :id="'ref_1'" class="mx-auto" style="border-radius: 10px" :src="qrLogo"></v-img>
        </v-col>
        <v-col cols="4" class="justify-center align-center">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!-- card  title -->
      <v-card flat color="transparent" class="mb-5 text-center text-white">
        <div :class="{
          'text-h6 pl-5 pr-5': $vuetify.display.xs,
          'text-h5 font-weight-bold pt-3 pb-3': $vuetify.display.sm,
        }">
          {{ $t("business.findout") }}
        </div>
      </v-card>

      <!-- carousel  -->
      <Carousel :autoplay="5000" :mouse-drag="true" :itemsToShow="1.8" :wrapAround="true" :transition="500">
        <Slide v-for="slide in carouselSlides" :key="slide">
          <div class="carousel__item align-center text-caption" data-aos="fade-up" data-aos-duration="600" data-aos-anchor-placement="top-center">
            <v-card flat tile color="transparent" class="pa-2" style="text-align: -webkit-center">
              <v-img contain max-width="230px" :src="slide.image"></v-img>
              <div class="d-block mt-5 text-white">
                <div class="text-body-1 font-weight-bold">
                  {{ slide.title }}
                </div>
                <div class="font-weight-light mt-4">
                  {{ slide.description }}
                </div>
              </div>
            </v-card>
          </div>
        </Slide>
      </Carousel>
    </div>
  </v-card>

  <!-- 3rd section -->
  <v-row class="waves justify-center align-end" style="padding-top: 25vh">
    <img ref="ship" :style="
      $vuetify.display.mdAndUp
        ? ' max-height: 40vh'
        : $vuetify.display.sm
          ? 'max-height:30vh'
          : 'max-height:20vh'
    " />
  </v-row>

  <v-row style="height: 5vh; background-color: #d8f4f4"></v-row>
  <Footer :show-business-logo="showBusinessLogo"></Footer>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import AppBar from "../components/AppBar.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import Footer from "../components/Footer.vue";
import BusinessForm from "../components/BusinessForm.vue";

export default {
  name: "business",
  data() {
    return {
      showBusinessLogo: false,
      /* slides */
      carouselSlides: [
        {
          slide: 1,
          image: require("../assets/business/carousel1.svg"),
          title: "",
          description: "",
        },
        {
          slide: 2,
          image: require("../assets/business/carousel2.svg"),
          title: "",
          description: "",
        },
        {
          slide: 3,
          image: require("../assets/business/carousel3.svg"),
          title: "",
          description: "",
        },
      ],
      /* appBar */
      dialog: false,
      touchlessBoolean: true,
      appBar: true,
      showTabs: false,
      shrinkBar: false,
      /* images */
      headerImage: require("../assets/business/business_header.svg"),
      leftWave: require("../assets/business/left_wave.svg"),
      businessLogo: require("../assets/business/blue_business.svg"),
      qrLogo: require("../assets/qrLogo.jpg"),
      appStoreButton: require("../assets/app_store_icon.png"),
      googlePlayButton: require("../assets/google_play_icon.png"),
      whiteTravizco: require("../assets/headerimg.png"),
    };
  },
  methods: {
    scrollToElementById(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
  created() {
    AOS.init();
  },
  mounted() {
    /* pre-load image with the ship */
    const img = new Image();
    img.src = require("../assets/business/ship.png");
    img.onload = () => {
      this.$refs.ship.src = img.src;
    };

    /* add topic translations to the array */
    this.carouselSlides[0].title += this.$t("business.topic1_title");
    this.carouselSlides[0].description += this.$t(
      "business.topic1_description"
    );
    this.carouselSlides[1].title += this.$t("business.topic2_title");
    this.carouselSlides[1].description += this.$t(
      "business.topic2_description"
    );
    this.carouselSlides[2].title += this.$t("business.topic3_title");
    this.carouselSlides[2].description += this.$t(
      "business.topic3_description"
    );
  },
  components: {
    AppBar,
    Carousel,
    Slide,
    BusinessForm,
    Footer,
  },
};
</script>

<style scoped>
/* * {
  outline: solid 0.15rem rgba(18, 243, 37, 0.5);
} */

.header-color {
  background-color: #d8e4ec
}

.carousel__item {
  height: 60vh;
  width: 90%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.4s;
}

.carousel__slide {
  opacity: 0.1;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active~.carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.85);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.85);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.2);
}

.item-card {
  transition: all 0.4s;
}

.item-card:hover {
  transform: translate3D(0, -1px, 0) scale(1.07);
  transition: all 0.4s;
}

.imageExpand:hover {
  transform: scale(1.05);
  transition: all 0.2s ease;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-family: "League Gothic";
}

.underlineText:hover {
  text-decoration: underline;
}

.background-gradient-mobile {
  background: linear-gradient(#d8e4ec, #013358 80%);
  border-radius: 0px;
}

.background-gradient {
  background: linear-gradient(#d8e4ec, #013358 45%);
  border-radius: 0px;
}

.waves {
  background: url("../assets/business/waves.jpg") no-repeat bottom center;
  background-size: cover;
}

.business-logo {
  filter: drop-shadow(1px 1px black);
}

.business-subtitle {
  color: darkslategray;
}
</style>