<template>
    <div style="height: 55vh; position: relative; background-color: #b0d4dc;">
        <l-map ref="map" :zoom="$vuetify.display.xs ? 8 : 9" :center="center" :style="
            isLoading ? ' z-index: -1;' : ''">
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
            <l-control-layers />
            <l-polygon :lat-lngs="district_perimeter"></l-polygon>
        </l-map>
        <div class="progress-overlay" v-if="isLoading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
    </div>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    LPolygon,

} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";


export default {
    name: "destinationMap",
    props: {
        destino: {
            type: String
        }
    },
    data() {
        return {
            isLoading: true,
            center: [0, 0],
            district_marker: {
                lat: "",
                lon: ""
            },
            district_perimeter: [],
            //maxBounds: []
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlLayers,
        LPolygon,
    },
    mounted() {
        this.isLoading = true;
        // Use fetch to get the search results
        setTimeout(() => {

            var searchUrl =
                "https://nominatim.travizco.com/search.php?q=" +
                this.destino +
                "&polygon_geojson=1&format=jsonv2&addressdetails=1&dedupe=1";

            fetch(searchUrl)
                .then(response => response.json())
                .then(data => {

                    const element = data.find(element => element.display_name.includes(this.destino) && element.display_name.includes('Portugal'));
                    if (element) {

                        const geojson = element.geojson;
                        let coordinates = [];

                        // Update center map
                        this.center = [element.lat, element.lon];

                        if (geojson.type === "Polygon") {
                            coordinates = geojson.coordinates[0].map(coords => [coords[1], coords[0]]);
                            this.isLoading = false;
                        } else if (geojson.type === "MultiPolygon") {
                            geojson.coordinates.forEach(polygon => {
                                polygon[0].forEach(coords => {
                                    coordinates.push([coords[1], coords[0]]);
                                });
                            });
                            this.isLoading = false;
                        }
                        this.district_perimeter = coordinates;
                        //const bounds = L.polygon(this.district_perimeter).getBounds();
                        //this.maxBounds = [[bounds.getSouthWest().lat, bounds.getSouthWest().lng], [bounds.getNorthEast().lat, bounds.getNorthEast().lng]];
                    }
                });
        }, 1000);
    },
}
</script>

<style scoped>
.progress-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
</style>