<template>
    <v-container v-animation="bounce" class="pa-3 jingle-container">
        <!-- top wave -->
        <v-row style="height: 42vh ">
            <v-img cover :src="require('../assets/jingle/wave2.svg')">
                <v-row>
                    <v-col xs="5" md="6" lg="6" xl="6">
                        <!-- lyrics am and up -->
                        <div v-if="audioPlaying && $vuetify.display.smAndUp" class="travizco-lyrics-block-container" :style="
                            $vuetify.display.xs
                                ? 'font-size: 12px'
                                : $vuetify.display.sm
                                    ? 'font-size: 12px; padding-left:20px; padding-top:15px; padding-right: 50px'
                                    : $vuetify.display.md
                                        ? 'font-size: 13px; padding:25px'
                                        : $vuetify.display.lg
                                            ? 'font-size: 14px; padding:25px'
                                            : 'font-size: 18px; padding:25px'">
                            <div v-for=" (line, index) in lyrics" :key="index" :class="{ active: activeIndex === index, 'dance': audioPlaying, }">
                                <p v-if="audioPlaying && index == 0">{{ lyrics[0] }}</p>
                                <p v-if="activeIndex >= index && index < 6 && index > 0">{{ line }}</p>
                            </div>
                        </div>

                        <!-- jingle title mobile -->
                        <div class="d-block text-center align-center justify-center ml-4" v-if="$vuetify.display.xs">
                            <div v-if="$vuetify.display.xs" class="font-weight-bold text-white" :style="
                                $vuetify.display.xs
                                    ? 'font-size: 15px; margin-top: 3vh; '
                                    : ' '">
                                {{ $t("jingle") }}
                            </div>

                            <!-- buttons mobile -->
                            <v-btn v-if="!audioPlaying && $vuetify.display.xs" class="text-primary mt-4 mr-4" icon="mdi-play" @click="toggleAudio" :style="
                                $vuetify.display.xs
                                    ? 'margin-left: 5vw'
                                    : 'margin-right: 10px'" :size="$vuetify.display.xs
            ? 'small'
            : $vuetify.display.sm
                ? 'default'
                : $vuetify.display.md
                    ? 'default'
                    : $vuetify.display.lg
                        ? 'large'
                        : 'large'" />
                            <v-btn v-if="audioPlaying && $vuetify.display.xs" class="text-primary mt-4 mr-4" icon="mdi-stop" @click="toggleAudio" :style="
                                $vuetify.display.xs
                                    ? 'margin-left: 5vw'
                                    : 'margin-right: 10px'" :size="$vuetify.display.xs
            ? 'small'
            : $vuetify.display.sm
                ? 'default'
                : $vuetify.display.md
                    ? 'default'
                    : $vuetify.display.lg
                        ? 'large'
                        : 'large'" />
                        </div>

                    </v-col>

                    <v-col xs="7" md="6" lg="6" xl="6" class="text-center align-center pt-12" :style="$vuetify.display.xs
                        ? 'display: block; margin-top: 10vh;padding-right:5vw;'
                        : 'display: flex; margin-top: 20vh'">

                        <!-- listen jingle title -->
                        <div v-if="$vuetify.display.smAndUp" class="font-weight-bold" :style="
                            $vuetify.display.xs
                                ? 'font-size: 15px; margin-bottom: 2vh; '
                                : $vuetify.display.sm
                                    ? 'font-size: 18px; padding-right: 16px; '
                                    : $vuetify.display.md
                                        ? 'font-size: 20px; margin-left: 5vw; padding-right: 16px; '
                                        : $vuetify.display.lg
                                            ? 'font-size: 25px; margin-left:6vw; padding-right: 16px; '
                                            : 'font-size: 28px; margin-left:10vw; padding-right: 16px; '">
                            {{ $t("jingle") }}
                        </div>

                        <!-- lyrics mobile -->
                        <div v-if="audioPlaying && $vuetify.display.xs" class="travizco-lyrics-mobile " :style="
                            $vuetify.display.xs
                                ? 'font-size: 11px; color:black'
                                : 'font-size: 12px'">
                            <div v-for=" (line, index) in lyrics" :key="index" :class="{ active: activeIndex === index, 'dance': audioPlaying }">
                                <p v-if="audioPlaying && index == 0">{{ lyrics[0] }}</p>
                                <p v-if="activeIndex >= index && index < 6 && index > 0">{{ line }}</p>
                            </div>
                        </div>

                        <!-- buttons play and stop pc -->
                        <v-btn v-if="!audioPlaying && $vuetify.display.smAndUp" class="text-white bg-primary mr-5" icon="mdi-play" @click="toggleAudio" :style="
                            $vuetify.display.xs
                                ? 'margin-left: 5vw'
                                : 'margin-right: 10px'" :size="$vuetify.display.xs
            ? 'small'
            : $vuetify.display.sm
                ? 'default'
                : $vuetify.display.md
                    ? 'default'
                    : $vuetify.display.lg
                        ? 'large'
                        : 'large'" />
                        <v-btn v-if="audioPlaying && $vuetify.display.smAndUp" class="text-white bg-primary mr-5" icon="mdi-stop" @click="toggleAudio" :style="
                            $vuetify.display.xs
                                ? 'margin-left: 5vw'
                                : 'margin-right: 10px'" :size="$vuetify.display.xs
            ? 'small'
            : $vuetify.display.sm
                ? 'default'
                : $vuetify.display.md
                    ? 'default'
                    : $vuetify.display.lg
                        ? 'large'
                        : 'large'" />
                    </v-col>
                </v-row>

                <audio ref="audioPlayer" :src="audioSrc" @timeupdate="updateLyrics"></audio>

            </v-img>
        </v-row>

        <!-- bottom wave -->
        <v-row style="height: 25vh; background-color: #bbdefb; ">
            <v-img cover :src="require('../assets/jingle/wave1.svg')">
                <v-row>
                    <v-col cols="7">
                        <img v-if="!audioPlaying" :class="{ 'dance': audioPlaying }" class="mt-1" :src="listener" :style="
                            $vuetify.display.xs
                                ? 'margin-left:10vw; height: 31.5vh;'
                                : 'margin-left:14vw; height: 31.5vh;'">

                        <img v-else :class="{ 'dance': audioPlaying }" class="mt-1" :src="listener_smile" :style="
                            $vuetify.display.xs
                                ? 'margin-left:10vw; height: 30vh;'
                                : 'margin-left:14vw; height: 30vh;'">

                    </v-col>
                    <v-col cols="5" class="d-block text-center">
                        <!-- tra viz co -->
                        <div v-if="audioPlaying" class="travizco-lyrics-flex-container pt-4" style="  font-family: Droidiga">
                            <div v-for=" (line, index) in lyrics" :key="index" v-bind:class="{ active: activeIndex === index }">
                                <div class="mb-2" v-if="activeIndex >= index && index >= 6 && index <= 8" :style="
                                    $vuetify.display.xs
                                        ? 'font-size: 14px; margin-top: 16vh'
                                        : $vuetify.display.sm
                                            ? 'font-size: 15px; margin-top: 15vh'
                                            : $vuetify.display.md
                                                ? 'font-size: 20px; margin-top:14vh'
                                                : $vuetify.display.lg
                                                    ? 'font-size: 25px; margin-top:12vh'
                                                    : 'font-size: 30px; margin-top:12vh'">
                                    <p>{{ line }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- escreve-se com z -->
                        <div v-if="audioPlaying" class=" text-white text-body-2 font-weight-medium">
                            <div v-for="(line, index) in lyrics" :key="index" v-bind:class="{ active: activeIndex === index }">
                                <div v-if="index == 9 && activeIndex >= index" :style="
                                    $vuetify.display.xs
                                        ? 'font-size: 7px'
                                        : $vuetify.display.sm
                                            ? 'font-size: 10px;'
                                            : $vuetify.display.md
                                                ? 'font-size: 11px; '
                                                : $vuetify.display.lg
                                                    ? 'font-size: 13px; '
                                                    : 'font-size: 13px; '">
                                    <p>{{ line }}</p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Jingle",
    data() {
        return {
            listener: require("../assets/jingle/listener.png"),
            listener_smile: require("../assets/jingle/listener_smile.png"),
            audioPlaying: false,
            audioSrc: require("../assets/jingle/jingle.mp3"),
            audioTime: '0:00',
            lyrics: [
                "Oh Maria tu vê-me isto",
                "Instalei o Travizco",
                "Praias rotas e marisco",
                "Tudo no Travizco",
                "Dentro de uma aplicação um guia turístico",
                "Viajar é bem mais fácil mesmo sem registo",
                "Tra",
                "viz",
                "co",
                "Escreve-se com Z",
            ],
            timecodes: [0, 1, 3, 4, 6, 9, 11, 12, 13, 14, 15],
            activeIndex: 0,
        }
    },
    methods: {
        toggleAudio() {
            if (this.audioPlaying) {
                this.audioPlaying = false;
                this.$refs.audioPlayer.currentTime = 0;
                this.$refs.audioPlayer.pause();
                this.$refs.audioPlayer.removeEventListener('ended', this.stopDanceAnimation);
            } else {
                this.audioPlaying = true;
                this.$refs.audioPlayer.play();
                this.$refs.audioPlayer.addEventListener('ended', this.stopDanceAnimation)
            }
        },
        updateLyrics() {
            // get the current playback time
            const currentTime = Math.floor(this.$refs.audioPlayer.currentTime);

            // find the index of the line to display based on the current time
            const index = this.timecodes.findIndex(time => time > currentTime) - 1;

            // update the activeIndex to show the current line
            this.activeIndex = index >= 0 ? index : 0;
        },
        stopDanceAnimation() {
            setTimeout(() => {
                this.audioPlaying = false;
            }, 1000);
        }
    }
}
</script>

<style scoped>
/* * {
    outline: solid 0.25rem rgba(18, 243, 37, 0.5);
} */

.jingle-container {
    background-color: #bbdefb;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    width: 68vw;
    margin-top: 80px
}

.travizco-lyrics-block-container {
    display: block;
    color: #fff;
    font-weight: 400;
}

.travizco-lyrics-mobile {
    display: block;
    color: black;
    font-weight: 500;
}

.travizco-lyrics-flex-container {
    display: flex;
    text-align: center;
    justify-content: center;
    justify-self: center;
    color: #fff;
}

.active {
    color: #fff;
    transform: scaleX(1.05);
    font-weight: 900;
}

.dance {
    position: relative;
    animation: shake 1.5s infinite;
}

@keyframes shake {
    0% {
        transform: rotate(1deg) translateX(1px) translateY(-2px);
    }

    25% {
        transform: rotate(-1deg) translateX(-1px) translateY(2px);
    }

    50% {
        transform: rotate(1deg) translateX(1px) translateY(-2px);
    }

    75% {
        transform: rotate(-1deg) translateX(-1px) translateY(2px);
    }

    100% {
        transform: rotate(1deg) translateX(1px) translateY(-2px);
    }
}
</style>