export default {
  "common": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bem-vindo a ", _interpolate(_named("municipal"))])},
    "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos & Condições"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler mais"])},
    "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler menos"])},
    "AppDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarrega a aplicação para ter acesso completo"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A carregar informação, aguarde por favor..."])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segue-nos"])}
  },
  "notFound": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página não encontrada"])}
  },
  "tabname": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo"])},
    "poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontos de Interesse"])},
    "routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotas"])},
    "destinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinos"])},
    "qrscanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitor de Qr"])},
    "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ser Parceiro"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adira"])}
  },
  "home": {
    "travizco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal numa app, uma app para o mundo"])},
    "poiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontos de Interesse"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobre "])},
    "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aventura-te com as nossas "])},
    "routesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotas"])},
    "poiSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na página inicial mostramos pontos de interesse e serviços locais próximos de ti, destacando o município onde te encontras."])},
    "routesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora as nossas rotas turísticas destacadas em diferentes cores e saiba quais os pontos de interesse que inclui."])},
    "routesTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create distinct routes between different points of interest to discover them in an orderly way"])},
    "routesTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each route contains a specific order of points of interest that can be found and consulted as you travel with the app"])},
    "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtém mais informação utilizando o "])},
    "qrscannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitor de QR"])},
    "qrscannerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use o leitor de QR num ponto de interesse para obter mais informações sobre o mesmo."])},
    "dare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atreve-te a viajar de uma nova forma com o"])},
    "explorerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador"])},
    "explorerSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com o modo explorador podes descobrir pontos de interesse ao teu redor enquanto viajas em tempo real, com o telemóvel ligado ou desligado."])},
    "qrscannerTopic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se um ponto de interesse estiver acompanhado de um código QR, a aplicação permite que este seja consultado diretamente através do respetivo scan"])},
    "qrscannerTopic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao verificar um ponto de interesse por meio do QR Scanner, é possivel obter todas as informações correspondentes ao local analisado"])},
    "footerDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travizco administrado pela Impactzero, Software lda., tem por objetivo descobrir o património cultural e histórico de uma determinada localidade."])},
    "footerDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes, porém, de qualquer consulta ao APP, deve-se ler os Termos de Uso e Política de Tratamento"])},
    "footerDescription3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A permanência e a utilização da APP pelo visitante/utilizador implicam a aceitação dos seus termos e condições, sem qualquer reserva, pelo que se com estes não concordar deve imediatamente cessar a navegação."])},
    "footerDescription4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Travizco, reserva-se o direito de, a todo o tempo, alterar a apresentação e configuração da APP, bem como dos seus termos e condições de utilização e da política de tratamento de dados pessoais, devendo, assim, o visitante/utilizador sempre que aceder a APP, consultar previamente estes termos e condições."])},
    "footerDescription5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações complementares poderão ser obtidas junto da Impactzero, Software lda. através do e-mail support", "@", "travizco.com."])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna-te nosso parceiro com o Travizco Business"])},
    "advertBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promove o teu negócio,"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna-te nosso parceiro"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regista-te!"])}
  },
  "giveaway_popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passatempo fotográfico"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobre os prémios do nosso passatempo fotográfico e como participar"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até dia 14/11"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar"])},
    "contest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passatempo"])}
  },
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale connosco"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "business": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo ao"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seja nosso parceiro."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se aqui"])},
    "findout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra como ampliar o seu negócio connosco"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferecemos:"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtem mais Visibilidade e Credibilidade"])},
    "topic1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efetue Registo"])},
    "topic1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registe-se no TRAVIZCO BUSINESS e adicione Pontos de Interesse, Serviços e Rotas temáticas para dar mais visibilidade à sua empresa."])},
    "topic2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potencie o seu Negócio"])},
    "topic2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otimize o seu negócio de forma creativa e original, publicidade à medida para melhor divulgar a sua empresa."])},
    "topic3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percursos Personalizados"])},
    "topic3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilizamos percursos turísticos em 30 idiomas diferentes em modo automático mesmo com o telefone bloqueado. Contacte-nos para promover as suas ideias."])}
  },
  "destinations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinos"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Destinos"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita"])},
    "atractVisitants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["que atraem milhões de viajantes ao redor do mundo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguns exemplos incluem cidades históricas como Porto e Lisboa, paisagens naturais deslumbrantes como a Costa Vicentina e montanhas da Ilha da Madeira, e praias de areia vulcanica podem ser encontradas nos Açores! Esses lugares oferecem uma ampla variedade de atividades, desde passeios culturais e históricos até aventuras ao ar livre e relaxamento na praia. Além disso, muitos destinos turísticos possuem uma rica culinária e vida noturna animada para desfrutar. Ao planear sua próxima viagem, considere um dos muitos destinos turísticos incríveis disponiveis no Travizco!"])}
  },
  "blog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita o nosso blog"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra as novidades mais sobre os seus destinos preferidos"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitar"])}
  },
  "cookies": {
    "accept_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar tudo"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Opções"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de segmentação são utilizados para identificar visitantes entre diferentes sites, por exemplo. parceiros de conteúdo, redes de banner. Estes cookies podem ser utilizados por empresas para construir um perfil de interesses dos visitantes ou mostrar anúncios relevantes noutro websites."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este website utiliza cookies"])},
    "textToShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este website usa cookies para melhorar a experiência do usuário. Ao utilizar o nosso website, estará a concordar com todos os cookies de acordo com nossa Política de Cookies."])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Cookies"])},
    "about_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies são pequenos arquivos de texto colocados no seu computador pelos sites que consulta. Os sites utilizam cookies para ajudar os usuários a navegar com eficiência e executar certas funções. Os cookies necessários para o funcionamento adequado do site podem ser configurados sem a permissão do utilizador Todos os outros cookies precisam ser aprovados antes de serem configurados no navegador.O utilizador pode alterar seu consentimento para o uso de cookies a qualquer momento na nossa página de Política de Privacidade."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cookies"])},
    "save_exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar e Sair"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusar"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcionamento"])}
  },
  "jingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouve o nosso Jingle!"])},
  "business_form": {
    "register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registe-se aqui"])},
    "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar Local"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
    "poi_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria Ponto de Interesse"])},
    "service_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria Serviço"])},
    "service_subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Categoria Serviço"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número telemóvel"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morada Completa"])},
    "colaborator_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica-se como colaborador, representante ou responsável do local que pretende adicionar?"])},
    "colaborator_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual é a sua função"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
    "aditional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas/Comentários adicionais"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha o formulário para entrar em contacto via email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "mandatory_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo Obrigatório"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail com formato inválido"])},
    "invalid_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telemóvel inválido"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])},
    "searchPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquise pelo local a adicionar:"])}
  },
  "form_map": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected place"])}
  },
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "destination_details": {
    "aveiro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveiro é cidade e pertence à Região do Centro. Importante centro urbano, ferroviário, portuário, com universidades e muito turístico. A Veneza portuguesa, como é apelidada, com os seus moliceiros, não há nada de mais belo! Cidade na costa oeste fundada ao lado de uma lagoa, a Ria de Aveiro, com o sal e o comércio naval como mais valias. Os Moliceiros, barcos com cores atraentes, utilizados tradicionalmente para a colheita de algas, levam os turistas pelos canais, numa viagem agradável. As suas praias são um atrativo, com galardão de Qualidade de Ouro. Desde o património edificado com os seus painéis azulejares de rara beleza, passando pelos museus e pelos diferentes parques, faça uma pausa para apreciar os famosos ovos moles! Com 19 municípios, tem uma gastronomia muito rica, as ostras que vêm da Ria…não perca nem um pouco de toda esta riqueza, de tanta maravilha. O Travizco será o seu companheiro nesta travessia!"])},
    "acores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os Açores é um arquipélago formado por 9 ilhas. Na Ilha do Pico, os vinhedos são plantados em campos de lava que parecem labirintos. O ponto mais alto é o Pico com 2352 metros. A origem vulcânica dos Açores tem mais expressão na ilha de São Miguel, no Vale das Furnas, onde sai o “Cozido das Furnas”. É a maior e a mais povoada, com a Lagoa das Sete Cidades e do Fogo. A beleza e a preservação da paisagem natural verificam-se em todas elas. A Graciosa é considerada Reserva Mundial da Biosfera pela UNESCO, conhecida como a Ilha Branca. Com o TRAVIZCO, visite as plantações de Chá, pois são as mais antigas e únicas na Europa, formando uma paisagem incrível!"])},
    "beja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beja, capital do baixo Alentejo, capital do maior distrito português em área, é uma cidade com cerca de 36 mil habitantes, fácil de explorar a pé ou de bicicleta e onde o tempo é relativo… No tempo dos romanos era Pax Júlia e para relembrar esses tempos, realiza-se na Primavera durante 3 dias a Beja Romana onde se recorda a sua grandeza e imponência. Território dividido em 14 concelhos, de planícies verdejantes que se tornam douradas durante o verão, é um território de beleza única que vai da Espanha até ao mar. Os seus vinhos são dos melhores de Portugal, a sua gastronomia é simples, criativa, mas extremamente rica nos sabores. Um bom exemplo dessa criatividade é a carne de porco à alentejana uma simbiose perfeita entre a terra e o mar. Explore Beja com o TRAVIZCO e deixe-se levar com calma, maravilhe-se pelo Alqueva e se poder aprecie o belo Cante Alentejano."])},
    "braga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade dos Arcebispos com mais de 2000 anos de existência, Braga é a cidade mais antiga de Portugal, denominada pelos Romanos de “Bracara Augusta. É conhecida pelo seu Turismo Religioso e Cultural, com grande afluência de pessoas na Páscoa. Os seus Santuários são bem conhecidos tais como o Bom Jesus do Monte e o Sameiro. São 14 os municípios de Braga, todos com uma grande importância para o crescimento do território, também em termos turísticos. Bastante acidentado, com serras altas, com municípios na área do Parque Nacional Peneda Gerês (1545 metros), o único em Portugal. A gastronomia e os vinhos da região, atraem cada vez mais visitantes e onde a população jovem encontra aqui uma boa qualidade de vida."])},
    "braganca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bragança é uma cidade raiana, sede e distrito situado no Nordeste de Portugal. São 12 os seus municípios, composto por duas regiões distintas, as de maior altitude, a Norte, que constituem a Terra Fria Transmontana e a Sul, a Terra Quente Transmontana, com clima mais suave. No geral, o distrito é montanhoso. A Serra da Nogueira, com 1320 metros, separa os vales do Tuela e do Sabor. A maior de todas é a Serra de Montesinho, com imensos trilhos, que sobe até aos 1400 metros e que se prolonga pelo território espanhol. Conta-nos a história que foi em Bragança que aconteceu o casamento secreto entre D. Pedro e Inês de Castro. Percorra o centro histórico a pé e não perca a beleza dos seus monumentos! Visite os concelhos vizinhos, aprecie a paisagem e com o Travizco, descubra onde comer o que de melhor há por estas bandas!"])},
    "castelo_branco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castelo Branco localiza-se no interior centro do país, na Beira Baixa. É capital de distritos e são onze os municípios que lhe pertencem. Das muralhas do castelo, pode ver parte da cidade. O bordado ganhou grande importância na região com a cultura do linho como tradição. Os produtos da terra são de grande impacto, como o azeite e o vinho. Da pastorícia, produz-se o queijo, alguns dos mais apreciados do país. A produção de cereja, ginjas e pêssegos contribuem para o desenvolvimento económico do concelho. O marco geodésico que assinala o centro de Portugal tem uma vista magnífica! A arquitetura religiosa e militar encontra-se espalhada pelos vários concelhos. Com TRAVIZCO, venha à terra dos Albicastrenses!"])},
    "coimbra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coimbra situa-se no centro de Portugal, capital de distrito. Cidade dos estudantes que já foi capital do país, com o Mondego a seus pés. Um conjunto arquitetónico que integra a Universidade de Coimbra são Património Mundial da Humanidade. Cidade dos amores e das tradições académicas, residências estudantis e repúblicas, lugar de tertúlias onde vários escritores se formaram. A beleza da Biblioteca Joanina é ímpar! Tascas e petiscos, doces conventuais, pratos típicos que se encontram em todos os seus 17 municípios. Praias de areia dourada, serras altas, aldeias de xisto e Conímbriga, são ótimas razões para uma visita. Se nunca viu plantações de arroz, o Travizco indica-lhe onde as encontrar!"])},
    "evora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évora, cidade que respira história de arte portuguesa, o seu centro histórico foi declarado Património Mundial pela UNESCO. Praça do Giraldo, o Templo de Diana, a Capela dos Ossos, a Sé, a Igreja de São Francisco… nada como caminhar ao acaso com o TRAVIZCO no bolso para descobrir esta maravilhosa cidade. Mas não é só em Évora onde reside a beleza e o encanto, não perca a oportunidade de conhecer o distrito onde certamente ficará rendido ao seu património e belezas naturais. A paisagem é tipicamente alentejana, plana de extensos olivais e vinhedos, árida nos meses mais secos. Do alto de Monsaraz contempla-se a vastidão da albufeira do Alqueva, o maior lago artificial da Europa. No sopé da serra de Borba encontra-se Vila Viçosa caracterizada por ambiente de realeza distinto, em Arraiolos venha conhecer os tapetes, em Portel visite o imponente castelo, marque uma visita nas Grutas do Escoural em Montemor-o-Novo… tanto há para dizer mas o melhor mesmo é deixar-se levar pelo TRAVIZCO numa jornada pelo Alentejo Central, território de beleza única, rico em cultura, tradição e gastronomia."])},
    "faro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faro é a capital do Algarve, região que dispensa apresentações. Território conhecido pelas suas praias de areia dourada, pelo barrocal de onde se destaca o seu delicioso mel, o medronho e a cortiça. Por fim a serra, que ocupa 50% da região, terra de grande beleza cujo pico mais elevado se encontra na serra de Monchique a 902m de altitude. O seu clima temperado de características mediterrânicas, banhado por mais de 3000h de sol por ano, faz do Algarve um dos destinos turísticos mais populares do mundo e de elevada procura para a prática do Golfe. O Algarve é composto por 16 concelhos, cada um com identidade vincada, cheios de histórias e de paisagens de beleza ímpar. Perca-se no Algarve e encontre o rumo com o TRAVIZCO."])},
    "guarda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O distrito da Guarda situa-se no centro da região beirã, entre o Planalto Guarda-Sabugal e a Serra da Estrela. É nas suas muralhas que a história se conta há mais de 800 anos. Local de saúde e bem-estar pelo ar puro que aqui se respira. É a região dos 5 F´s: Forte pelo granito, Farta pelos vales férteis, Fria pelo clima, Fiel pelas suas gentes e Formosa por tudo o que possui. Os passadiços do Mondego convidam ao passeio, a começar junto à Barragem do Caldeirão, com pontes suspensas e paisagens incríveis! São 14 os municípios com lugares de rara beleza! Pinturas rupestres, castelos e gastronomia serrana de conforto, já são razões de sobra para cá vir! Pegue em si e no Travizco e aventure-se!"])},
    "leiria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiria situa-se na Região Centro Litoral, é capital e sede do distrito. A cidade cresceu junto ao castelo, perto do rio Lis. Possui 16 municípios com monumentos de beleza extraordinária. As matas, os pinhais sem fim, praias a perder de vista, arquitetura religiosa e civil, as suas termas e tradições populares são todos de grande importância!…a gastronomia, entre serras e praias, é muito variada, com pratos portugueses de peixe e de carne, acompanhados do vinho das Encostas de Aire DOC. A cerâmica e o vidro são uma referência. Outros castelos e monumentos merecem uma visita obrigatória. Se gosta de chocolate, não perca o festival da região. O TRAVIZCO estará consigo para lhe indicar o caminho!  "])},
    "lisboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisboa é a capital do país, capital de distrito com o Tejo a ver partir as suas naus para o para os descobrimentos há mais de 500 anos. Lisboa é a cidade das 7 colinas, com os seus castelos e igrejas imponentes, com uma beleza incomparável. Da tragédia se refez, com monumentos arquitetónicos de grande valor, bairros emblemáticos que não deixaram de ter vida ao longo dos anos. Com 18 municípios, vilas e cidades com as suas próprias identidades, com arenas e tradições taurinas, vinhedos e zonas agrícolas com as hortas que abastecem a grande Lisboa. As praias e as vilas de pescadores que se tornaram locais turísticos, sem perderem o que mais as caraterizam. A gastronomia é muito mais do que se possa escrever, é estar para provar. O TRAVIZCO encontra esse lugar!"])},
    "madeira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Madeira é um arquipélago (com Porto Santo e Ilhas Desertas), com Funchal como capital. Centrado no turismo, vêm visitantes dos quatro cantos do mundo, pelo clima com temperaturas amenas e as paisagens com montanhas e vales encantadores. Nos seus 11 concelhos, procure os miradouros e admire tudo o que vê (Cabo Girão e o skywalk). Não deixe de subir ao Pico do Areeiro a mais de 1800m de altitude. Piscinas naturais, casas de Santana, localidades pitorescas e piscatórias, um mundo a descobrir com o TRAVIZCO! Suba no teleférico, desça nos Carros de Cesto e vá ao Mercado dos Lavradores. Na gastronomia, o peixe fresco, a espetada, bolo do caco, Vinho da Madeira e a Poncha…aprovadíssimo!"])},
    "portalegre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portalegre fica situado no Norte Alentejano, em pleno Parque Natural da Serra de São Mamede. Cidade cercada por serras, penhascos e planície, com paisagens de grande beleza. A manufatura de tapeçarias tornou-se um ex-líbris da cidade e em toda a região se encontram peças de artesanato. São muito os produtos regionais, enchidos, fruta, mel e queijos que tornam a gastronomia riquíssima, com os vinhos e as cervejas artesanais. Os doces conventuais que se encontram por todo o território, são um manjar do Deuses. As coudelarias onde se criam os melhores cavalos Lusitanos, são terras de lendas e tradições, festas e romarias que até tem uma “Sintra do Alentejo” pela beleza dos jardins, com alguns municípios de Portalegre a serem os mais fortificados de Europa. Com o TRAVIZCO, venha conhecê-los!"])},
    "porto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cidade do Porto é distrito e sede do distrito do Porto localizado na Região Norte, no Douro Litoral. Agrupa 18 municípios onde se situam a maioria das pequenas e médias empresas do país. O Porto de Leixões e o aeroporto Francisco Sá Carneiro são duas grandes e importantes infraestruturas da região. Porto é a cidade que deu o nome a Portugal quando se designava de Portus Cale. É mundialmente conhecido pelo seu vinho (vinho do Porto), pelas cores das casas da Ribeira e pelo centro histórico, que é património Mundial. A Francesinha é mais famosa aqui do que em França, mas as Tripas à moda do Porto são portuenses! Cidade Invicta (invencível) e região de grande beleza, tanto natural como arquitetónica, todos os municípios mantêm a sua identidade própria. Os barcos rabelos que pelo Douro navegam, mostram as pontes e as margens do Douro. Não perca mais tempo e com o Travizco encontrará todos os tesouros escondidos por aí!"])},
    "santarem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santarém pertence à região do Ribatejo, capital e sede do distrito. As Serras de Aire e Candeeiros são imponentes. Todo o património arquitetónico da região é de grande valor. Os Templários deixaram grande parte dele, como castelos e igrejas. Vá de barco ao castelo que fica no meio do rio. O Santuário de Fátima atrai milhares de peregrinos durante todo o ano, com o recinto a receber milhares de pessoas. Conheça a terra dos fenómenos, terras de vinhos e do melão, assim como a cidade onde viveu Luís de Camões. Praias fluviais que enchem no verão. Com muita fome? A gastronomia da região com pratos tradicionais, é imperdível. Depois, encontre o próximo rumo com o TRAVIZCO."])},
    "setubal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setúbal é capital de distrito, na margem Sul do Tejo com 13 municípios, tendo o rio Sado e a Serra da Arrábida como aliados. Contraste entre a serra e o mar, as praias a perder de vista e os trilhos, o urbano e o rural. Território com património edificado e natural de grande beleza, com paisagens incríveis, com lugares a descobrir. A importância do seu porto para o crescimento económico da região, com indústrias em áreas diversas fez crescer o distrito. Grandes personalidades aqui nasceram, poetas e cantores. Tem aqui uma das mais antigas cidades de Europa, é terra de lagoas e Reserva Natural. Com o TRAVIZCO, venha provar o choco frito!"])},
    "viana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viana do Castelo (sede do distrito) é uma cidade atlântica a Norte de Portugal, no Alto Minho. Paisagem natural, com a presença de rio, montanha e mar (o rio Lima nasce em Espanha e desagua em Viana). No alto, a Basílica de Santa Luzia de onde se tem uma vista lindíssima, a não perder! Vá no Elevador que faz um percurso de 650 metros (o maior de Portugal). São 10 os municípios de Viana do Castelo, dos mais belos do país. Viana do Castelo tem uma forte ligação com o bacalhau, daqui partiam os bacalhoeiros para a Terra Nova e Gronelândia. São várias as iguarias gastronómicas por todo o distrito. A Senhora da Agonia celebrada a 20 de agosto atrai muitos visitantes, assim como as praias de areia fina. A arquitetura do centro histórico e a Ponte Eiffel… Os Vinhos Verdes bem conhecidos, não podem faltar! E como doçaria, a Torta de Viana, certificada pela Câmara Municipal. Com o Travizco, deixe-se levar e surpreender! De recordação, leve um lenço dos namorados ou um objeto em filigrana."])},
    "vila_real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para cá do Marão, mandam os que cá estão! Vila real, cidade e distrito com o mesmo nome, situa-se no norte de Portugal, na antiga província de Trás-os-Montes e Alto Douro. Fica situada na encosta da Serra do Marão e junto à Serra do Alvão. Pertencem ao distrito de Vila Real 14 municípios, cada um deles com as suas particularidades que os caracterizam tão bem. A paisagem é de tirar o fôlego! Reino Maravilhoso de Miguel Torga, é hoje um destino turístico de excelência! São muitas as Quintas que pode visitar e onde pode fazer provas de vinho. A gastronomia da região, tão rica e diversificada, não pode deixar de ser experimentada e saboreada. Com a construção do túnel do Marão com quase 6 quilómetros, maior túnel rodoviário do país, veio aproximar ainda mais os distritos, como os de Braga, Porto e Guimarães. Com a N2 a passar por estes municípios, o Travizco diz-lhe o que encontrar e visitar!"])},
    "viseu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cidade de Viseu é sede e capital de distrito, na região do Norte Centro. É o maior distrito do país com 24 municípios, cada um deles com características ímpares. Região de Viseu Dão-Lafões protegida pelas Serra da Estrela, Montemuro e Caramulo, é nos seus terroirs que nascem os néctares premiados. Aproveite as termas da região para se revigorar e deixe-se levar pelos trilhos das serras, respire o ar puro e aprecie a paisagem. Se o pretender, pode ainda aperfeiçoar a sua técnica de golf. Cidade de Viriato com tantos monumentos, num legado arquitetónico interessante em todos os concelhos. A gastronomia da região é rica e diversificada. Indo eu, indo eu, com o Travizco, a caminho de Viseu!"])}
  }
}